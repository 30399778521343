import api from '../index';
const guestObject = {
  getCategories() {
    return api.get(`/guest/categories`);
  },
  getMarketPlaceList(params) {
    return api.get(`/guest/marketplace?${params}`);
  },
  getMarketPlaceDetail(id) {
    return api.get(`/guest/marketplace/${id}`);
  },
  getFaq(params) {
    return api.get(`/guest/faq?${params}`);
  },
  getFaqCompany() {
    return api.get(`/guest/faq/company`);
  },
  searchFaqByTitle(query) {
    return api.get(`/guest/faq/search?${query}`);
  },
  getTerms() {
    return api.get(`/guest/terms`);
  },
  getPolicies() {
    return api.get(`/guest/policies`);
  },
};
export default guestObject;

// {{base_url}}/v1/guest/marketplace?category_id=3&limit&page&bussines_type&brand_name
