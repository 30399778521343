import { useEffect, useState, useCallback } from 'react';
import { Col, Radio, Row } from 'antd';

import GuestAPI from 'api/GuestAPI';

import { PdButton } from 'components/pd-button';
import CardNFT from 'components/landing-components/CardNFT';
import Footer from 'components/landing-components/Footer';
import JoinOurBusinessOwner from 'components/landing-components/JoinBusinessOwner';
import Navbar from 'components/landing-components/Navbar';

import landingHero from 'assets/images/landing-hero.png';
import grabLogo from 'assets/images/grab.svg';
import BgCard from 'assets/images/bg-card.png';

import './style.scss';
import InvestorAPI from 'api/InvestorAPI';

const LandingPage = () => {
  const [tabPosition, setTabPosition] = useState('left');
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(3);

  const handlePagination = () => {
    setPage(page + 3);
  };
  const fetchMarketplaceList = useCallback(async () => {
    const query = { limit: page, order_from: 'updatedAt', order_by: 'DESC' };
    const params = new URLSearchParams(query);
    const { data = {} } = await GuestAPI.getMarketPlaceList(params);
    const { rows = [], count = 0 } = data;
    setTotalPage(count);
    setMarketplaceList(rows);

    const isInvestor = localStorage.getItem('investor_token');

    if (isInvestor) {
      const { data = {} } = await InvestorAPI.getMarketPlaceList(params);
      setTotalPage(data.pageInfo.totalData);
      setMarketplaceList(data.data);
    } else {
      const { data = {} } = await GuestAPI.getMarketPlaceList(params);
      setTotalPage(data.pageInfo.totalData);
      setMarketplaceList(data.data);
    }
  }, [page]);

  useEffect(() => {
    fetchMarketplaceList();
  }, [fetchMarketplaceList]);

  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };
  return (
    <>
      <Navbar />

      <section className="hero">
        <Row
          align="middle"
          justify="center"
          className="pd-margin-top-md pd-margin-bottom-md pd-margin-left-md pd-margin-right-md">
          <Col
            xs={{ order: 3 }}
            md={{ order: 2, span: 12 }}
            className="pd-margin-top-md">
            <h1 className="hero-title">
              Own A <span className="hero-title-special">Business</span>
            </h1>
            <h1 className="hero-title">In One-Click!</h1>
            <p className="hero-desc">
              Tokenizing real world business through Blockchain/NFT
            </p>
          </Col>
          <Col xs={{ order: 2 }} md={{ order: 3, span: 12 }}>
            <Row justify="center">
              <img
                src={landingHero}
                alt="landing hero"
                className="hero-image"
              />
            </Row>
          </Col>
        </Row>
      </section>

      <section className="hero partners">
        <Row align="middle" justify="center">
          <h1 className="partners-header pd-margin-bottom-md">Listed Brands</h1>
        </Row>
        <Row
          align="middle"
          justify="center"
          gutter={12}
          className="pd-margin-bottom-lg">
          <Col span={4}>
            <Row justify="center">
              <img
                src={grabLogo}
                alt="landing hero"
                className="partners-image"
              />
            </Row>
          </Col>
          <Col span={4}>
            <Row justify="center">
              <img
                src={grabLogo}
                alt="landing hero"
                className="partners-image"
              />
            </Row>
          </Col>
          <Col span={4}>
            <Row justify="center">
              <img
                src={grabLogo}
                alt="landing hero"
                className="partners-image"
              />
            </Row>
          </Col>
          <Col span={4}>
            <Row justify="center">
              <img
                src={grabLogo}
                alt="landing hero"
                className="partners-image"
              />
            </Row>
          </Col>
          <Col span={4}>
            <Row justify="center">
              <img
                src={grabLogo}
                alt="landing hero"
                className="partners-image"
              />
            </Row>
          </Col>
        </Row>
      </section>

      <section className="hero explore">
        <Row align="middle" justify="center">
          <h1 className="explore-header pd-margin-bottom-md">
            Explore Business
          </h1>
        </Row>

        <Row align="middle" justify="center" className="pd-margin-bottom-lg">
          <Radio.Group value={tabPosition} onChange={changeTabPosition}>
            <Radio.Button value="latest-update">Latest Update</Radio.Button>
            {/* <Radio.Button value="most-popular">Most Popular</Radio.Button>
            <Radio.Button value="trending">Trending</Radio.Button> */}
          </Radio.Group>
        </Row>

        <Row align="middle" justify="center">
          <Col span={16}>
            <Row align="top" justify="center" gutter={[12, 12]}>
              {marketplaceList.map((data) => {
                const { id = 0 } = data;

                return (
                  <Col xs={24} md={12} lg={8} className="gutter-row" key={id}>
                    <CardNFT
                      refetch={fetchMarketplaceList}
                      className={'pd-margin-top-lg'}
                      cover={BgCard}
                      data={data}
                      from="landing"
                    />
                  </Col>
                );
              })}
            </Row>
            <Row align="middle" justify="center" className="pd-margin-top-md">
              <PdButton
                disabled={page >= totalPage}
                color="black"
                className="h-button"
                onClick={handlePagination}>
                Load More
              </PdButton>
            </Row>
          </Col>
        </Row>
      </section>
      <JoinOurBusinessOwner className={'pd-margin-bottom-lg'} />
      <Footer />
    </>
  );
};

export default LandingPage;
