import { Link, useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import GuestAPI from 'api/GuestAPI';

import { ArrowRightOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';

import debounce from 'lodash.debounce';

import { PdAntdInput } from 'components/pd-antd-input';
import Footer from 'components/landing-components/Footer';
import JoinOurBusinessOwner from 'components/landing-components/JoinBusinessOwner';
import Navbar from 'components/landing-components/Navbar';

import circ1 from '../../../assets/images/circ1.png';
import circ2 from '../../../assets/images/circ2.png';

import './style.scss';

const FAQ = () => {
  const history = useHistory();
  const [companyData, setCompanyData] = useState([]);
  const [investorData, setInvestorData] = useState([]);
  const [isFetchPaused, setIsFetchPaused] = useState(false);

  const [searchValue, setSearchValue] = useState(
    localStorage.getItem('searchFAQ')
  );

  const handleResult = debounce((value) => {
    history.push(`/faq/search?title=${value}`);
    setIsFetchPaused(false);
  }, 1500);

  const debouceRequest = useCallback(
    (value) => handleResult(value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangeSearchInput = (event) => {
    if (localStorage.getItem('searchQuery')) {
      localStorage.removeItem('searchQuery');
    }

    setIsFetchPaused(true);

    const {
      target: { value = '' },
    } = event;
    setSearchValue(value);
    debouceRequest(value);
  };

  useEffect(() => {
    const getAllFaq = async () => {
      try {
        let { data: company } = await GuestAPI.getFaq(`filter=Company`);
        let { data: investor } = await GuestAPI.getFaq(`filter=Investor`);

        //splice idx 0-4
        company.rows.splice(5)
        investor.rows.splice(5)

        setCompanyData(company.rows);
        setInvestorData(investor.rows);
      } catch (error) {
        message.error('Error while trying to get faqs..');
      }
    };
    getAllFaq();
  }, []);
  return (
    <>
      <Navbar />
      <div className="faq-wrapper">
        <div className="faq-hero">
          <img src={circ1} alt="circ" className="circ1" />
          <div className="hero-wrapper">
            <p>FAQ</p>
            <h1>Frequently Asked Questions</h1>
            <PdAntdInput
              loading={isFetchPaused}
              value={searchValue}
              variant="search"
              size="large"
              placeholder="Search"
              className="h-input w-full"
              handleSearch={onChangeSearchInput}
            />
          </div>
          <img src={circ2} alt="circ" className="circ2" />
        </div>
        <Row gutter={24} className="content-wrapper">
          <Col span={12}>
            <div className="detail-link">Investor Questions</div>
            {investorData.map((data) => (
              <Link to={`/faq/investor/detail/${data.id}`}>
                <div className="question" key={data}>
                  <Row justify="space-between" align="middle">
                    <p className="question-text">{data.title}</p>
                    <ArrowRightOutlined />
                  </Row>
                </div>
              </Link>
            ))}
            <Link to={'/faq/investor/all'}>See All</Link>
          </Col>
          <Col span={12}>
            <div className="detail-link">Company Questions</div>
            {companyData.map((data) => (
              <Link to={`/faq/company/detail/${data.id}`}>
                <div className="question" key={data}>
                  <Row justify="space-between" align="middle">
                    <p className="question-text">{data.title}</p>
                    <ArrowRightOutlined />
                  </Row>
                </div>
              </Link>
            ))}
            <Link to={'/faq/company/all'}>See All</Link>
          </Col>
        </Row>
      </div>
      <JoinOurBusinessOwner className={'pd-margin-bottom-lg'} />
      <Footer />
    </>
  );
};

export default FAQ;
