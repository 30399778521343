import PdAntdHeader from 'components/pd-antd-header';

import { PdNotificationPopover } from 'components/pd-notification-popover';
import { PdProfileNav } from 'components/pd-profile-nav';

import './style.scss';

export const PdNavbar = ({ from = 'company' }) => {
  return (
    <PdAntdHeader className="navbar">
      <div></div>

      <div className="nav-content-wrapper">
        {from === 'company' && <PdNotificationPopover />}
        <PdProfileNav role={from} />
      </div>
    </PdAntdHeader>
  );
};

// export default { PdNavbar };
