import { Col, Row, Spin } from 'antd';

import CardNFT from '../CardNFT';
import { PdButton } from 'components/pd-button';
import BgCard from 'assets/images/bg-card.png';

const AllCardNFT = ({
  datas = [],
  loading = false,
  page,
  handlePagination,
  pagination,
  refetch = () => {},
}) => {
  return (
    <Row
      align="middle"
      justify="center"
      className="pd-margin-top-md pd-margin-bottom-xl">
      <Col span={22}>
        <Row justify="center">{loading && <Spin />}</Row>
        <Row justify="start" gutter={[40, 24]}>
          {datas.map((data) => {
            const { id = 0 } = data;
            return (
              <Col span={8} className="gutter-row" key={id}>
                <CardNFT
                  className={'pd-margin-top-lg'}
                  cover={BgCard}
                  data={data}
                  from="landing"
                  refetch={refetch}
                />
              </Col>
            );
          })}
        </Row>
        <Row justify="center" className="pd-margin-top-md">
          <div>
            <PdButton
              type="default"
              color="black"
              disabled={page === 0}
              className="pd-margin-right-md h-button"
              onClick={() => handlePagination('prev')}>
              Previous
            </PdButton>
            <PdButton
              color="black"
              className="h-button"
              disabled={page === pagination}
              onClick={() => handlePagination('next')}>
              Next
            </PdButton>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default AllCardNFT;
