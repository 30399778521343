import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
import tykoonLogo from 'assets/images/logo-black.png';

const data = [
  {
    id: 1,
    title: 'Resources',
    subTitle1: {
      title: 'Explore Business',
      link: '/explore',
    },
    subTitle2: {
      title: 'About Us',
      link: '/',
    },
    subTitle3: {
      title: 'Join as Company',
      link: '/login',
    },
    subTitle4: {
      title: 'Join as Investor',
      link: '/explore',
    },
  },
  {
    id: 2,
    title: 'Legal',
    subTitle1: {
      title: 'Term & Condition',
      link: '/term-of-use',
    },
    subTitle2: {
      title: 'Privacy Policy',
      link: '/privacy-policy',
    },
  },
  {
    id: 3,
    title: 'FAQ',
    // subTitle1: {
    //   title: 'Crowdfunding',
    //   link: '/',
    // },
    subTitle1: {
      title: 'Our Contact',
      link: '/',
    },
    subTitle2: {
      title: 'Frequently Asked Question',
      link: '/faq',
    },
  },
];

const Footer = () => {
  return (
    <>
      <Row align="top" justify="center" style={{ padding: '0 25px' }}>
        <Col span={22}>
          <Row
            align="top"
            justify="space-between"
            className="pd-padding-top-xl pd-padding-bottom-xl">
            <Col xs={22} md={10}>
              <p>
                <img src={tykoonLogo} alt="logo" />
              </p>
              <p>Let’s invest with simply one click</p>
            </Col>
            <Col xs={22} md={12} className="pd-margin-top-md">
              <Row align="top" justify="start">
                {data.map((i) => (
                  <Col key={i.id} xs={22} sm={22} md={8}>
                    <h4 className="pd-margin-bottom-md font-bold">{i.title}</h4>
                    <Link to={i.subTitle1.link} className="font-400">
                      <h4>{i.subTitle1.title}</h4>
                    </Link>
                    <Link to={i.subTitle2.link} className="font-400">
                      <h4>{i.subTitle2.title}</h4>
                    </Link>
                    {i.id === 1 && (
                      <>
                        <Link to={i?.subTitle3?.link} className="font-400">
                          <h4> {i?.subTitle3.title}</h4>
                        </Link>
                        <Link to={i?.subTitle4?.link} className="font-400">
                          <h4> {i?.subTitle4.title}</h4>
                        </Link>
                      </>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row align="top" justify="center">
        <Col span={22}>
          <p>© 2022 Your Company, Inc. All rights reserved.</p>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
