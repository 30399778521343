import { useHistory } from 'react-router-dom';

import loginCompanyImage from 'assets/images/login-company.svg';
import registerCompanyImage from 'assets/images/register-company.svg';

import './style.scss';

/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

const LoginModal = () => {
  const history = useHistory();

  return (
    <div className="pd-login-modal">
      <p className="title">Join Now</p>
      <div className="wrapper">
        <div className="card" onClick={() => history.push('/login')}>
          <img src={loginCompanyImage} alt="Login as Company" />
          <p className="title">Login as Company</p>
          <p className="desc">Lorem Ipsum dolor sit amet dolor sit amet</p>
        </div>
        <div className="card" onClick={() => history.push('/register')}>
          <img src={registerCompanyImage} alt="Register as Company" />
          <p className="title">Register as Company</p>
          <p className="desc">Lorem Ipsum dolor sit amet dolor sit amet</p>
        </div>
      </div>
    </div>
  );
};

export { LoginModal };
