import { useState, useCallback, useEffect } from 'react';
import { Layout, message } from 'antd';

import { DashboardContainer } from 'components/dashboard-container';
import { dateFormater } from 'utils/date-utility';
import { Failed, Success } from 'components/pd-modal/success';
import { PdButton } from 'components/pd-button';
import { PdDropdown } from 'components/pd-dropdown';
import { PdModals } from 'components/pd-modal';
import { PdTooltip } from 'components/pd-tooltip';
import { UploadDocument } from 'components/pd-modal/upload-document';
import CompanyAPI from 'api/CompanyAPI';
import PdAntdHeader from 'components/pd-antd-header';
import PdTable from 'components/pd-table';
import PdTitle from 'components/pd-title';
import UploadAPI from 'api/UploadAPI';

import infoIcon from 'assets/icon/info-blue.svg';

import './style.scss';

const CompanyDocument = () => {
  const [documents, setDocuments] = useState([]);
  const [uploadForm, setUploadForm] = useState({});

  const [loading, setLoading] = useState({});

  const [modal, setModal] = useState({
    type: '',
    visible: false,
    data: {},
    status: '',
  });
  const [pageSize, setPageSize] = useState(5);
  const [businessList, setBusinessList] = useState([]);
  const [documentList, setDocumentList] = useState([]);

  const fetchDocumentList = useCallback(async () => {
    if (modal.status === 'read' || modal.status === 'edit') {
      const { data = {} } = await CompanyAPI.getBusinessDocument(uploadForm.id);
      const { ListDocuments = [] } = data;
      const modifiedListDocuments = ListDocuments.map((doc) => {
        return { ...doc, upload: true };
      });
      setDocumentList(modifiedListDocuments);
    }
  }, [uploadForm.id, modal]);

  const fetchBusiness = useCallback(async () => {
    setLoading({ page: true });
    try {
      const { data } = await CompanyAPI.getAllBusiness();
      const businessForSelect = data.map((business, index) => {
        const { id: businessId, brand_name = '' } = business;
        return { optKey: index, value: businessId, text: brand_name };
      });
      setBusinessList(businessForSelect);
      setLoading({ page: false });
    } catch (_err) {
      setLoading({ page: false });
      message.error('Check your internet connection');
    }
  }, []);

  const fetchDocument = useCallback(async () => {
    const { data = {} } = await CompanyAPI.getAllBusinessDocument();
    const { rows = [] } = data;
    setDocuments(rows);
  }, []);

  useEffect(() => {
    fetchBusiness();
    fetchDocument();
    fetchDocumentList();
  }, [fetchBusiness, fetchDocument, fetchDocumentList]);

  const onClickActionDocument = async (props, id, data) => {
    const { key } = props;
    if (key === 'edit') {
      setModal({ type: 'upload', visible: true, status: 'edit' });
      setUploadForm(data);
    }
    if (key === 'delete') {
      try {
        await CompanyAPI.deleteDocumentBusiness(id);
        message.success('Delete complete');
        fetchDocument();
      } catch (_) {
        message.error('Network Error');
      }
    }
  };

  const menu = [
    {
      label: 'Edit',
      key: 'edit',
    },
    {
      label: 'Delete',
      key: 'delete',
    },
  ];

  const columns = [
    {
      title: 'Document Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Business Name',
      dataIndex: 'business',
      key: 'business',
    },
    {
      title: 'Uploaded Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Total Files',
      dataIndex: 'files',
      key: 'files',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, eachRow) => {
        const { id = '' } = eachRow;
        return (
          <div className="action-button-wrapper">
            <PdButton
              type="primary"
              color="blue"
              onClick={() => {
                setModal({
                  type: 'upload',
                  visible: true,
                  data: eachRow,
                  status: 'read',
                });
                setUploadForm(eachRow);
              }}>
              <p className="actionButtonText">Detail</p>
            </PdButton>
            <PdDropdown
              color="black"
              type="primary"
              menus={menu}
              textClassName="actionButtonText"
              onClick={(val) => onClickActionDocument(val, id, eachRow)}
            />
          </div>
        );
      },
    },
  ];

  const tableData = documents.map((row, index) => {
    const {
      Bussine = {},
      createdAt = '',
      document_name = '',
      bussines_id = '',
      sum_docs = 0,
    } = row;
    const { brand_name = '' } = Bussine;
    return {
      key: index + 1,
      date: dateFormater(createdAt),
      name: document_name,
      business: brand_name,
      bussines_id,
      files: sum_docs,
      ...row,
    };
  });

  const handleCloseModal = () => {
    setModal({ type: '', visible: false, data: {}, status: '' });
    setUploadForm({});
  };

  const onChangePageSize = (size) => {
    setPageSize(size);
  };

  const handleOpenFilterModal = () => {
    setModal({ type: 'filter', visible: true });
  };

  const handleOpenUploadDocument = () => {
    setModal({ type: 'upload', visible: true, status: 'create' });
  };

  const onChangeBusiness = (value) => {
    setUploadForm((prevState) => ({ ...prevState, bussines_id: value }));
  };

  const onChangeForm = ({ target }) => {
    const { name, value } = target;
    setUploadForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onFinishForm = async (status) => {
    setLoading({ submit: true });
    let id = 0;
    let payload = {};
    let bussines_document = [];
    let getFileUrlPromises = [];
    let fileName = [];

    try {
      // take care of images
      if (uploadForm.file?.length) {
        getFileUrlPromises = await Promise.all(
          uploadForm.file.map(async ({ type = '', name = '' }) => {
            const extension = type.split('/')[1];
            fileName.push(name);
            return await UploadAPI.getUploadURL(extension, 'financial_report');
          })
        );
        await Promise.all(
          getFileUrlPromises.map(async ({ data }, index) => {
            const { uploadUrl = '' } = data;
            return await UploadAPI.uploadImage(
              uploadForm.file[index].originFileObj,
              uploadUrl
            );
          })
        );
      }

      if (status === 'create') {
        bussines_document = getFileUrlPromises.map(({ data }, index) => {
          const { objectUrl = '' } = data;
          return { bussines_document: objectUrl, file_name: fileName[index] };
        });

        payload = {
          ...uploadForm,
          documents: bussines_document,
        };
      }

      if (status === 'edit') {
        bussines_document = getFileUrlPromises.map(({ data }, index) => {
          const { objectUrl = '' } = data;
          return { bussines_document: objectUrl, file_name: fileName[index] };
        });

        payload = {
          ...uploadForm,
          documents: bussines_document,
        };
      }

      try {
        if (status === 'edit') {
          id = uploadForm.id;
          await CompanyAPI.editDocumentBusiness(id, payload);
          setTimeout(() => {
            setModal({ type: 'success', visible: true });
          }, 300);
        }

        if (status === 'create') {
          id = uploadForm.bussines_id;
          await CompanyAPI.postDocumentBusiness(id, payload);
          setTimeout(() => {
            setModal({ type: 'success', visible: true });
          }, 300);
        }
        setLoading({ submit: false });

        fetchDocument();
        handleCloseModal();
      } catch (errInner) {
        setLoading({ submit: false });
        setModal({ type: 'failed', visible: true });
        message.error('Please reupload image inner');
      }
    } catch (err) {
      console.log('err', err);
      setLoading({ submit: false });
      message.error('Please reupload image outer');
    }
  };

  const modalContent = {
    upload: (
      <UploadDocument
        onClose={handleCloseModal}
        businessList={businessList}
        loading={loading.submit}
        documentList={documentList}
        setDocumentList={setDocumentList}
        onChangeBusiness={onChangeBusiness}
        onChangeForm={onChangeForm}
        onFinish={onFinishForm}
        setUploadForm={setUploadForm}
        status={modal.status}
        uploadForm={uploadForm}
      />
    ),
    success: (
      <Success
        title="Congratulations"
        desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
      />
    ),
    failed: (
      <Failed
        title="Oops!"
        desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
      />
    ),
  };

  return (
    <Layout className="company-document-page">
      <PdModals
        visible={modal.visible}
        wrapClassName="upload-document-modal"
        headStyle={{ alignText: 'center' }}
        handleClose={handleCloseModal}
        footer={null}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Company Document
          <PdTooltip
            placement="top"
            color="white"
            title="In company document menu, You can add company documents/financial reports">
            <img src={infoIcon} alt="info" className="info-icon" />
          </PdTooltip>
        </PdTitle>
        <PdButton
          className="upload-document-button"
          onClick={handleOpenUploadDocument}>
          Upload Company Document
        </PdButton>
      </PdAntdHeader>
      <DashboardContainer>
        <PdTable
          loading={loading.page}
          pageSize={pageSize}
          openFilter={handleOpenFilterModal}
          title="All Company Document"
          columns={columns}
          data={tableData}
          onChangePageSize={onChangePageSize}
        />
      </DashboardContainer>
    </Layout>
  );
};

export { CompanyDocument };
