import { useEffect, useState } from 'react';
import { Avatar, Badge, Col, Layout, message, Row, Tabs } from 'antd';

import CompanyAPI from 'api/CompanyAPI';

import { isImage } from 'utils/params-nil';

import { DashboardContainer } from 'components/dashboard-container';
import { PdButton } from 'components/pd-button';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import PdTable from 'components/pd-table';

import moment from 'moment';

import defaultIcon from 'assets/icon/notif-icon.svg';

export const Notification = () => {
  const [data, setData] = useState([]);
  const [countUnread, setCount] = useState(0);
  const [tabs, setTabs] = useState('1');
  const handleChangeTabs = (val) => {
    setTabs(val);
  };

  const getAllNotification = async (status) => {
    const query = { status };
    const params = new URLSearchParams(query);
    try {
      const { data } = await CompanyAPI.getAllNotification(params);
      let counting = 0;
      data.map((data) => (data.status === 'unread' ? counting++ : ''));
      setCount(counting);
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = async (id) => {
    const payload = {
      status: 'read',
      notification_ids: [id],
    };
    try {
      await CompanyAPI.markAsReadNotif(payload);
      getAllNotification(tabs === '1' ? '' : 'unread');
      message.success('Successfully mark as read!');
    } catch (error) {
      console.log(error);
      message.error(error.response.data.message);
    }
  };

  function ColumnAction(data) {
    const { status = '', id } = data;

    return (
      <Row gutter={18}>
        <Col lg={12}>
          <PdButton
            block
            color="black"
            onClick={handleAction.bind(this, id)}
            disabled={status === 'read'}>
            Mark as Read
          </PdButton>
        </Col>
      </Row>
    );
  }

  const columns = [
    {
      dataIndex: 'notification',
      key: 'notif',
      width: 400,
      render: (notification, Bussine) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <Avatar
            className="pd-margin-right-md"
            icon={
              <img
                src={isImage(Bussine.logo) ? Bussine.logo : defaultIcon}
                alt="logo"
              />
            }
          />
          <div> {notification}</div>
        </div>
      ),
    },
    {
      dataIndex: 'updatedAt',
      key: 'notif date',
      render: (updatedAt) => moment(updatedAt).format('YYYY-MM-DD'),
    },
    {
      dataIndex: 'updatedAt',
      key: 'notif time',
      render: (updatedAt) =>
        moment(updatedAt).isSame(moment(), 'day')
          ? moment(updatedAt).fromNow()
          : moment(updatedAt).format('hh:mm A'),
    },
    {
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status === 'unread' ? (
          <Badge status="error" text="Unread" />
        ) : (
          <Badge status="success" text="Read" />
        ),
    },
    {
      render: ColumnAction,
      width: 300,
      align: 'center',
    },
  ];

  useEffect(() => {
    getAllNotification(tabs === '1' ? '' : 'unread');
  }, [tabs]);

  return (
    <Layout>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Notification
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer style={{ paddingTop: '0' }}>
        <Row>
          <Tabs
            activeKey={tabs}
            onChange={handleChangeTabs}
            items={[
              {
                label: (
                  <div>
                    All Notification
                    {countUnread > 0 && (
                      <Badge
                        count={countUnread}
                        className="pd-margin-left-sm"
                      />
                    )}
                  </div>
                ),
                key: '1',
              },
            ]}
          />
        </Row>
        <PdTable columns={columns} data={data} title="All Notifications" />
      </DashboardContainer>
    </Layout>
  );
};
