import { useEffect, useState } from 'react';

import GuestAPI from '../../../api/GuestAPI';
import Footer from 'components/landing-components/Footer';
import JoinOurBusinessOwner from 'components/landing-components/JoinBusinessOwner';
import Navbar from 'components/landing-components/Navbar';
import { message } from 'antd';
import moment from 'moment';

import './index.scss';

const TermsOfUse = () => {
  const [data, setData] = useState({});
  const { description = '', post_date = '' } = data;
  useEffect(() => {
    const getTermsData = async () => {
      try {
        const { data } = await GuestAPI.getTerms();
        setData(data);
      } catch (error) {
        message.error('Error while getting terms data');
      }
    };
    getTermsData();
  }, []);
  return (
    <>
      <Navbar />
      <div className="privacy-wrapper">
        <div className="content-wrapper">
          <h1>Terms of Use</h1>
          <div className="time">
            Updated {moment(post_date).format('YYYY-MM-DD')}
          </div>
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
      <JoinOurBusinessOwner className={'pd-margin-bottom-lg'} />
      <Footer />
    </>
  );
};

export default TermsOfUse;
