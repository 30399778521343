import dashboard from '../../assets/icon/dashboard-icon.svg';
import revenue from '../../assets/icon/revenue-icon.svg';
import companyDoc from '../../assets/icon/document-icon.svg';
import blog from '../../assets/icon/blog.svg';
import requestEdit from '../../assets/icon/request-edit.svg';
import history from '../../assets/icon/history.svg';
import mybusiness from '../../assets/icon/mybisnis.svg';
import favorite from '../../assets/icon/fav.svg';

export const menuOptionsCompany = [
  {
    type: 'Menu',
    name: 'Dashboard',
    link: '/company/dashboard',
    image: dashboard,
  },
  {
    type: 'Menu',
    name: 'Revenue Disbursement',
    link: '/company/revenue-disbursement',
    image: revenue,
  },
  {
    type: 'Menu',
    name: 'History',
    link: '/company/history',
    image: history,
  },
  {
    type: 'Menu',
    name: 'Company Document',
    link: '/company/company-document',
    image: companyDoc,
  },
  {
    type: 'Menu',
    name: 'Blog',
    link: '/company/blog',
    image: blog,
  },
  {
    type: 'Menu',
    name: 'Request Change Data',
    link: '/company/request-edit',
    image: requestEdit,
  },
];

export const menuOptionsInvestor = [
  {
    type: 'Menu',
    name: 'My Business',
    link: '/investor/my-business',
    image: mybusiness,
  },
  {
    type: 'Menu',
    name: 'History',
    link: '/investor/history',
    image: history,
  },
  {
    type: 'Menu',
    name: 'Favorite',
    link: '/investor/favorite',
    image: favorite,
  },
];
