import _ from 'lodash';

/**
 *
 * @param {String} str
 * @example capitalize('heLLoYeLlo');
 * @returns Helloyello
 */

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
/**
 *
 * @param {String} str
 * @example dashSplitter('one-man-show-1');
 * @returns One Man Show 1
 */

export function dashSplitter(str) {
  const split = str.split('-');
  const cap = split.map((e) => capitalize(e));
  return cap.join(' ');
}

export function capitalizeFirstLetter(string) {
  return string
    .split(' ')
    .map((letter) => letter[0].toUpperCase().concat(letter.slice(1)))
    .join(' ');
}

export function displayTwoDigit(number = 0) {
  let temp = number.toString();

  return temp.length >= 2 ? temp : '0' + temp;
}

/**
 *
 * @param {Number} val
 * @example currencyFormatter(100000)
 */

export function currencyFormatter(val, sym) {
  return `${sym} ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 *
 * @param {String} val
 * @example currencyParser('Rp.100,000')
 */

export function currencyParser(val) {
  return val.replace(/\$ \s?|(,*)/g, '');
}

export function addressSplitter(add, length) {
  return _(add).truncate({ length });
}
