import { useEffect, useState, useCallback } from 'react';
import { Layout } from 'antd';

import CompanyAPI from 'api/CompanyAPI';

import PdTable from 'components/pd-table';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import { DashboardContainer } from 'components/dashboard-container';
import { PdButton } from 'components/pd-button';
import RequestChangeDataDetail from 'components/pd-modal/request-change-data-detail';
import { PdModals } from 'components/pd-modal';
import RequestChangeDataFilter from 'components/pd-modal/request-change-data-filter';

import './style.scss';

const RequestEditChange = () => {
  const [requestList, setRequestList] = useState([]);
  const [modal, setModal] = useState({ type: '', visible: false, data: {} });
  const [filterStatus, setFilterStatus] = useState('');
  const [finalFilterStatus, setFinalFilterStatus] = useState('');
  const [pageSize, setPageSize] = useState(5);

  const fetchRequestEdit = useCallback(async () => {
    const { data } = await CompanyAPI.getAllRequestEdit();
    const { rows = [] } = data;

    let requestDataList = rows;

    if (finalFilterStatus && finalFilterStatus !== 'all') {
      requestDataList = rows.filter(({ status = '' }) => {
        return status === finalFilterStatus;
      });
    }
    setRequestList(requestDataList);
  }, [finalFilterStatus]);

  useEffect(() => {
    fetchRequestEdit();
  }, [fetchRequestEdit]);

  const columns = [
    {
      title: 'Created Date',
      dataIndex: 'date',
      key: 'name',
    },
    {
      title: 'Business',
      dataIndex: 'business',
      key: 'business',
    },
    {
      title: 'Detail Request',
      dataIndex: 'requestDetail',
      key: 'requestDetail',
      width: 350,
      ellipsis: true,
    },
    {
      title: 'Request Status',
      dataIndex: 'requestStatus',
      key: 'requestStatus',
      render: (one) => {
        const bgColor =
          one === 'in-review'
            ? '#1A252D'
            : one === 'rejected'
            ? '#FF4444'
            : '#44ABFF';
        const statusLabel =
          one === 'in-review'
            ? 'In Review'
            : one === 'rejected'
            ? 'Rejected'
            : 'Updated';

        return (
          <div className="status-wrapper" style={{ backgroundColor: bgColor }}>
            <p className="status">{statusLabel}</p>
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, data) => {
        return (
          <PdButton
            type="primary"
            color="black"
            onClick={() =>
              setModal({ type: 'requestDetail', visible: true, data })
            }>
            <p
              style={{
                fontWeight: 400,
                fontSize: '12px',
                margin: 0,
                padding: 0,
              }}>
              Detail
            </p>
          </PdButton>
        );
      },
    },
  ];

  const tableData = requestList.map((row, index) => {
    const {
      send_date = '',
      Bussine = {},
      request_description = '',
      status = '',
    } = row;
    const { brand_name = '' } = Bussine;
    return {
      key: index + 1,
      date: send_date,
      business: brand_name,
      requestDetail: request_description,
      requestStatus: status,
    };
  });

  const onChangePageSize = (size) => {
    setPageSize(size);
  };

  const handleChangeFilterStatus = (status) => {
    setFilterStatus(status);
  };

  const onFinishFilter = () => {
    setFinalFilterStatus(filterStatus);
    setModal({ type: '', visible: false, data: {} });
  };

  const handleOpenFilterModal = () => {
    setFilterStatus('all');
    setModal({ type: 'filter', visible: true });
  };

  const handleCloseModal = () => {
    setModal({ type: '', visible: false, data: {} });
  };

  const modalContent = {
    requestDetail: <RequestChangeDataDetail data={modal.data} />,
    filter: (
      <RequestChangeDataFilter
        onFinish={onFinishFilter}
        onChange={handleChangeFilterStatus}
      />
    ),
    // success: <Success />,
  };

  return (
    <Layout className="request-edit-page">
      <PdModals
        visible={modal.visible}
        handleClose={handleCloseModal}
        closable={modal.type !== 'filter'}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Request Change Data
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer>
        <PdTable
          pageSize={pageSize}
          openFilter={handleOpenFilterModal}
          title="All Request"
          columns={columns}
          data={tableData}
          onChangePageSize={onChangePageSize}
        />
      </DashboardContainer>
    </Layout>
  );
};

export { RequestEditChange };
