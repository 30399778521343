import { forwardRef } from 'react';
import { Input, InputNumber } from 'antd';

import './style.scss';

const { Search, TextArea } = Input;

/**
 *
 * @param {Object} props
 * @param {('text' | 'text-area' | 'search' | 'email' | 'password' | 'number')} props.variant - variant of input that we want to return
 * @returns
 */

export const PdAntdInput = forwardRef(
  (
    { variant = 'text', className = '', handleSearch = () => {}, ...props },
    ref
  ) => {
    switch (variant) {
      case 'text':
        return (
          <Input
            className={`pd-antd-input ${className}`}
            ref={ref}
            {...props}
          />
        );
      case 'number':
        return (
          <InputNumber
            className={`pd-antd-input ${className}`}
            ref={ref}
            {...props}
          />
        );
      case 'password':
        return (
          <Input.Password
            type="password"
            className={`pd-antd-input ${className}`}
            ref={ref}
            {...props}
          />
        );
      case 'text-area':
        return (
          <TextArea
            className={`pd-antd-input ${className}`}
            ref={ref}
            {...props}
          />
        );
      case 'search':
        return (
          <Search
            className={`pd-antd-input ${className}`}
            onChange={handleSearch}
            {...props}
          />
        );
      case 'email':
        return (
          <Input
            className={`pd-antd-input ${className}`}
            ref={ref}
            type="email"
            {...props}
          />
        );
      default:
        break;
    }
  }
);

PdAntdInput.displayName = 'PdAntdInput';

const PdAntdInputs = { PdAntdInput };
export default PdAntdInputs;
