import './style.scss';
import { useEffect, useState } from 'react';
import { Alert, Col, Layout, message, Pagination, Row, Spin } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import { useAuthContext } from 'contexts/authentication';

import CompanyAPI from 'api/CompanyAPI';

import { DashboardContainer } from 'components/dashboard-container';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import { Success } from 'components/pd-modal/success';
import BusinessType from 'components/pd-modal/business-type';
import CardNFT from 'components/landing-components/CardNFT';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';

import BgCard from 'assets/images/bg-card.png';

export function Home() {
  const { authenticatedUserProfile, auth } = useAuthContext();
  const { status: userStatus, isHavingBussines } = authenticatedUserProfile;

  const location = window.location.pathname;

  const history = useHistory();

  const [businessData, setBusinessData] = useState([]);
  const [businessLoad, setBusinessLoad] = useState(false);
  const [count, setCount] = useState(0);
  const [modal, setModal] = useState({ type: '', visible: false });
  const [timeline, setTimeline] = useState(0);
  const [page, setPage] = useState(0);

  const handleCloseModal = () => {
    if (modal.type === 'success') {
      const userID = authenticatedUserProfile.id;
      let isVerifShow = { user: [userID], isShow: true };
      const isVerif = JSON.parse(localStorage.getItem('verif_show'));
      if (isVerif) {
        isVerifShow = { user: [...isVerif.user, userID], isShow: true };
        localStorage.setItem('verif_show', JSON.stringify(isVerifShow));
      } else {
        localStorage.setItem('verif_show', JSON.stringify(isVerifShow));
      }
    }
    setModal({ type: '', visible: false });
  };

  const handleOpenModal = () => {
    setModal({ type: 'businessType', visible: true });
  };

  const handleToDetail = (id, brandName, businessType, categoryId) => {
    return history.push(`/company/product-detail/${id}`, {
      role: 'company',
      brandName,
      businessType,
      categoryId,
    });
  };

  const modalContent = {
    businessType: <BusinessType />,
    success: (
      <Success
        title="Congratulations!"
        desc={'Your Account is Now Verified!'}
      />
    ),
  };

  useEffect(() => {
    const fetchCompanyBusiness = async () => {
      setBusinessLoad(true);
      try {
        const query = { page, limit: 6 };
        const params = new URLSearchParams(query);
        const { data } = await CompanyAPI.getAllBusiness(params);

        setBusinessData(data);
        setCount(data.length);
      } catch (err) {
        message.error('Error connection server failed!');
      } finally {
        setBusinessLoad(false);
      }
    };
    fetchCompanyBusiness();
  }, [page]);

  useEffect(() => {
    const checkUserStatus = async () => {
      await auth({}, 'getUserProfile');
      console.log(timeline);
      if (userStatus === 'not verified' || 'rejected') setTimeline(0);
      if (userStatus === 'pending') setTimeline(1);
      if (userStatus === 'verified') {
        const isVerif = JSON.parse(localStorage.getItem('verif_show')) || {
          user: [],
        };

        const { user } = isVerif;
        if (user.indexOf(authenticatedUserProfile.id) < 0) {
          setModal({ type: 'success', visible: true });
        }

        setTimeline(2);
      }
      if (isHavingBussines && userStatus === 'verified') setTimeline(3);
    };
    checkUserStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isHavingBussines, userStatus, authenticatedUserProfile.id]);

  return (
    <Layout className="pd-cms-home">
      <PdModals
        wrapClassName="pd-business-type-modal"
        // width={700}
        visible={modal.visible}
        handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Dashboard
        </PdTitle>
        <PdButton
          onClick={handleOpenModal}
          className="h-button-primary font-600"
          disabled={userStatus !== 'verified'}>
          Register Your Business
        </PdButton>
      </PdAntdHeader>
      <DashboardContainer>
        {userStatus === 'not verified' && (
          <Alert
            message="Your account hasn’t been confirmed yet."
            type={'warning'}
            description="Please verify your account to enjoy all features and transaction"
            showIcon
            action={
              <Link to="/company/verify-account">
                <PdButton color="white" className="h-button-primary font-600">
                  Verify Your Account
                </PdButton>
              </Link>
            }
          />
        )}

        {/* commented temporary!! */}
        {/* <div className="steps-wrapper pd-margin-top-md">
          <Steps size="small" current={timeline} labelPlacement="vertical">
            <Step title="Verify Your Account" />
            <Step title={timeline >= 2 ? 'Verified' : 'Reviewing Account'} />
            <Step title="Register Your Business" />
            <Step title="Upload Company Document" />
          </Steps>
        </div> */}

        <Row
          align="middle"
          justify="center"
          className="pd-margin-top-md pd-margin-bottom-xl">
          <Col span={24}>
            {businessLoad && (
              <Row justify="center">
                <Spin />
              </Row>
            )}
            <Row justify="start" gutter={[16, 16]}>
              {businessData.map((data) => {
                const {
                  brand_name = '',
                  bussines_type = '',
                  Category = {},
                  id = 0,
                } = data;

                const { id: category_id } = Category;

                return (
                  <Col
                    onClick={() =>
                      handleToDetail(id, brand_name, bussines_type, category_id)
                    }
                    span={8}
                    className="gutter-row"
                    key={data.id}>
                    <CardNFT
                      className={'pd-margin-top-lg'}
                      from="company"
                      role="company"
                      button={false}
                      cover={BgCard}
                      data={data}
                    />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row justify="end" className="pd-margin-top-lg">
          {count && (
            <Pagination
              defaultCurrent={1}
              total={count}
              onChange={(page) => setPage(page - 1)}
            />
          )}
        </Row>
      </DashboardContainer>
    </Layout>
  );
}

const Homes = { Home };

export default Homes;
