import { createRef, useState } from 'react';
import { Checkbox, Col, Divider, Form, message, Row } from 'antd';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import PdUploader from 'components/pd-uploader';
import PdTitle from 'components/pd-title';

import { currencyFormatter } from 'utils/string-utility';

import './style.scss';

/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

const halfLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  labelAlign: 'left',
};

export default function RevenuePayment({
  onFinish = () => {},
  onClose = () => {},
  data = {},
}) {

  const ref = createRef();
  const [form] = Form.useForm();
  const [isAgree, setIsAgree] = useState(false);
  const [financialReport, setFinancialReport] = useState();
  const [income, setIncome] = useState(0);

  form.setFieldsValue({
    business_name: data.name,
    revenue_sharing: data.revenue_sharing,
  });

  function handleChangeIncome(val) {
    setIncome(val);
  }
  const onChange = (e) => {
    setIsAgree(e.target.checked);
  };
  function handleFinish(value) {
    if (!isAgree) {
      message.warning('Please check the agreement down below');
      return;
    }
    if (!value) onFinish({ payload: {} });
    else {
      onFinish({
        total_income: value.total_income,
        financial_report: financialReport,
        id: data.id,
      });
    }
    form.resetFields();
  }
  return (
    <div className="modal-business">
      <div className="title-wrapper">
        <PdTitle
          align="center"
          color="black"
          size="extra-small"
          weight="normal"
          className="title">
          Revenue Disbursement
        </PdTitle>
      </div>
      <Divider />
      <div className="content">
        <Form form={form} className="form-wrapper" onFinish={handleFinish}>
          <Row gutter={16}>
            <Col lg={24}>
              <Form.Item
                {...halfLayout}
                className=""
                label="For Business"
                name="business_name">
                <PdAntdInput disabled={true} ref={ref} />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label={`Revenue Sharing for ${data.name}`}
                name="revenue_sharing">
                <PdAntdInput
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                  disabled={true}
                  ref={ref}
                  variant="number"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label={`Total Income Past ${data.dividend_period} Month`}
                name="total_income"
                rules={[
                  {
                    required: true,
                    message: 'Please input your total income!',
                  },
                ]}>
                <PdAntdInput
                  variant="number"
                  placeholder={`Write Total Income Past ${data.dividend_period} Month`}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  prefix={'IDR'}
                  ref={ref}
                  onChange={handleChangeIncome}
                />
              </Form.Item>
              <div className="pd-align-left pd-margin-bottom-md text-note">
                {`NFT Sold: ${data.nft_sold}/${
                  parseInt(data.nft_sold) + parseInt(data.number_of_nft)
                }`}{' '}
                <br />
                {income > 0 && (
                  <>
                    Disbursement:{' '}
                    {`(${currencyFormatter(income, '')} x ${
                      data.revenue_sharing
                    }%) x ${data.nft_sold}% = ${currencyFormatter(
                      ((income * parseInt(data.revenue_sharing)) / 100) *
                        (parseInt(data.nft_sold) /
                          (parseInt(data.nft_sold) +
                            parseInt(data.number_of_nft))),
                      ''
                    )}`}
                  </>
                )}
              </div>
              <Form.Item
                {...halfLayout}
                className=""
                label="Upload Document/Finance Report"
                name="financial_report"
                rules={[
                  {
                    required: true,
                    message: 'Please Upload Document/Finance Report',
                  },
                ]}>
                <PdUploader
                  accept="application/pdf image/*"
                  type="file"
                  afterUpload={setFinancialReport}
                  url={financialReport}
                  purpose="financial_report"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                valuePropName="checked"
                name="agreement"
                rules={[
                  {
                    required: true,
                    message: 'Please, you have to check for this agreement',
                  },
                ]}>
                <Checkbox onChange={onChange} className="pd-align-left">
                  You agree to pay for this period's revenue disbursement
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row justify="space-between" className="bottom-wrapper" gutter={20}>
            <Col>
              <PdButton
                type="default"
                className="bottom-button"
                color="blue"
                onClick={onClose}>
                Cancel
              </PdButton>
            </Col>
            <Col>
              <PdButton
                htmlType="submit"
                color="purple"
                className="bottom-button">
                Pay
              </PdButton>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
