import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';

import CompanyRouteWrapper from 'routes/CompanyRoute';
import InvestorRouteWrapper from 'routes/InvestorRoute';

import AuthenticationContextProvider from './contexts/authentication';

import { DetailBusiness } from 'pages/shared';
import { Explore } from 'pages/landing/Explore';
import { LoginPageForm } from 'pages/dashboard/Company/login';
import AuthenticatedGuardRoute from 'components/guard/authenticated';
import NotAuthenticatedGuardRoute from 'components/guard/notAuthenticated';

import LandingPage from 'pages/landing/Explore/landing';
// import NewLandingPage from 'pages/landing/New';
import LandingPageNewVersion from 'pages/landing/NewV2';
import FAQ from 'pages/landing/Faq';
import AllFAQ from 'pages/landing/Faq/allfaq';
import DetailFAQ from 'pages/landing/Faq/detail';
import SearchFAQ from 'pages/landing/Faq/search';
import PrivacyPolicy from 'pages/landing/Terms/privacy';
import TermsOfUse from 'pages/landing/Terms/terms';

import 'config/antd.less';
import '@ant-design/compatible/assets/index.css';
import 'App.scss';


// Weird that we mix UI and routes
function DashboardAccount() {
  return (
    <Switch>
      <>
        <NotAuthenticatedGuardRoute
          exact
          path="/login"
          component={LoginPageForm}
        />
        <NotAuthenticatedGuardRoute
          exact
          path="/register"
          component={LoginPageForm}
        />

        <Route exact path="/" component={LandingPage} />
        <Route exact path="/new" component={LandingPageNewVersion} />
        <Route exact path="/explore" component={Explore} />
        <Route path="/explore/:id" component={DetailBusiness} />
        {/* faq */}
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/faq/investor/all" component={AllFAQ} />
        <Route exact path="/faq/company/all" component={AllFAQ} />
        <Route exact path="/faq/search" component={SearchFAQ} />
        <Route exact path="/faq/company/detail/:id" component={DetailFAQ} />
        <Route exact path="/faq/investor/detail/:id" component={DetailFAQ} />

        {/* privacy policy */}
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/term-of-use" component={TermsOfUse} />
      </>
    </Switch>
  );
}

// TODO: 404 page
// TODO: ErrorBoundary

function App() {
  const history = useHistory();

  return (
    <Router>
      <Switch>
        <AuthenticationContextProvider history={history}>
          <Switch>
            <AuthenticatedGuardRoute
              path="/company"
              component={CompanyRouteWrapper}
            />
            <AuthenticatedGuardRoute
              destinationToRedirect="/explore"
              path="/investor"
              component={InvestorRouteWrapper}
            />
            <Route path="/" component={DashboardAccount} />
          </Switch>
        </AuthenticationContextProvider>
      </Switch>
    </Router>
  );
}

export default App;
