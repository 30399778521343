import { useEffect, useState } from 'react';
import GuestAPI from 'api/GuestAPI';

import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';
import { Col, message, Row } from 'antd';

import { Link } from 'react-router-dom';
import { capitalize } from 'utils/string-utility';

import Footer from 'components/landing-components/Footer';
import JoinOurBusinessOwner from 'components/landing-components/JoinBusinessOwner';
import Navbar from 'components/landing-components/Navbar';

import './style.scss';

const AllFAQ = () => {
  const loc = window.location.pathname.split('/')[2];
  const [data, setData] = useState({ left: [], right: [] });

  const splitData = (data = []) => {
    const limit = 10;
    const leftRow =
      data.count > limit ? data.rows.splice(data.count / 2) : data.rows;
    const rightRow = data.count > limit ? data.rows : [];
    return { right: rightRow, left: leftRow };
  };
  useEffect(() => {
    const getAllFaq = async () => {
      try {
        if (loc === 'investor') {
          const { data: investor } = await GuestAPI.getFaq(`filter=Investor`);

          const splitted = splitData(investor);
          setData(splitted);
        } else {
          const { data: company } = await GuestAPI.getFaq(`filter=Company`);

          const splitted = splitData(company);
          setData(splitted);
        }
      } catch (error) {
        message.error('Error while trying to get faqs..');
      }
    };
    getAllFaq();
  }, [loc]);
  return (
    <>
      <Navbar />
      <div className="faq-wrapper">
        <Row align="middle" className="breadcumb">
          <Link to="/faq">Tykoon Help Center</Link>
          <RightOutlined />
          <div>{capitalize(loc)} Questions</div>
        </Row>
        <div className="detail-question">{capitalize(loc)} Questions</div>
        <Row gutter={24} className="content-wrapper" align="top">
          <Col span={12}>
            {data.left.map((data) => (
              <Link
                to={`/faq/${loc}/detail/${data.id}`}
                className="question"
                key={data.id}>
                <Row justify="space-between" align="middle">
                  <p className="question-text">{data.title}</p>
                  <ArrowRightOutlined />
                </Row>
              </Link>
            ))}
          </Col>
          {data.right.length > 0 && (
            <Col span={12}>
              {data.right.map((data) => (
                <Link
                  to={`/faq/${loc}/detail/${data.id}`}
                  className="question"
                  key={data}>
                  <Row justify="space-between" align="middle">
                    <p className="question-text">{data.title}</p>
                    <ArrowRightOutlined />
                  </Row>
                </Link>
              ))}
            </Col>
          )}
        </Row>
      </div>
      <JoinOurBusinessOwner className={'pd-margin-bottom-lg'} />
      <Footer />
    </>
  );
};

export default AllFAQ;
