import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Layout, Row, message } from 'antd';

import moment from 'moment';

import { DashboardContainer } from 'components/dashboard-container';
import { PdAntdDatePicker } from 'components/pd-antd-date-picker';
import { PdAntdSelect } from 'components/pd-antd-select';
import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import AskVerif from 'components/pd-modal/verif-ask';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import PdUploader from 'components/pd-uploader';

import CompanyAPI from 'api/CompanyAPI';

import { useAuthContext } from 'contexts/authentication';

const halfLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
  labelAlign: 'left',
};

export function VerifyForm() {
  const ref = createRef();
  const [form] = Form.useForm();
  const history = useHistory();
  const { authenticatedUserProfile } = useAuthContext();
  const { companyName, fullName } = authenticatedUserProfile;

  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [selfieKtp, setSelfieKtp] = useState(null);
  const [modal, setModal] = useState({ type: '', visible: false });
  const [formValues, setFormValues] = useState({});

  async function handleSubmit(values) {
    setLoading(true);
    try {
      const {
        full_name,
        birth_day,
        no_ktp,
        company_name,
        npwp,
        company_website,
        company_social_media,
        bank,
        account_name,
        bank_account,
      } = values;

      const payload = {
        full_name,
        birth_day: moment(birth_day).format('YYYY-MM-DD'),
        no_ktp,
        selfie_ktp: selfieKtp,
        company_name,
        npwp,
        company_website,
        company_social_media,
        bank,
        account_name,
        bank_account,
      };

      await CompanyAPI.verifUser(payload);

      message.success('Data has successfully sent');
      history.push('/company/dashboard');
    } catch (err) {
      console.log(err);
      message.error(err?.response?.data?.message);
    } finally {
      handleCloseModal();
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleSetFormValues = (value) => {
    setFormValues(value);
    setModal({ type: 'verification', visible: true });
  };

  const handleVerifAnswer = (val) => {
    if (val === 'yes') {
      handleSubmit(formValues);
    } else {
      handleCloseModal();
    }
  };

  const categories = useCallback(() => {
    return [
      { name: 'BCA' },
      { name: 'BNI' },
      { name: 'Mandiri' },
      { name: 'BRI' },
    ];
  }, []);

  const catList = useMemo(() => {
    const output = categories().map(({ name }) => ({
      text: name,
      value: name,
    }));

    return [...output];
  }, [categories]);

  const handleChangeCat = (val) => {
    setCategory(val);
  };

  const modalContent = {
    verification: <AskVerif onFinish={handleVerifAnswer} />,
    // success: <Success />,
  };

  useEffect(() => {
    async function getInitialForms() {
      const { data } = await CompanyAPI.getUserProfile();
      const { CompanyForms = [] } = data;
      const isNotFirstFilled = CompanyForms.length;
      if (isNotFirstFilled) {
        const {
          full_name,
          birth_day,
          no_ktp,
          selfie_ktp,
          company_name,
          npwp,
          company_website,
          company_social_media,
          bank,
          account_name,
          bank_account,
        } = CompanyForms[isNotFirstFilled - 1];
        setSelfieKtp(selfie_ktp);
        form.setFieldsValue({
          full_name,
          company_website,
          company_social_media,
          birth_day: moment(birth_day),
          no_ktp,
          selfie_ktp,
          company_name,
          npwp,
          bank,
          account_name,
          bank_account,
        });
      } else {
        form.setFieldsValue({
          full_name: fullName,
          company_name: companyName,
        });
      }
    }
    getInitialForms();
  }, [companyName, form, fullName]);

  return (
    <Layout>
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader line="line">
        <PdTitle size="small" className="pd-margin-top-sm">
          Verify Account
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer className="pd-cms-content">
        <Form form={form} onFinish={handleSetFormValues}>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">Personal Information</PdTitle>
                <p>This section will be provide</p>
              </div>
              <Form.Item
                {...halfLayout}
                className=""
                label="Full Name"
                name="full_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your fullname!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Full Name"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Birthday"
                name="birth_day"
                rules={[
                  {
                    required: true,
                    message: 'Please input your birth date!',
                  },
                ]}>
                <PdAntdDatePicker
                  ref={ref}
                  placeholder="Birthday date"
                  className="w-quarter h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="No KTP"
                name="no_ktp"
                rules={[
                  {
                    required: true,
                    message: 'Please input your KTP number!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write KTP number"
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Selfie with KTP"
                name="selfie_ktp"
                rules={[
                  {
                    required: true,
                    message: 'Please input your selfie with KTP!',
                  },
                ]}>
                <PdUploader
                  accept="image/jpg, image/png, image/jpeg, image/gif"
                  type="image"
                  afterUpload={setSelfieKtp}
                  url={selfieKtp}
                  purpose="selfie_ktp"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">Company Information</PdTitle>
                <p>This section will be provide</p>
              </div>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company Name"
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company name!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Company Name"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company NPWP"
                name="npwp"
                rules={[
                  {
                    required: true,
                    message: 'Please input your NPWP number!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  variant="number"
                  ref={ref}
                  placeholder="Write NPWP number"
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company Website"
                name="company_website"
                rules={[
                  {
                    required: false,
                    message: 'Please input your company website url!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Company Website URL"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company Social Media"
                name="company_social_media">
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Company Social Media"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">Bank Account Information</PdTitle>
                <p>This section will be provide</p>
              </div>
              <Form.Item
                {...halfLayout}
                className=""
                label="Bank Account Name"
                name="account_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your bank account name!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Bank Accont Name"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Bank Vendor"
                name="bank"
                rules={[
                  {
                    required: true,
                    message: 'Please select your bank!',
                  },
                ]}>
                <PdAntdSelect
                  ref={ref}
                  dataSet={catList}
                  optKey="value"
                  optValue="value"
                  optContent="text"
                  defaultValue={undefined}
                  placeholder="Select Bank Type"
                  className="w-full h-button"
                  onSelect={handleChangeCat}
                  value={category}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Account Number"
                name="bank_account"
                rules={[
                  {
                    required: true,
                    message: 'Please input your bank account number!',
                  },
                ]}>
                <PdAntdInput
                  variant="number"
                  disabled={loading}
                  placeholder="Write Bank Accont Number"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={20} className="pd-margin-bottom-xl">
            <Col span={3} style={{ justifyContent: 'end', display: 'flex' }}>
              <PdButton
                type="default"
                className="w-full"
                color="blue h-button"
                onClick={() => history.goBack()}>
                Cancel
              </PdButton>
            </Col>
            <Col span={3} style={{ justifyContent: 'end', display: 'flex' }}>
              <PdButton
                htmlType="submit"
                color="purple"
                loading={loading}
                className="w-full h-button">
                Verify Now
              </PdButton>
            </Col>
          </Row>
        </Form>
      </DashboardContainer>
    </Layout>
  );
}
const VerifyForms = { VerifyForm }
export default VerifyForms;
