import { useCallback, useEffect, useState } from 'react';
import { Col, Layout, Pagination, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { DashboardContainer } from 'components/dashboard-container';
import CardNFT from 'components/landing-components/CardNFT';
import InvestorAPI from 'api/InvestorAPI';
import PdAntdHeader from 'components/pd-antd-header';
import { PdAntdInput } from 'components/pd-antd-input';
import PdTitle from 'components/pd-title';

import BgCard from 'assets/images/bg-card.png';

import './style.scss';
const Favorite = () => {
  const [searchValue, setSearchValue] = useState('');
  const [favoriteList, setFavoriteList] = useState([]);
  const [loading, setLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [isFetchPaused, setIsFetchPaused] = useState(false);

  const getAllFavorite = useCallback(async () => {
    if (isFetchPaused) return;
    const query = {
      page: currentPage - 1,
      limit: 6,
      brand_name: searchValue,
    };
    const params = new URLSearchParams(query);
    setLoading({ page: true });
    try {
      const { data: allFavorite = [] } = await InvestorAPI.getAllFavorite(
        params
      );

      setTotalData(allFavorite.pageInfo.totalData);

      //following investor-home
      const newData = allFavorite.data.map((data) => {
        const { id, nft_own, status, revenue, Bussine = {} } = data;

        return {
          id,
          nft_own,
          status,
          revenue,
          ...Bussine,
          favorite: true,
        };
      });
      setFavoriteList(newData);
    } catch (_) {
      setLoading({ page: false });
    } finally {
      setLoading({ page: false });
    }
  }, [currentPage, isFetchPaused, searchValue]);

  useEffect(() => {
    getAllFavorite();
  }, [getAllFavorite]);

  const onChangeSearch = (event) => {
    setIsFetchPaused(true);
    const {
      target: { value = '' },
    } = event;
    setSearchValue(value);
    setTimeout(() => {
      setIsFetchPaused(false);
    }, 2000);
  };

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <Layout className="pd-favorite-page">
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Favorite
        </PdTitle>
      </PdAntdHeader>

      <DashboardContainer>
        <Row className="search-favorite">
          <Col span={7}>
            <PdAntdInput
              value={searchValue}
              size="large"
              variant="search"
              name="searchQuery"
              onChange={onChangeSearch}
              prefix={<SearchOutlined />}
              placeholder="Search"
              loading={isFetchPaused}
            />
          </Col>
        </Row>
        <Row
          align="middle"
          justify="center"
          className="pd-margin-top-md pd-margin-bottom-xl">
          <Col span={24}>
            <Row justify="flex-start" gutter={[16, 16]}>
              {/* {isFetchPaused ? <Spin /> : } */}

              {favoriteList.map((cardData) => (
                <Col span={8} className="gutter-row" key={cardData.id}>
                  <Link to={`/investor/product-detail/${cardData.id}`}>
                    <CardNFT
                      className={'pd-margin-top-lg'}
                      from="company"
                      role="investor"
                      isAuthenticated={true}
                      cover={BgCard}
                      data={cardData}
                      type={true}
                      refetch={getAllFavorite}
                    />
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <p className="pd-margin-bottom-none paginationt-text">
              Showing {favoriteList.length} Items out of {totalData} results
              found
            </p>
          </Col>
          <Col>
            <Pagination
              defaultCurrent={1}
              total={totalData}
              pageSize={6}
              onChange={onChangePage}
              current={currentPage}
            />
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
};

export { Favorite };
