import './style.scss';
import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Badge,
  Breadcrumb,
  Carousel,
  Col,
  Layout,
  message,
  Popover,
  Row,
  Tabs,
} from 'antd';
import {
  FacebookFilled,
  LinkOutlined,
  TwitterSquareFilled,
  WhatsAppOutlined,
} from '@ant-design/icons';

import contractABI from 'services/contracAbi.json';
import moment from 'moment';
import parse from 'html-react-parser';

import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import BusinessType from 'components/pd-modal/business-type';
import Navbar from 'components/landing-components/Navbar';
import PdAntdCollapse from 'components/pd-antd-collapse';
import PdTitle from 'components/pd-title';

import GuestAPI from 'api/GuestAPI';
import CompanyAPI from 'api/CompanyAPI';
import InvestorAPI from 'api/InvestorAPI';

import {
  addressSplitter,
  capitalize,
  currencyFormatter,
} from 'utils/string-utility';
import { isImage } from 'utils/params-nil';
import useMetamask from 'hooks/useMetamask';

import { Column } from '@ant-design/plots';

import { Failed, Success } from 'components/pd-modal/success';
import RequestChangeData from 'components/pd-modal/request-change-data';
import Minting from 'components/pd-modal/minting';
import PdTable from 'components/pd-table';
import MakeOffer from 'components/pd-modal/offers';

import medium from 'assets/icon/medium.svg';
import twitter from 'assets/icon/twitter.svg';
import discord from 'assets/icon/discord.svg';
import downloadIcon from 'assets/icon/download-icon.svg';
import businessDetail from 'assets/icon/business-detail.png';
import cctv from 'assets/icon/cctv.svg';
import companyDoc from 'assets/icon/company-document.svg';
import dummyBusiness from 'assets/images/dummy-detail-business.png';
import leftArrow from 'assets/icon/left-arrow.png';
import listingTable from 'assets/icon/listing-table.svg';
import offerTable from 'assets/icon/offer-table.png';
import option from 'assets/icon/option.svg';
import refresh from 'assets/icon/refresh.svg';
import offer from 'assets/images/offer.svg';
import share from 'assets/icon/share.svg';
import transaction from 'assets/icon/transaction.svg';

import _ from 'lodash';

const Web3 = require('web3');

const { Content } = Layout;

export function DetailBusiness(props) {
  const { location = {} } = props;
  const isInvestorLogin = localStorage.getItem('investor_token');

  const {
    connectWalletHandler,
    signer,
    allowanceAmount,
    mintingNFT,
    getBalanceForSpesificToken,
  } = useMetamask(false);

  const { id } = useParams();
  const history = useHistory();

  const [role, setRole] = useState('');
  const [modal, setModal] = useState({ type: '', visible: false });
  const [data, setData] = useState({});
  const [documentData, setDocumentData] = useState([]);
  const [requestDescription, setRequestDescription] = useState('');
  const [mintLoading, setMintLoading] = useState(false);
  const [metaBalance, setMetaBalance] = useState(0);
  const [currInvestorNFTOwn, setCurrInvestorNFTOwn] = useState([]);

  //offers table
  const [offerData, setOfferData] = useState([]);

  //listing table
  const [listingData, setListingData] = useState([]);

  const {
    // dividend_period,
    // expected_apy,
    // fair_value = 0,
    // fund_goal = 0,
    // fundraising_status,
    // InvestorCount = 0,
    // nft_sold = 0,
    // price_of_nft = 0,
    additional_logos = [],
    average_apy,
    branch_location,
    brand_description = '',
    brand_name = 'Business NFT#1',
    bussines_type = '',
    BussinessArticles = [],
    company_name = 'Company Inc.',
    expected_monthly_return,
    expiry_year,
    first_period,
    fund_goal,
    id: businessId = '',
    launch_date,
    logo,
    nft_sold,
    number_of_nft = 0,
    operation_type,
    revenue_sharing,
    RevenueDisbursements = [],
    smart_contract,
    total_investor,
  } = data;

  const chartCol = [
    {
      type: '1st Period',
      sales: 38,
    },
    {
      type: '2nd Period',
      sales: 52,
    },
    {
      type: '3rd Period',
      sales: 61,
    },
    {
      type: '4th Period',
      sales: 145,
    },
    {
      type: '5th Period',
      sales: 48,
    },
    {
      type: '6th Period',
      sales: 38,
    },
    {
      type: '7th Period',
      sales: 38,
    },
    {
      type: '8th Period',
      sales: 38,
    },
  ];

  const chartConfig = {
    data: chartCol,
    xField: 'type',
    yField: 'sales',
    label: {
      // 可手动配置 label 数据标签位置
      position: 'middle',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: 'Revenue Disbursement',
      },
      sales: {
        alias: 'Periode Januari-Februari',
      },
    },
    columnWidthRatio: 0.5,
  };

  const [activeImage, setActiveImage] = useState(logo);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const ROLE_LIST = ['company', 'investor'];
    const getRole = () => {
      const currentRole = location.pathname.split('/')[1];

      if (ROLE_LIST.includes(currentRole)) {
        setRole(currentRole);
      } else {
        setRole('guest');
      }
    };
    getRole();
  }, [location.pathname]);

  const getBusinessDetail = useCallback(async () => {
    if (role === 'guest') {
      const { data: guestResponse = [] } = await GuestAPI.getMarketPlaceDetail(
        id
      );
      setData(guestResponse);
    }

    if (role === 'company') {
      try {
        const { data: companyResponse } = await CompanyAPI.getBusinessDetail(
          id
        );
        setData(companyResponse);
      } catch (error) {
        if (error.response.status === 404) {
          history.push(`/company/dashboard`);
        }
      }
    }

    if (role === 'investor') {
      try {
        const { data: investorResponse } = await InvestorAPI.getBusinessDetail(
          id
        );
        setData(investorResponse);
      } catch (error) {
        if (error.response.status === 404) {
          history.push('/investor/my-business');
        }
      }
    }
  }, [role, id, history]);

  const getBusinessDocument = useCallback(async () => {
    if (role !== 'company') return;

    try {
      const { data } = await CompanyAPI.getProductDocument(brand_name);
      const { rows = [] } = data;
      setDocumentData(rows);
    } catch (_) {
      setModal({ type: 'failed', visible: true });
    }
  }, [role, brand_name]);

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleOpenRequestModal = () => {
    setModal({ type: 'requestChangeData', visible: true });
  };

  const onChangeRequestData = ({ target }) => {
    const { value = '' } = target;
    setRequestDescription(value);
  };

  const onSubmitRequestData = async () => {
    if (!requestDescription) return;
    try {
      await CompanyAPI.postRequestEdit(businessId, {
        request_description: requestDescription,
      });
      message.success('Request Sent');
      handleCloseModal();
    } catch (_) {
      message.success('Network error');
      setRequestDescription('');
      handleCloseModal();
    } finally {
      handleCloseModal();
      setRequestDescription('');
    }
  };

  const handleMinting = async (number_of_nft) => {
    var web3 = new Web3(Web3.givenProvider);
    const { contractAddress } = await web3.eth.getTransactionReceipt(
      smart_contract
    );

    try {
      setMintLoading(true);
      await allowanceAmount(contractAddress, number_of_nft * 1000000);
    } catch (error) {
      message.error('Error while allowing balance of token...');
    } finally {
      setMintLoading(false);
    }

    handleCloseModal();

    const payload = {
      bussines_id: parseInt(id),
      nft_own: number_of_nft,
    };

    try {
      const hash = await mintingNFT(
        contractAddress,
        contractABI,
        signer,
        number_of_nft
      );
      // const { hash } = await nftContract.safeMint(number_of_nft);
      await InvestorAPI.addBusiness(payload);
      setModal({ type: 'success', visible: true, data: hash });
    } catch (error) {
      message.error('Failed minting...!');
      console.log(error);
    }
  };

  //handling on submit offering
  const handleOffering = async (priceOffers) => {
    try {
      await InvestorAPI.makeOffer(businessId, {
        offer_price: priceOffers,
      });
      setModal({ type: 'successOffering', visible: true });
      setTimeout(() => {
        getTables();
      }, 1200);
    } catch (error) {
      message.error('Error while making offers...');
    }
  };

  //handling on submit listing
  const handleSelling = async (value) => {
    try {
      const payload = {
        listing_price: value.listing_price,
        nft_count: value.nft_count,
        current_nft: currInvestorNFTOwn,
      };
      await InvestorAPI.makeListing(businessId, payload);
      setTimeout(() => {
        getTables();
      }, 1200);
      setModal({ type: 'successOffering', visible: true });
    } catch (error) {
      message.error('Error while making selling...');
    }
  };

  //action minting, offers, sell
  const handleButtonAction = async (action) => {
    if (isInvestorLogin) {
      setModal({ type: action, visible: true });
    } else {
      message.info('Please connect to Metamask and Login as Investor!');
      connectWalletHandler();
    }
  };

  const getTables = useCallback(async () => {
    if (isInvestorLogin) {
      try {
        const currentLogin = JSON.parse(
          localStorage.getItem('investor_user')
        ).wallet_address;
        const { data: countNFTOwn } = await InvestorAPI.getNFTOwn(id);
        const { data: offerData } = await InvestorAPI.getOffersTable(id);
        const { data: listingData } = await InvestorAPI.getListingsTable(id);

        // filtering listing tabel with wallet address so nft owned count of this investor will realtime
        const filteredListing = listingData.filter(
          (data) => data.Investor.wallet_address === currentLogin
        );
        console.log(filteredListing, countNFTOwn, 'listing');

        if (countNFTOwn.nft_id === null) {
          setCurrInvestorNFTOwn(0);
        } else {
          setCurrInvestorNFTOwn(
            countNFTOwn.nft_id.length - filteredListing.length
          );
        }

        setOfferData(offerData);
        setListingData(listingData);
      } catch (error) {
        console.log(error, 'error');
        message.error('Error while getting tables data..');
      }
    }
  }, [id, isInvestorLogin]);

  const handleCopyClipboard = (data) => {
    message.success('Text copied to clipboard!');
    navigator.clipboard.writeText(data);
  };

  useEffect(() => {
    getBusinessDetail();
    getBusinessDocument();
    getTables();
  }, [getBusinessDetail, getBusinessDocument, getTables]);

  useEffect(() => {
    const gettingBal = async () => {
      const bal = await getBalanceForSpesificToken();
      setMetaBalance(bal);
    };
    gettingBal();
  }, [getBalanceForSpesificToken]);

  const modalContent = {
    businessType: <BusinessType />,
    requestChangeData: (
      <RequestChangeData
        onChange={onChangeRequestData}
        onFinish={onSubmitRequestData}
      />
    ),
    failed: <Failed title="Oops!" desc="Network Error" />,
    minting: (
      <Minting
        onFinish={handleMinting}
        onClose={handleCloseModal}
        loading={mintLoading}
        isPrefilled={bussines_type === 'existing'}
      />
    ),
    success: (
      <Success
        title="Successfully minted!"
        desc={
          <>
            <p>{`Check your transaction by copying this hash ${_(
              modal.data
            ).truncate({ length: 20 })}`}</p>
            <p onClick={() => handleCopyClipboard(modal.data)}>Copy</p>
          </>
        }
      />
    ),
    sell: (
      <Minting
        onFinish={handleSelling}
        onClose={handleCloseModal}
        loading={false}
        alertDesc="Tykoon handles the transfer of NFT ownership when you buy/sale an NFT. Once the sale is complete, the NFT ownership isn’t yours ."
        useFor="selling"
        nftOwned={currInvestorNFTOwn}
      />
    ),
    offers: (
      <MakeOffer
        onFinish={handleOffering}
        onClose={handleCloseModal}
        loading={false}
        balance={metaBalance}
        alertDesc="Tykoon has the ability to facilitate the transfer of funds from a buyer's account to a seller's account in order to purchase a non-fungible token (NFT). This ensures that the seller receives the correct amount for the NFT being purchased."
      />
    ),
    successOffering: (
      <Success
        title="Successfully making an offer!"
        desc={
          <>
            <p>Your offering will be listed in offering table!</p>
          </>
        }
      />
    ),
    delete: (
      <Failed
        title="Oops! You have deleted your listing"
        desc="Successfully deleted"
      />
    ),
  };

  const handleRefresh = () => {
    getBusinessDetail();
  };

  const RenderBreadCrumbs = () => {
    return (
      <Breadcrumb className="breadcrumb-wrapper" separator=">">
        <Breadcrumb.Item>
          <Link to={`/${role}/dashboard`} className="dashboard-text">
            Dashboard
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item className="nft-detail-text">
          NFT Detail
        </Breadcrumb.Item>
      </Breadcrumb>
    );
  };

  const ShareButton = () => {
    return (
      <Row gutter={16} style={{ padding: '12px' }}>
        <Col>
          <a
            style={{ color: 'black' }}
            target="blank"
            href={`https://www.facebook.com/sharer.php?u=http://staging.tykoon.ventures/explore/${id}`}>
            <FacebookFilled style={{ fontSize: '30px', cursor: 'pointer' }} />
          </a>
        </Col>
        <Col>
          <a
            target="blank"
            style={{ color: 'black' }}
            href={`https://twitter.com/intent/tweet?url=http://staging.tykoon.ventures/explore/${id}`}>
            <TwitterSquareFilled
              style={{ fontSize: '30px', cursor: 'pointer' }}
            />
          </a>
        </Col>
        <Col>
          <a
            target="blank"
            style={{ color: 'black' }}
            href={`https://api.whatsapp.com/send?text=http://staging.tykoon.ventures/explore/${id}`}>
            <WhatsAppOutlined style={{ fontSize: '30px', cursor: 'pointer' }} />
          </a>
        </Col>
        <Col
          onClick={() =>
            handleCopyClipboard(`http://staging.tykoon.ventures/explore/${id}`)
          }>
          <LinkOutlined style={{ fontSize: '30px', cursor: 'pointer' }} />
        </Col>
      </Row>
    );
  };

  const RenderBlogUpdates = () => {
    return BussinessArticles.map(({ title, detail_article, updateAt }, idx) => (
      <div className="blog-wrapper" key={idx}>
        <span>{moment(updateAt).format('YYYY-MM-DD')}</span>
        <h1>{title}</h1>
        <p>{parse(detail_article)}</p>
      </div>
    ));
  };

  const OfferAction = (data, use) => {
    const {
      Investor: { wallet_address },
      id: actionId,
    } = data;

    const currentLogin = JSON.parse(
      localStorage.getItem('investor_user')
    ).wallet_address;
    const isTheBidder = wallet_address === currentLogin;

    const handleRemoveOffers = async () => {
      if (use === 'offer') {
        try {
          await InvestorAPI.deleteOffers(id, actionId);
          setModal({ type: 'delete', visible: true });
        } catch (error) {
          message.error('Error while removing offers...');
        }
      } else {
        try {
          await InvestorAPI.deleteListing(id, actionId);
          setModal({ type: 'delete', visible: true });
        } catch (error) {
          message.error('Error while removing listings...');
        }
      }
      setTimeout(() => {
        getTables();
      }, 1200);
    };

    return (
      <div>
        {isTheBidder ? (
          <PdButton type="default" onClick={handleRemoveOffers}>
            Remove
          </PdButton>
        ) : (
          <PdButton type="default">Accept</PdButton>
        )}
      </div>
    );
  };

  // use = offer | listing
  const renderColumnProps = (use = 'offer') => {
    const columns = [
      {
        title: 'Price',
        dataIndex: use === 'offer' ? 'offer_price' : 'listing_price',
        key: 'price',
        render: (price) => currencyFormatter(price, 'IDRT'),
      },
      {
        title: 'Floor Difference',
        dataIndex: 'floor_difference',
        key: 'floor_diff',
      },
      // {
      //   title: 'Expiration',
      //   dataIndex: 'exp',
      //   key: 'exp',
      // },
      {
        title: 'From',
        dataIndex: 'Investor',
        key: 'investor',
        render: (investor) => addressSplitter(investor.wallet_address, 15),
      },
      {
        title: 'Action',
        key: 'action',
        render: (data) => OfferAction(data, use),
      },
    ];
    return columns;
  };

  return (
    <Layout className="pd-product-detail">
      {role === 'guest' && <Navbar />}

      <Content
        className={`${
          ['company', 'investor'].includes(role)
            ? 'pd-product-detail-wrapper'
            : 'pd-product-detail-wrapper-guest'
        } `}>
        {role !== 'company' ? (
          <div className="back-button-wrapper">
            <img
              onClick={() => history.goBack()}
              src={leftArrow}
              alt="left arrow"
            />
            <p onClick={() => history.goBack()}>Back to Dashboard</p>
          </div>
        ) : (
          <div className="breadcrumb-container">
            <RenderBreadCrumbs />
            <PdButton
              type="primary"
              color="black"
              onClick={handleOpenRequestModal}
              className="request-change-data-button">
              <p className="request-change-data-text">Request Change Data</p>
            </PdButton>
          </div>
        )}

        <PdModals
          visible={modal.visible}
          handleClose={handleCloseModal}
          headStyle={{ alignText: 'center' }}
          footer={null}
          wrapClassName="modal-business">
          {modalContent[modal.type]}
        </PdModals>

        <Row gutter={{ xs: 20, md: 30 }} justify="center">
          <Col xs={{ span: 24 }} md={{ span: 9 }}>
            <Row className="pd-margin-bottom-md">
              <img
                src={
                  activeImage
                    ? isImage(activeImage)
                      ? activeImage
                      : dummyBusiness
                    : isImage(logo)
                    ? logo
                    : dummyBusiness
                }
                alt="sea-icon"
                style={{ width: '100%', borderRadius: '20px' }}
                loading="lazy"
              />
            </Row>
            <Carousel
              slidesPerRow={4}
              dotPosition="bottom"
              className="pd-margin-bottom-md">
              {additional_logos.map((data, idx) => (
                <div style={{ border: '1px solid red' }}>
                  <img
                    onClick={(e) => setActiveImage(e.target.src)}
                    src={isImage(data) ? data : dummyBusiness}
                    alt="sea-icon"
                    style={{
                      width: '95px',
                      height: '95px',
                      objectFit: 'cover',
                      borderRadius: '20px',
                      cursor: 'pointer',
                    }}
                    loading="lazy"
                  />
                </div>
              ))}
            </Carousel>
            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse
                image={businessDetail}
                text="Business Detail"
                data={'test'}>
                <Row className="business-detail">
                  <Col span={24} className="brand-desc pd-margin-bottom-md">
                    {brand_description}
                  </Col>
                  <Col span={24} className="brand-desc">
                    Launch Date:{' '}
                    {launch_date === null
                      ? '-'
                      : moment(launch_date).format('MMM Do YY')}
                  </Col>
                  <Col span={24} className="brand-desc">
                    Operational Date:{' '}
                    {first_period === null
                      ? '-'
                      : moment(first_period).format('MMM Do YY')}
                  </Col>
                  <Col span={24} className="brand-desc">
                    Expiry Year: {expiry_year}
                  </Col>
                  <Col span={24} className="brand-desc">
                    Type:{' '}
                    {operation_type === 'revenue_sharing'
                      ? 'Revenue Sharing'
                      : '-'}
                  </Col>
                  <Col span={24} className="brand-desc">
                    Location: {branch_location}
                  </Col>
                  <Col>
                    <div className="social-box first-box">
                      <img src={discord} alt="discord" />
                    </div>
                  </Col>
                  <Col>
                    <div className="social-box">
                      <img src={twitter} alt="discord" />
                    </div>
                  </Col>
                  <Col>
                    <div className="social-box last-box">
                      <img src={medium} alt="medium" />
                    </div>
                  </Col>
                </Row>
              </PdAntdCollapse>
            </Row>
            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse
                image={companyDoc}
                text="Company Document"
                data={documentData}>
                {documentData.map((doc) => {
                  const {
                    document_name = '',
                    id = '',
                    bussines_document = '',
                  } = doc;
                  return (
                    <Row
                      className="document-list"
                      key={id}
                      justify="space-between">
                      <Col>
                        <p
                          className={`${
                            documentData.length === 1 ? 'one-doc' : 'many-doc'
                          }`}>
                          {document_name}
                        </p>
                      </Col>
                      <Col>
                        <a href={bussines_document} download>
                          <img src={downloadIcon} alt="download document" />
                        </a>
                      </Col>
                    </Row>
                  );
                })}
              </PdAntdCollapse>
            </Row>

            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse image={cctv} text="CCTV" data={'test'} />
            </Row>
          </Col>

          <Col xs={{ span: 24 }} md={{ span: 15 }}>
            <Row justify="space-between">
              <Col>
                <p>{company_name}</p>
                <Row align="middle">
                  <PdTitle size="small">{brand_name}</PdTitle>
                  <Badge
                    count={capitalize(
                      bussines_type === 'existing' ? 'operating' : 'fundraising'
                    )}
                    className="pd-margin-bottom-md pd-margin-left-md"
                    style={{ backgroundColor: '#44ABFF' }}
                  />
                </Row>
              </Col>
              <Col>
                <Row className="button-wrapper">
                  <div className="button-detail">
                    <img src={refresh} alt="refresh" onClick={handleRefresh} />
                  </div>
                  <div className="button-detail">
                    <Popover
                      content={<ShareButton />}
                      trigger="click"
                      open={open}
                      onOpenChange={handleOpenChange}>
                      <img src={share} alt="share" />
                    </Popover>
                  </div>
                  <div className="button-detail">
                    <img src={option} alt="menu options" />
                  </div>
                </Row>
              </Col>
            </Row>
            {/* CARDS */}
            <Row
              justify="start"
              gutter={role === 'guest' ? [24, 24] : [16, 16]}
              className="pd-margin-bottom-md">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}>
                <div className="card-counter">
                  <h1>{total_investor === null ? '-' : total_investor}</h1>
                  <p className="pd-text-body-card">Investor</p>
                </div>
              </Col>
              <Col
                className="gutter-row "
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}>
                <div className="card-counter">
                  <h1>-</h1>
                  <p className="pd-text-body-card">Last Traded Price</p>
                </div>
              </Col>
              <Col
                className="gutter-row "
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}>
                <div className="card-counter">
                  <h1>
                    {revenue_sharing === null ? '-' : `${revenue_sharing}%`}
                  </h1>
                  <p className="pd-text-body-card">Rev. Sharing</p>
                </div>
              </Col>
              <Col
                className="gutter-row "
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}>
                <div className="card-counter">
                  <h1>
                    {expected_monthly_return === null
                      ? '-'
                      : currencyFormatter(
                          parseInt(expected_monthly_return),
                          'IDRT'
                        )}
                  </h1>
                  <p className="pd-text-body-card">Exp Monthly Return</p>
                </div>
              </Col>
              <Col
                className="gutter-row "
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}>
                <div className="card-counter">
                  <h1>
                    {nft_sold === null ? `-/${number_of_nft}` : `${nft_sold}`}
                  </h1>
                  <p className="pd-text-body-card">Total Share</p>
                </div>
              </Col>
              <Col
                className="gutter-row "
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}>
                <div className="card-counter">
                  <h1>
                    {fund_goal === null
                      ? '-'
                      : currencyFormatter(parseInt(fund_goal), 'IDRT')}
                  </h1>
                  <p className="pd-text-body-card">Business Valuation</p>
                </div>
              </Col>
              <Col
                className="gutter-row "
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}>
                <div className="card-counter">
                  <h1>{average_apy == null ? '-' : `${average_apy}%`}</h1>
                  <p className="pd-text-body-card">Avg APY</p>
                </div>
              </Col>
              <Col
                className="gutter-row "
                xs={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}>
                <div className="card-counter">
                  <h1>
                    {RevenueDisbursements.length > 0
                      ? RevenueDisbursements[RevenueDisbursements.length - 1]
                          .revenue_per_nft === null
                        ? '-'
                        : currencyFormatter(
                            parseInt(
                              RevenueDisbursements[
                                RevenueDisbursements.length - 1
                              ].revenue_per_nft
                            ),
                            'IDRT'
                          )
                      : '-'}
                  </h1>
                  <p className="pd-text-body-card">Curr-Period Rev Share</p>
                </div>
              </Col>
            </Row>
            {/* open sea and make offer button */}
            <div className="wrapper-price">
              <div className="pd-margin-bottom-md">
                <div className="price-label">Current Price:</div>
                <div className="price-idrt">IDRT 1.200.000</div>
              </div>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <PdButton
                    type="primary"
                    color="blue"
                    disabled={number_of_nft === '0'}
                    onClick={() => handleButtonAction('minting')}
                    className="pd-button-primary h-button-primary w-full">
                    Buy NFT
                  </PdButton>
                </Col>
                <Col span={8}>
                  <PdButton
                    type="default"
                    color="blue"
                    // show hanya di operating dan sisi ui yang punya nftnya, sisanya disable
                    disabled={
                      bussines_type !== 'existing'
                        ? false
                        : currInvestorNFTOwn < 1
                    }
                    onClick={() => handleButtonAction('sell')}
                    className="pd-button-primary h-button-primary w-full">
                    Sell
                  </PdButton>
                </Col>
                <Col span={8}>
                  <PdButton
                    type="default"
                    color="blue"
                    // show hanya di operating dan sisi ui yang tdk dan punya nftnya, sisanya disable
                    disabled={bussines_type !== 'existing'}
                    onClick={() => handleButtonAction('offers')}
                    className="pd-button-primary h-button-primary w-full"
                    icon={
                      <img
                        src={offer}
                        alt="sea-icon"
                        className="open-sea-icon"
                        style={{ marginRight: 10 }}
                      />
                    }>
                    Make Offer
                  </PdButton>
                </Col>
              </Row>
            </div>
            {isInvestorLogin && (
              <>
                <Row className="pd-margin-bottom-md">
                  <PdAntdCollapse
                    image={listingTable}
                    text="Listing Table"
                    className="noPadding">
                    <PdTable
                      openFilter={false}
                      columns={renderColumnProps('listing')}
                      data={listingData}
                      isHeader={false}
                    />
                  </PdAntdCollapse>
                </Row>
                <Row className="pd-margin-bottom-md">
                  <PdAntdCollapse
                    image={offerTable}
                    text="Offer Table"
                    className="noPadding">
                    <PdTable
                      openFilter={false}
                      columns={renderColumnProps('offer')}
                      data={offerData}
                      isHeader={false}
                    />
                  </PdAntdCollapse>
                </Row>
              </>
            )}
            <Row className="pd-margin-bottom-md">
              <PdAntdCollapse
                image={transaction}
                text="Transaction Activity"
                data={'test'}
              />
            </Row>
          </Col>
        </Row>
        <Row>
          <Tabs
            defaultActiveKey="1"
            style={{ width: '100%' }}
            items={[
              {
                label: `Updates`,
                key: '1',
                children: BussinessArticles.length ? (
                  <RenderBlogUpdates />
                ) : (
                  'There is no articles published in this business'
                ),
              },
              {
                label: `Documents`,
                key: '2',
                children: `There is no documents published in this business`,
              },
              {
                label: `Financials`,
                key: '3',
                children: <Column {...chartConfig} />,
              },
            ]}
          />
        </Row>
      </Content>
    </Layout>
  );
}
