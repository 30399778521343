import { Collapse } from 'antd';

import './style.scss';
const { Panel } = Collapse;

const PdAntdCollapse = ({ children, image = null, text = '', ...props }) => {
  return (
    <Collapse
      className="pd-collapse"
      expandIconPosition="end"
      defaultActiveKey={['1']}
      {...props}
      style={{ width: '100%' }}>
      <Panel
        key={'1'}
        header={
          <>
            {image && (
              <img
                src={image}
                alt="sea-icon"
                style={{ marginRight: '.5rem' }}
              />
            )}
            {text}
          </>
        }>
        <div>{children}</div>
      </Panel>
    </Collapse>
  );
};

export default PdAntdCollapse;
