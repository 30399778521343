import { createRef } from 'react';
import { Alert, Col, Divider, Form, Row, Spin } from 'antd';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import PdTitle from 'components/pd-title';

import './style.scss';
import { currencyFormatter } from 'utils/string-utility';

/**
 *
 * @param {Function} props.onFinish function that execute when button clicked or submitted
 * @param {Function} props.onClose function that close modal
 * @param {Function} props.loading loading props
 * @param {Function} props.useFor 'minting' | 'selling'
 */

const halfLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  labelAlign: 'left',
};

// this component can receive mint and sell action.
//

export default function MakeOffer({
  onFinish = () => {},
  onClose = () => {},
  loading = false,
  alertDesc = "Tykoon system moves amount needed from your account to the seller's account to pay for the NFT you're buying. This process is secure and handled by Tykoon system.",
  balance = 0,
  bestOffer = '1000000',
}) {
  const ref = createRef();
  const [form] = Form.useForm();

  function handleFinish(value) {
    onFinish(value.offer_price);
    onClose();
    form.resetFields();
  }

  return (
    <div className="modal-business">
      {loading && (
        <div className="loading-wrapper">
          <div className="loading">
            <Spin />
          </div>
          <div>Waiting for pending transaction during minting process...</div>
        </div>
      )}
      {!loading && (
        <>
          <div className="title-wrapper">
            <PdTitle
              align="center"
              color="black"
              size="extra-small"
              weight="normal"
              className="title">
              Make an Offer
            </PdTitle>
          </div>
          <Divider />
          <div className="content">
            <Alert message={alertDesc} type={'error'} showIcon />

            <div className="detail-wrapper">
              <div className="detail">
                <div>Balance Metamask</div>
                <div>{currencyFormatter(balance, 'IDRT')}</div>
              </div>
              <div className="detail">
                <div>Best Offer Price</div>
                <div>{currencyFormatter(parseInt(bestOffer), 'IDRT')}</div>
              </div>
            </div>
            <Form form={form} className="form-wrapper" onFinish={handleFinish}>
              <Row gutter={16} justify="start">
                <Col lg={24}>
                  <Form.Item
                    {...halfLayout}
                    className=""
                    label="Offer Price"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your offer price!',
                      },
                    ]}
                    name="offer_price">
                    <PdAntdInput
                      variant="number"
                      ref={ref}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      prefix={'IDR'}
                      placeholder="Write Offer Price"
                      className="h-button"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row
                justify="space-between"
                className="bottom-wrapper"
                gutter={20}>
                <Col>
                  <PdButton
                    type="default"
                    className="bottom-button"
                    color="blue"
                    onClick={onClose}>
                    Cancel
                  </PdButton>
                </Col>
                <Col>
                  <PdButton
                    htmlType="submit"
                    color="purple"
                    className="bottom-button">
                    Offer
                  </PdButton>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
