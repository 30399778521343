import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import homePageIcon from 'assets/icon/backtohome.svg';
import explore from 'assets/icon/explore.svg';
import profile from 'assets/icon/profile.svg';
import logout from 'assets/icon/logout.svg';

import { useAuthContext } from 'contexts/authentication';
import { PdDropdown } from 'components/pd-dropdown';
import CompanyAPI from 'api/CompanyAPI';

import './style.scss';

const PdProfileNav = ({ role = '' }) => {
  const [userProfile, setUserProfile] = useState({});
  useEffect(() => {
    if (role !== 'company') return;
    const getUserProfile = async () => {
      const { data } = await CompanyAPI.getUserProfile();
      const { CompanyForms = [] } = data;

      setUserProfile(CompanyForms[0]);
    };
    getUserProfile();
  }, [role]);

  const { authenticatedUserProfile, auth } = useAuthContext();
  const cms_token = localStorage.getItem('company_token');
  const wallet_address = localStorage.getItem('wallet_address');
  const modifiedWalletAddress = _(wallet_address).truncate({ length: 20 });
  const handleLogout = async () => {
    if (role === 'company' && cms_token) {
      localStorage.removeItem('company_token');
      localStorage.removeItem('current_user');
      localStorage.removeItem('form_id');
      localStorage.removeItem('wallet_address');
      localStorage.removeItem('investor_token');
      localStorage.removeItem('investor_user');
      localStorage.removeItem('role');
      window.location.reload();
    } else if (role === 'investor' && wallet_address) {
      localStorage.removeItem('role');
      localStorage.removeItem('wallet_address');
      localStorage.removeItem('investor_token');
      localStorage.removeItem('investor_user');
      window.location.reload();
    }
  };

  const accountChangedHandler = (newAccount) => {
    if (typeof newAccount === 'string') {
      const wallet = { wallet_address: newAccount };
      auth(wallet, 'login', 'investor');
      localStorage.setItem('wallet_address', JSON.stringify(newAccount));
    }
  };

  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    window.location.reload();
  };

  // listen for account changes
  if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on('accountsChanged', (res) =>
      accountChangedHandler(res[0], 'change')
    );
    window.ethereum.on('chainChanged', chainChangedHandler);
  }

  const menu = [
    {
      label: <Link to={`/${role}/dashboard`}>My Dashboard</Link>,
      key: 'dashboard',
      icon: <img src={homePageIcon} alt="home" />,
    },
    {
      label: <Link to={`/explore`}>Explore Business</Link>,
      key: 'exploreBusiness',
      icon: <img src={explore} alt="explore" />,
    },
    {
      label: <Link to={`/${role}/profile`}>My Profile</Link>,
      key: 'profile',
      icon: <img src={profile} alt="profile" />,
    },
    {
      label: <div onClick={handleLogout}>Logout</div>,
      key: 'logOut',
      icon: <img src={logout} alt="logout" />,
    },
  ];

  const currentUser =
    role === 'company'
      ? authenticatedUserProfile.fullName
      : role === 'investor'
      ? modifiedWalletAddress.charAt(1) + modifiedWalletAddress.slice(2)
      : '';

  return (
    <PdDropdown
      text={currentUser}
      color="transparent"
      type="text"
      menus={menu}
      isPadding={false}
      avatar={{
        img: role === 'company' ? userProfile.company_logo : null,
        render: true,
      }}
      textClassName="actionButtonText"
    />
  );
};

export { PdProfileNav };
