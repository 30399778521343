/**
 *
 * @param {('company' | 'investor',)} person
 * @returns
 */
function getAccessToken(person) {
  const initialHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  let access_token = localStorage.getItem(`${person}_token`);
  if (access_token) {
    return {
      headers: {
        Authorization: `Bearer ${access_token}`,
        ...initialHeaders,
      },
    };
  } else {
    return {
      headers: initialHeaders,
    };
  }
}

const getAccessTokens = { getAccessToken };

export default getAccessTokens;
