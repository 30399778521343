import { Col, Form, message, Row } from 'antd';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import PdTitle from 'components/pd-title';

import './style.scss';
import { createRef, useEffect, useState } from 'react';

/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

const halfLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  labelAlign: 'left',
};

export default function Withdraw({
  onFinish = () => {},
  onClose = () => {},
  data = 1,
  loading = false,
  gasFee = null,
}) {
  const ref = createRef();
  const [form] = Form.useForm();
  const [totalValue, setTotalValue] = useState(0);
  const [amountWithdraw, setAmountWithdraw] = useState(1000);

  // form.setFieldsValue({ total_amount: data, amount_withdraw: 1000 });
  function handleFinish(value) {
    if (value.amount_withdraw === 0) {
      message.error('You cannot withdraw without having a balance!');
    } else if (value.amount_withdraw > data) {
      message.error('You have not enough balance to withdraw');
      return;
    } else if (value.amount_withdraw < 1000) {
      message.error('Minimum withdraw is IDRT 1.000');
    } else {
      onFinish(value);
    }
  }

  const handleAmount = (e) => {
    if (e === null || e < 1000) {
      setTotalValue(0);
      return;
    } else {
      setAmountWithdraw(e);
      const tykoonFee = e * 0.02;
      const totalWithdraw = +(e - tykoonFee - gasFee).toFixed(1);
      setTotalValue(totalWithdraw);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      total_withdraw: totalValue,
      total_amount: data,
    });
  }, [totalValue, data, form]);

  return (
    <div className="investor-withdraw">
      <div className="header">
        <PdTitle align="center" color="black" size="extra-small">
          Withdraw
        </PdTitle>
      </div>
      <div className="content">
        <Form form={form} className="form-wrapper" onFinish={handleFinish}>
          <Row gutter={16}>
            <Col lg={24}>
              <Form.Item
                {...halfLayout}
                className=""
                label="Total Available Amount"
                name="total_amount">
                <PdAntdInput
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  prefix={'IDRT'}
                  disabled={true}
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Amount to Withdraw"
                name="amount_withdraw"
                rules={[
                  {
                    required: true,
                    message: 'Please input your amount to withdraw!',
                  },
                ]}>
                <PdAntdInput
                  variant="number"
                  placeholder="Write Amount to Withdrawy"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  prefix={'IDRT'}
                  ref={ref}
                  disabled={loading}
                  className="h-button"
                  onChange={handleAmount}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                label="Total Withdraw"
                name="total_withdraw"
                rules={[
                  {
                    required: true,
                    message: 'Please input your amount to withdraw!',
                  },
                ]}>
                <PdAntdInput
                  variant="number"
                  placeholder="Write Amount to Withdrawy"
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  prefix={'IDRT'}
                  ref={ref}
                  disabled={true}
                  className="h-button"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="start">
            <Form.Item>
              <Col style={{ textAlign: 'left' }}>
                <div className="text-note">
                  Tykoon Fee: 2%, Gas Fee: IDRT 896
                </div>
                <div className="text-note pd-margin-top-sm">
                  Total:{' '}
                  {`${amountWithdraw} - (${amountWithdraw} * ${2}%) - Gas Fee`}
                </div>
              </Col>
            </Form.Item>
          </Row>
          <Col className="bottom-wrapper">
            <Row
              className="pd-margin-left-md pd-margin-right-md"
              justify="space-between"
              gutter={20}>
              <Col>
                <PdButton
                  type="default"
                  className="w-full h-button "
                  color="blue"
                  onClick={onClose}>
                  Cancel
                </PdButton>
              </Col>
              <Col>
                <PdButton
                  loading={loading}
                  htmlType="submit"
                  color="purple"
                  className="h-button w-full ">
                  Withdraw
                </PdButton>
              </Col>
            </Row>
          </Col>
        </Form>
      </div>
    </div>
  );
}
