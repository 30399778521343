import { message } from 'antd';
import { useAuthContext } from 'contexts/authentication';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ethers } from 'ethers';
import { RANKERCONTRACTADDRESS, TYKOONWALLETADDRESS } from 'metamask/constant';

import RANKERABI from 'metamask/rankerABI.json';

const useMetamask = (redirect = true) => {
  const { auth } = useAuthContext();
  const history = useHistory();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const provider =
    window.ethereum && window.ethereum.isMetaMask
      ? new ethers.providers.Web3Provider(window.ethereum)
      : null;

  const signer = provider.getSigner();
  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((result) => {
          if (result.length > 0) {
            if (redirect) history.push('/investor/my-business');
            accountChangedHandler(result[0]);
          } else {
            setIsWalletConnected(false);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    } else {
      message.info('Need to install MetaMask');
      window.open(
        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
        '_blank'
      );
    }
  };

  const accountChangedHandler = (newAccount) => {
    if (typeof newAccount === 'string') {
      const wallet = { wallet_address: newAccount };
      auth(wallet, 'login', 'investor', redirect);
      localStorage.setItem('wallet_address', JSON.stringify(newAccount));
      setIsWalletConnected(true);
    }
  };

  async function send_token(
    contract_address, // token contract address e.g ranker contract address
    send_token_amount, // how many amount
    to_address, // transfer to who?
    send_account, // send from wallet tykoon
    private_key // tykoon private key wallet
  ) {
    let wallet = new ethers.Wallet(private_key);
    let walletSigner = wallet.connect(provider);

    if (contract_address) {
      const contractAbiFragment = [
        {
          name: 'transfer',
          type: 'function',
          inputs: [
            {
              name: '_to',
              type: 'address',
            },
            {
              type: 'uint256',
              name: '_tokens',
            },
          ],
          constant: false,
          outputs: [],
          payable: false,
        },
      ];
      const contract = new ethers.Contract(
        contract_address,
        contractAbiFragment,
        walletSigner
      );

      // How many tokens?
      const numberOfDecimals = 18;
      // can user decimals of 18/wei/gwei dst
      const numberOfTokens = ethers.utils.parseUnits(
        send_token_amount,
        numberOfDecimals
      );

      // Send tokens
      const tx = await contract.transfer(to_address, numberOfTokens, {
        gasPrice: ethers.utils.parseUnits('5', 'gwei'),
        gasLimit: 45000,
      });

      // transaction fee is gasLimit * gasPrice
      // but gasLimit is not always use all of the amount
      const txFee = ethers.utils.formatUnits(tx.gasLimit) * 10 ** 18 * 1;
      const ethValue = txFee / 10 ** 9;

      // the gas fee is in ether
      await tx.wait();
      return { hash: tx.hash, gasFee: ethValue };
    } // ether send
    else {
      const tx = {
        from: send_account,
        to: to_address,
        value: ethers.utils.parseUnits(send_token_amount, 18),
      };
      try {
        walletSigner.sendTransaction(tx).then((transaction) => {
          console.dir(transaction);
          alert('Send finished!');
        });
      } catch (error) {
        alert('failed to send!!');
      }
    }
  }

  // this is for sending a token to another wallet
  const sendTransaction = async (amount, receiverAdd) => {
    let private_key = process.env.REACT_APP_TYKOON_WALLET_KEY;
    const sender = '0xF97C7A13439DA91254B2D499685D52CC3E64E4EF';
    return send_token(
      RANKERCONTRACTADDRESS,
      amount,
      receiverAdd,
      sender,
      private_key
    );
  };

  // this is to get balance for spesifik token by wallet address
  // this is getting the balance from the BC not metamask but the result is the same
  const getBalanceForSpesificToken = async () => {
    const contract = new ethers.Contract(
      RANKERCONTRACTADDRESS,
      RANKERABI.result,
      provider
    );
    const balance = (await contract.balanceOf(TYKOONWALLETADDRESS)).toString();
    const balanceInEth = ethers.utils.formatEther(balance);
    const balanceInIDRT = balanceInEth * 19915429.35;
    console.log(balanceInIDRT, 'bal');
    return parseInt(balanceInIDRT);
  };

  // this is function to allowance amount to minting
  // before minting we need to pass amount to allowance the nft amount
  const allowanceAmount = async (smartContract, amount) => {
    const contract = new ethers.Contract(
      RANKERCONTRACTADDRESS,
      RANKERABI.result,
      signer
    );
    let receipt = null;

    while (receipt === null) {
      try {
        receipt = await contract.approve(smartContract, amount);
        await receipt.wait();
        if (receipt === null) {
          continue;
        }
      } catch (e) {
        console.log(`Receipt error:`, e);
        break;
      }
    }
  };

  // this is the function to minting nft
  // receives the contract address of the business, contract abi of the business, signer, and nft number
  // to be minted
  const mintingNFT = async (
    contractAddress,
    contractABI,
    signer,
    number_of_nft
  ) => {
    const contract = new ethers.Contract(
      contractAddress,
      contractABI.result,
      signer
    );
    const { hash } = await contract.safeMint(number_of_nft);
    return hash;
  };

  return {
    allowanceAmount,
    connectWalletHandler,
    isWalletConnected,
    provider,
    signer,
    sendTransaction,
    getBalanceForSpesificToken,
    mintingNFT,
  };
};

export default useMetamask;
