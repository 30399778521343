import { forwardRef } from 'react';
import { DatePicker } from 'antd';

import './style.scss';

export const PdAntdDatePicker = forwardRef(
  ({ className = '', format = 'MM/DD/YYYY', ...props }, ref) => {
    return (
      <DatePicker
        ref={ref}
        format={format}
        {...props}
        className={`pd-date-picker ${className}`}
      />
    );
  }
);

PdAntdDatePicker.displayName = 'PdDatePicker';

const PdAntdDatePickers = { PdDatePicker: PdAntdDatePicker };
export default PdAntdDatePickers;
