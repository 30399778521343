import { Link } from 'react-router-dom';
import { Alert, Row } from 'antd';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import RejectReason from 'components/pd-modal/reject-reason';

import { useAuthContext } from 'contexts/authentication';
import { useEffect, useState } from 'react';
import CompanyAPI from 'api/CompanyAPI';

const PdStickyFailedVerification = () => {
  const { authenticatedUserProfile } = useAuthContext();
  const { status: userStatus } = authenticatedUserProfile;
  const [modal, setModal] = useState({ type: '', visible: false });
  const [reason, setReason] = useState('');

  const getProfile = async () => {
    const { data } = await CompanyAPI.getUserProfile();
    const { RejectedReasons = [] } = data;

    if (RejectedReasons.length) {
      const rejected = RejectedReasons.pop();
      const { description } = rejected;

      setReason(description);
    }
  };
  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const modalContent = {
    rejected: <RejectReason onClose={handleCloseModal} reason={reason} />,
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      {userStatus === 'rejected' && (
        <Alert
          message={'Ooops your verification failed.'}
          type={'error'}
          showIcon
          action={
            <Row>
              <PdButton
                color="white"
                onClick={() => setModal({ type: 'rejected', visible: true })}>
                View Reason
              </PdButton>

              <Link to="/company/verify-account">
                <PdButton color="white" className="pd-margin-left-md">
                  Re-Submit
                </PdButton>
              </Link>
            </Row>
          }
        />
      )}
    </>
  );
};

export default PdStickyFailedVerification;
