import { useState } from 'react';
import { message, Upload } from 'antd';
import { Icon } from '@ant-design/compatible';
import { get, noop } from 'lodash';

import { PdButton } from 'components/pd-button';

import uploadApi from 'api/UploadAPI';
// import { InboxOutlined } from '@ant-design/icons';

import './style.scss';
const { Dragger } = Upload;

//allowedExtension = ['pdf','mp4','jpg','png','jpeg','gif'];

/**
 *
 * @param {Object} props
 * @param {() => {}} props.afterUpload - callback after beforeUpload is triggered
 * @param {string} props.alt- text if image isn't rendered
 * @param {string} props.icon - type of icon antd to display
 * @param {object} props.imgStyle - style of image
 * @param {('profile_pictures' | 'company_assets' | 'materials' | 'classroom_attachment' | 'classroom_assets')} props.purpose - beforeUpload params => based on BE
 * @param {('image' | 'video')} props.type - type of media
 * @param {string} props.url - media url to display afterUpload
 */

export default function PdUploader({
  afterUpload = () => {},
  alt = '',
  icon = 'inbox',
  imgStyle = {},
  purpose = '',
  type = 'image',
  url = '',
  uploadText = '',
  multiple = false,
  ...props
}) {
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [uploadList, setUploadList] = useState([]);

  // const UPLOAD_TEXT = {
  //   image: 'Click or drag to import an image',
  //   video: 'Click or drag to import a video',
  // };

  // create the jsx component here for the content
  const ImageContent = () => (
    <img
      className="img-container"
      style={imgStyle}
      alt={alt}
      src={url}
      width={300}
    />
  );

  const VideoContent = () => (
    <video className="img-container">
      <source src={url} alt={alt}></source>
    </video>
  );

  // Define which content based on type props here
  const CONTENT = {
    image: ImageContent,
    video: VideoContent,
  };

  const MediaContent = CONTENT[type];

  async function beforeUpload(file = {}, purpose) {
    try {
      setUploading(true);
      const { file: fileUploaded, fileList = [] } = file;
      const ext = fileUploaded.type.split('/')[1];
      const {
        data: { uploadUrl, objectUrl },
      } = await uploadApi.getUploadURL(ext, purpose);
      await uploadApi.uploadImage(fileUploaded, uploadUrl);

      message.success('Upload Success');
      if (multiple) {
        const additionalLogos = {
          id: fileUploaded.uid,
          objectUrl,
        };
        setUploadList((uploadList) => [...uploadList, additionalLogos]);
        afterUpload((uploadList) => [...uploadList, additionalLogos]);
      } else {
        afterUpload(objectUrl);
      }
      if (type === 'file') {
        fileList[0].status = 'done';
        setFileList([fileList[0]]);
      }
    } catch (err) {
      console.log(err, 'errrr');
      const errMessage = get(err, 'response.data.message');
      if (errMessage) {
        message.error(errMessage);
      } else {
        if (type === 'file') {
          if (fileList.length) {
            fileList[0].status = 'error';
            setFileList([fileList[0]]);
          } else {
            setFileList([]);
          }
        }
        message.error('Cannot connect to server, please check connection');
      }
    } finally {
      setUploading(false);
    }
  }

  async function handleChange(file) {
    await beforeUpload(file, purpose);
  }

  const propse = {
    name: 'image',
    multiple: multiple,
    beforeUpload: (file) => {
      beforeUpload({ file }, purpose);
    },
    customRequest: (d) => {
      d.onSuccess();
    },
    onRemove: (d) => {
      const news = uploadList.filter((data) => data.id !== d.uid);
      afterUpload(news);
      setUploadList(news);
    },
  };

  return type !== 'file' ? (
    <Dragger {...propse}>
      {!url || multiple ? (
        <div className="container">
          <p className="ant-upload-hint">
            Drop files here to upload (Recommended using ratio 1:1)
          </p>
        </div>
      ) : (
        <MediaContent />
      )}
    </Dragger>
  ) : (
    <Upload
      customRequest={noop}
      beforeUpload={(file, fileList) => handleChange({ file, fileList })}
      fileList={fileList}
      maxCount={1}
      {...props}>
      <PdButton
        color="blue"
        icon={<Icon type={uploading ? 'loading' : icon} />}>
        Choose File
      </PdButton>
    </Upload>
  );
}
