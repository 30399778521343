import { Form } from 'antd';

import { PdAntdInput } from 'components/pd-antd-input';

import './style.scss';

/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

export default function RequestChangeDataDetail({
  onFinish = () => {},
  onChange = () => {},
  data = {},
}) {
  const fields = [
    { name: 'date', value: data.date },
    { name: 'business', value: data.business },
    { name: 'requestDetail', value: data.requestDetail },
  ];
  return (
    <div className="request-change-data-detail-modal">
      <div className="title-wrapper">
        <p>Detail Request</p>
      </div>
      <div className="content">
        <Form
          id="request-change-data-form"
          layout="vertical"
          className="form-wrapper"
          requiredMark={false}
          fields={fields}>
          <Form.Item label="Created Date" name="date">
            <PdAntdInput
              className="pd-text-white"
              size="large"
              variant="text"
              disabled={true}
            />
          </Form.Item>
          <Form.Item label="For Business" name="business">
            <PdAntdInput
              className="pd-text-white"
              size="large"
              variant="text"
              disabled={true}
            />
          </Form.Item>
          <Form.Item label="Detail Request" name="requestDetail">
            <PdAntdInput
              className="pd-text-white"
              rows={4}
              size="large"
              variant="text-area"
              disabled={true}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
