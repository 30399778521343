import { Form } from 'antd';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import PdTitle from 'components/pd-title';

import './style.scss';

/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

export default function Forgot({ onFinish = () => {}, onLoading = false }) {
  return (
    <div className="pd-guest-modal-forgot">
      <div className="header">
        <PdTitle
          color="black"
          weight="normal"
          align="center"
          size="extra-small">
          Forgot Password
        </PdTitle>
        Enter the registered e-mail. We will send you a OtP code to reset your
        password on your account
      </div>
      <div className="content">
        <Form
          id="forgot-form"
          layout="vertical"
          className="form-wrapper"
          onFinish={onFinish}
          requiredMark={false}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}>
            <PdAntdInput
              type="email"
              className="h-button"
              placeholder="Enter email address"
            />
          </Form.Item>
          <Form.Item noStyle>
            <PdButton
              htmlType="submit"
              className="h-button"
              loading={onLoading}
              style={{ width: '100%' }}>
              Next
            </PdButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
