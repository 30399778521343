import { useCallback, useEffect, useState } from 'react';
import AllCardNFT from 'components/landing-components/AllCard';
import Footer from 'components/landing-components/Footer';
import Hero from 'components/landing-components/Hero';
import JoinOurBusinessOwner from 'components/landing-components/JoinBusinessOwner';
import Navbar from 'components/landing-components/Navbar';
import GuestAPI from 'api/GuestAPI';
import InvestorAPI from 'api/InvestorAPI';
import { message } from 'antd';

export const Explore = () => {
  let search = !window.location.search.split('?')[1]
    ? ''
    : window.location.search.split('?')[1].split('=')[1];

  const [select, setSelect] = useState({
    apy: 0,
    category: 0,
    sort: 0,
    businessType: 0,
  });

  const [additionalQuery, setAdditionalQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [page, setPage] = useState(0);
  const [totalData, setTotalData] = useState(0);

  // const { rows: data = [], count: totalPage } = marketplaceList;
  const pagination = Math.floor(totalData / 6);
  const { apy, category, sort, businessType } = select;

  const handlePagination = (action) => {
    if (page === 0 && action === 'prev') return;
    if (page === pagination && action === 'next') return;

    if (action === 'prev') {
      setPage(page - 1);
    } else {
      setPage(page + 1);
    }
  };
  const handleChangeSelect = (val, from) => {
    setSelect({ ...select, [from]: val });
  };

  const fetchMarketplaceList = useCallback(async () => {
    try {
      setLoading(true);
      const query = {
        page: search !== '' ? 0 : page,
        limit: 6,
        brand_name: search,
        order_from: 'createdAt',
        order_by: 'DESC',
        ...additionalQuery,
      };
      const params = new URLSearchParams(query);

      const isInvestor = localStorage.getItem('investor_token');
      if (isInvestor) {
        const { data = {} } = await InvestorAPI.getMarketPlaceList(params);
        setTotalData(data.pageInfo.totalData);
        setMarketplaceList(data.data);
      } else {
        const { data = {} } = await GuestAPI.getMarketPlaceList(params);
        setTotalData(data.pageInfo.totalData);
        setMarketplaceList(data.data);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [page, search, additionalQuery]);

  const handleSubmitFilter = () => {
    let query = {};
    if (apy) {
      switch (apy) {
        case 1:
          query.min_apy = 1;
          query.max_apy = 5;
          break;

        case 2:
          query.min_apy = 5;
          query.max_apy = 10;
          break;

        case 3:
          query.min_apy = 10;
          break;

        default:
          break;
      }
    }

    if (category) {
      query.category_id = category;
    }

    if (sort !== 3) {
      switch (sort) {
        case 1:
          query.order_from = 'brand_name';
          query.order_by = 'ASC';
          break;

        case 2:
          query.order_from = 'brand_name';
          query.order_by = 'DESC';
          break;

        case 0:
          query.order_from = 'createdAt';
          query.order_by = 'DESC';
          break;

        case 4:
          query.order_from = 'updatedAt';
          query.order_by = 'DESC';
          break;

        default:
          break;
      }
    }

    if (businessType) {
      if (businessType === 1) query.bussines_type = 'fund raising';
      if (businessType === 2) query.bussines_type = 'existing';
    }

    setAdditionalQuery(query);
    setPage(0);
  };

  useEffect(() => {
    fetchMarketplaceList();
  }, [page, search, fetchMarketplaceList, additionalQuery]);
  return (
    <>
      <Navbar />
      <Hero
        handleChangeSelect={handleChangeSelect}
        select={select}
        handleSubmitFilter={handleSubmitFilter}
      />
      <AllCardNFT
        datas={marketplaceList}
        loading={loading}
        page={page}
        pagination={pagination}
        handlePagination={handlePagination}
        refetch={fetchMarketplaceList}
      />
      <JoinOurBusinessOwner className={'pd-margin-bottom-lg'} />
      <Footer />
    </>
  );
};
