import { createRef, useEffect, useMemo, useState } from 'react';
import { Alert, Checkbox, Col, Divider, Form, message, Row, Spin } from 'antd';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import PdTitle from 'components/pd-title';

import debounce from 'lodash.debounce';

import './style.scss';

/**
 *
 * @param {Function} props.onFinish function that execute when button clicked or submitted
 * @param {Function} props.onClose function that close modal
 * @param {Function} props.loading loading props
 * @param {Function} props.useFor 'minting' | 'selling'
 * @param {Function} props.isPrefileed 'use for filled auto 1 if the business is an operating'
 * @param {Function} props.nftOwned 'total nft investor owned in that busniness '
 */

const halfLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  labelAlign: 'left',
};

// this component can receive mint and sell action.
//

export default function Minting({
  onFinish = () => {},
  onClose = () => {},
  loading = false,
  useFor = 'minting', // use for can ben minting/selling
  alertDesc = "Tykoon system moves amount needed from your account to the seller's account to pay for the NFT you're buying. This process is secure and handled by Tykoon system.",
  isPrefilled = false, // true only when business operating
  nftOwned = 0,
}) {
  const ref = createRef();
  const [form] = Form.useForm();
  const [isAgree, setIsAgree] = useState(false);
  const [numNft, setNumNft] = useState(null);

  function nearest_multiple_of(N, x) {
    return x + ((N - (x % N)) % N);
  }
  const onChange = (e) => {
    setIsAgree(e.target.checked);
  };

  const numberNFTOnChange = (val) => {
    setNumNft(val);
  };

  const debouncedResults = useMemo(() => {
    const handleChangeFundNeed = (val) => {
      if (useFor === 'selling') {
        //multiply number to sell and price per nft
        const totalPrice = parseInt(
          numNft === null ? nftOwned * val : numNft * val
        );
        form.setFieldsValue({
          total_price: totalPrice,
        });
      } else {
        //only kelipatan 1jt
        const total_fund = nearest_multiple_of(1000000, val);
        const number_of_nft = total_fund / 1000000;
        form.setFieldsValue({
          number_of_nft,
          amount: total_fund,
        });
      }
    };
    return debounce(handleChangeFundNeed, 1000);
  }, [form, numNft, useFor, nftOwned]);

  useEffect(() => {
    if (isPrefilled) {
      form.setFieldsValue({
        number_of_nft: 1,
        amount: 1000000,
      });
    } else if (useFor === 'selling') {
      form.setFieldsValue({
        nft_count: nftOwned,
      });
    }
  }, [form, isPrefilled, useFor, nftOwned]);

  function handleFinish(value) {
    if (!isAgree) {
      message.warning('Please check the agreement down below');
      return;
    }
    if (useFor === 'minting') {
      onFinish(value.number_of_nft);
    } else {
      if (value.nft_count > nftOwned) {
        message.error(
          `You are only have ${nftOwned} NFT, please check the number nft you want to sell`
        );
        return;
      } else {
        onFinish(value);
      }
    }
    form.resetFields();
  }

  return (
    <div className="modal-business">
      {loading && (
        <div className="loading-wrapper">
          <div className="loading">
            <Spin />
          </div>
          <div>Waiting for pending transaction during minting process...</div>
        </div>
      )}
      {!loading && (
        <>
          <div className="title-wrapper">
            <PdTitle
              align="center"
              color="black"
              size="extra-small"
              weight="normal"
              className="title">
              {useFor === 'selling' ? 'Sell NFT' : 'Buy NFT'}
            </PdTitle>
          </div>
          <Divider />
          <div className="content">
            <Alert message={alertDesc} type={'error'} showIcon />
            <Form form={form} className="form-wrapper" onFinish={handleFinish}>
              <Row gutter={16} justify="start">
                <Col lg={24}>
                  {useFor === 'selling' && (
                    <>
                      <Form.Item
                        {...halfLayout}
                        label="Number of NFT"
                        name="nft_count">
                        <PdAntdInput
                          className="h-button"
                          ref={ref}
                          variant="number"
                          onChange={numberNFTOnChange}
                        />
                      </Form.Item>
                      <Form.Item
                        {...halfLayout}
                        className=""
                        label="Price per NFT"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your price per NFT!',
                          },
                        ]}
                        name="listing_price">
                        <PdAntdInput
                          disabled={isPrefilled}
                          variant="number"
                          ref={ref}
                          onChange={debouncedResults}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          prefix={'IDR'}
                          placeholder="Write Price per NFT"
                          className="h-button"
                        />
                      </Form.Item>
                    </>
                  )}
                  {useFor !== 'selling' && (
                    <Form.Item
                      {...halfLayout}
                      className=""
                      label="Amount"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your amount!',
                        },
                      ]}
                      name="amount">
                      <PdAntdInput
                        disabled={isPrefilled}
                        variant="number"
                        ref={ref}
                        onChange={debouncedResults}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        prefix={'IDR'}
                        placeholder="Write Total Amount of NFT"
                        className="h-button"
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    {...halfLayout}
                    label={`Total NFT You ${
                      useFor === 'selling' ? 'Sell' : 'Get'
                    }`}
                    name={
                      useFor === 'selling' ? 'total_price' : 'number_of_nft'
                    }>
                    <PdAntdInput
                      className="h-button"
                      disabled={true}
                      ref={ref}
                      variant="number"
                    />
                  </Form.Item>
                  <Form.Item
                    {...halfLayout}
                    valuePropName="checked"
                    name="agreement"
                    rules={[
                      {
                        required: true,
                        message: 'Please, you have to check for this agreement',
                      },
                    ]}>
                    <Checkbox onChange={onChange} className="pd-align-left">
                      You agree to pay for tykoon fee about 2%
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row
                justify="space-between"
                className="bottom-wrapper"
                gutter={20}>
                <Col>
                  <PdButton
                    type="default"
                    className="bottom-button"
                    color="blue"
                    onClick={onClose}>
                    Cancel
                  </PdButton>
                </Col>
                <Col>
                  <PdButton
                    htmlType="submit"
                    color="purple"
                    className="bottom-button">
                    {useFor === 'selling' ? 'Sell' : 'Buy'}
                  </PdButton>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}
