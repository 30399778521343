import { Col, Form, Row } from 'antd';

import PdTitle from 'components/pd-title';
import { PdButton } from 'components/pd-button';
import { PdAntdInput } from 'components/pd-antd-input';
import infoIcon from 'assets/icon/info.svg';

import './style.scss';

/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

export default function RequestChangeData({
  onFinish = () => {},
  onChange = () => {},
}) {
  return (
    <div className="request-change-data-modal">
      <div className="title-wrapper">
        <PdTitle align="center" color="black" size="small" className="title">
          Request Change Data
        </PdTitle>
      </div>
      <div className="content">
        <Form
          id="request-change-data-form"
          layout="vertical"
          className="form-wrapper"
          onFinish={onFinish}
          requiredMark={false}>
          <Form.Item required>
            <Row justify="center">
              <Col span={24} className="alert-info pd-margin-bottom-md">
                <img src={infoIcon} alt="info" />
                <p>
                  Before submit request change data, please fill in which data
                  you want to change.
                </p>
              </Col>
              <Col span={24} className="pd-margin-bottom-md">
                <PdAntdInput rows={4} variant="text-area" onChange={onChange} />
              </Col>
              <Col span={24}>
                <PdButton
                  onClick={onFinish}
                  className=" w-full pd-align-center">
                  Submit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
