import { useState, useEffect } from 'react';
import { Col, DatePicker, Divider, Form, message, Row, Upload } from 'antd';

import { PdButton } from 'components/pd-button';
import { PdAntdInput } from 'components/pd-antd-input';
import CompanyAPI from 'api/CompanyAPI';
import UploadAPI from 'api/UploadAPI';

const DATE_FORMAT = 'MM/DD/YYYY';

const { Dragger } = Upload;

const PersonalInfomation = ({ setLoading, loading, userProfile }) => {
  const [form] = Form.useForm();

  const [selfieKtpInit, setSelfieKtpInit] = useState({});
  const [selfieKtpFile, setSelfieKtpFile] = useState('');

  useEffect(() => {
    const setInitialValue = () => {
      form.setFieldsValue({
        full_name: userProfile.full_name,
        birth_day: userProfile.birth_day,
        no_ktp: userProfile.no_ktp,
        selfie_ktp: userProfile.selfie_ktp,
      });

      setSelfieKtpInit({
        uid: '1',
        name: 'Previous Uploaded KTP',
        status: 'done',
        url: userProfile.selfie_ktp,
      });
    };
    setInitialValue();
  }, [form, userProfile, setSelfieKtpInit]);

  const selfieKtpUploadProps = {
    name: 'selfie_ktp',
    multiple: false,
    // defaultFileList: selfieKtpInit,
    beforeUpload: () => {
      // prevent upload automatically by ant design
      return false;
    },
    async onChange(info) {
      const extension = info.file.type.split('/')[1];

      // const objectUrl = URL.createObjectURL(info.file);
      // setSelfieKtp(objectUrl);
      setSelfieKtpFile((prevState) => ({
        ...prevState,
        file: info.file,
        extension,
        purpose: 'selfie_ktp',
      }));
    },
    showUploadList: true,
  };

  const onChangeDate = (date) => {
    form.setFieldValue({ birth_day: date });
  };

  const onClickSaveChanges = async (values) => {
    setLoading({ submit: true });
    const { full_name = '', birth_day = '', no_ktp = '' } = values;
    let selfie_image_url = '';

    try {
      if (selfieKtpFile.purpose) {
        const {
          data: { objectUrl = '', uploadUrl = '' },
        } = await UploadAPI.getUploadURL(
          selfieKtpFile.extension,
          selfieKtpFile.purpose
        );

        await UploadAPI.uploadImage(selfieKtpFile.file, uploadUrl);
        selfie_image_url = objectUrl;
      }
      const payload = {
        profile: {
          full_name,
        },
        forms: {
          birth_day,
          no_ktp,
          selfie_ktp: selfie_image_url,
        },
      };

      const { data } = await CompanyAPI.updateProfile(payload);
      message.success(data.message);
    } catch (_) {
      setLoading({ submit: false });
      message.error('Network error');
    } finally {
      setLoading({ submit: false });
    }
  };
  return (
    <Form
      name="personalForm"
      form={form}
      onFinish={onClickSaveChanges}
      autoComplete="off"
      initialValues={userProfile}
      labelAlign="left"
      colon={false}
      labelCol={{
        span: 8,
      }}
      layout="horizontal"
      size="large">
      <Form.Item
        className="pd-margin-top-sm"
        name="full_name"
        label="Fullname"
        wrapperCol={{ span: 10 }}>
        <PdAntdInput />
      </Form.Item>
      <Divider />
      <Form.Item name="birth_day" label="Birthday" wrapperCol={{ span: 5 }}>
        <DatePicker
          id="birth_day"
          placeholder={DATE_FORMAT}
          className="w-full pd-rounded-btn"
          format={DATE_FORMAT}
          onChange={onChangeDate}
        />
      </Form.Item>
      <Divider />
      <Form.Item name="no_ktp" label="No KTP" wrapperCol={{ span: 5 }}>
        <PdAntdInput />
      </Form.Item>
      <Divider />
      <Form.Item
        name="selfie_ktp"
        label="Selfie with KTP"
        valuePropName="file"
        wrapperCol={{ span: 10 }}
        className="selfie-form">
        {/* {selfieKtp && (
          <img src={selfieKtp} alt="selfie ktp" className="w-full" />
        )} */}
        <Dragger
          maxCount={1}
          // defa={selfieKtpInit}
          // file={selfieKtp}
          {...selfieKtpUploadProps}
          accept="image/*">
          <p className="ant-upload-hint">Drop files here to upload</p>
        </Dragger>
        {selfieKtpInit.url && !selfieKtpFile && (
          <a href={selfieKtpInit.url}>{selfieKtpInit.name}</a>
        )}
      </Form.Item>
      <Divider />
      <Form.Item>
        <Row justify="end" gutter={[20, 0]}>
          <Col>
            <PdButton
              type="ghost"
              className="cancel-button"
              style={{ border: 'none', height: '45px', width: '160px' }}>
              Cancel
            </PdButton>
          </Col>
          <Col>
            <PdButton
              disabled={loading.submit}
              loading={loading.submit}
              htmlType="submit"
              type="submit"
              variant="primary"
              className="save-button"
              style={{ height: '45px', width: '160px' }}>
              Save Changes
            </PdButton>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export { PersonalInfomation };
