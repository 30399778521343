import api from '../index';
import token from '../getAccessToken';

function verifUser(payload) {
  return api.post(
    `/company/form-verification`,
    payload,
    token.getAccessToken('company')
  );
}

function getUserProfile() {
  return api.get(`/company/profile`, token.getAccessToken('company'));
}
function updateProfile(payload) {
  return api.put('/company/profile', payload, token.getAccessToken('company'));
}

function updatePassword(payload) {
  return api.put(
    '/company/profile/change-password',
    payload,
    token.getAccessToken('company')
  );
}

function createExistingBusiness(payload) {
  return api.post(
    `/company/bussines/existing`,
    payload,
    token.getAccessToken('company')
  );
}

function createFundrisingBusiness(payload) {
  return api.post(
    `/company/bussines/fund-raising`,
    payload,
    token.getAccessToken('company')
  );
}

function getInitialForms(params) {
  return api.get(
    `/company/form-verification/${params}`,
    token.getAccessToken('company')
  );
}

function getAllBusiness(params = null) {
  return api.get(
    `/company/bussines?${params}`,
    token.getAccessToken('company')
  );
}

function getBusinessDetail(id) {
  return api.get(`/company/bussines/${id}`, token.getAccessToken('company'));
}

function getRevenueDisbursement() {
  return api.get(
    `/company/revenue-disbursement`,
    token.getAccessToken('company')
  );
}

function getDetailRevenueDisbursement(id) {
  return api.get(
    `/company/revenue-disbursement/${id}`,
    token.getAccessToken('company')
  );
}

function addRevenueDisbursement(id, payload) {
  return api.patch(
    `/company/revenue-disbursement/${id}`,
    payload,
    token.getAccessToken('company')
  );
}

function chooseBankPayment(payload) {
  return api.post(
    `/company/virtual-account/payment`,
    payload,
    token.getAccessToken('company')
  );
}

function getVirtualAccountDetail(id) {
  return api.get(
    `/company/virtual-account/${id}`,
    token.getAccessToken('company')
  );
}

function getAllNotification(params) {
  return api.get(
    `/company/notifications?${params}`,
    token.getAccessToken('company')
  );
}

function markAsReadNotif(payload) {
  return api.put(
    `/company/notifications`,
    payload,
    token.getAccessToken('company')
  );
}

function getAllRequestEdit() {
  return api.get(
    '/company/bussines/request-edit',
    token.getAccessToken('company')
  );
}

function postRequestEdit(id, payload) {
  return api.post(
    `/company/bussines/request-edit/${id}`,
    payload,
    token.getAccessToken('company')
  );
}

function getAllBusinessDocument() {
  return api.get(`/company/document/bussines`, token.getAccessToken('company'));
}

function getBusinessDocument(id) {
  return api.get(`/company/document/${id}`, token.getAccessToken('company'));
}

function getProductDocument(brandName = '') {
  return api.get(
    `/company/document/bussines?brand_name=${brandName}`,
    token.getAccessToken('company')
  );
}

function postDocumentBusiness(id, payload) {
  return api.post(
    `/company/document/bussines/${id}`,
    payload,
    token.getAccessToken('company')
  );
}

function deleteDocumentBusiness(id) {
  return api.delete(`/company/document/${id}`, token.getAccessToken('company'));
}

function editDocumentBusiness(id, payload) {
  return api.put(
    `/company/document/${id}`,
    payload,
    token.getAccessToken('company')
  );
}

function deleteFileDocumentBusiness(docId, id) {
  return api.delete(
    `/company/document/${docId}/list/${id}`,
    token.getAccessToken('company')
  );
}

function getCompanyHistory() {
  return api.get('/company/activity-history', token.getAccessToken('company'));
}

function postBlog(payload) {
  return api.post(`/company/blogs`, payload, token.getAccessToken('company'));
}

function getAllBlogs() {
  return api.get('/company/blogs', token.getAccessToken('company'));
}

function getDetailBlogs(id) {
  return api.get(`/company/blogs/${id}`, token.getAccessToken('company'));
}

function editBlogs(id, payload) {
  return api.put(
    `/company/blogs/${id}`,
    payload,
    token.getAccessToken('company')
  );
}

function deleteBlogs(id) {
  return api.delete(`/company/blogs/${id}`, token.getAccessToken('company'));
}

const companyObject = {
  addRevenueDisbursement,
  chooseBankPayment,
  createExistingBusiness,
  createFundrisingBusiness,
  deleteBlogs,
  deleteDocumentBusiness,
  deleteFileDocumentBusiness,
  editBlogs,
  editDocumentBusiness,
  getAllBlogs,
  getAllBusiness,
  getAllBusinessDocument,
  getAllNotification,
  getAllRequestEdit,
  getBusinessDetail,
  getBusinessDocument,
  getCompanyHistory,
  getDetailBlogs,
  getDetailRevenueDisbursement,
  getInitialForms,
  getProductDocument,
  getRevenueDisbursement,
  getUserProfile,
  getVirtualAccountDetail,
  markAsReadNotif,
  postBlog,
  postDocumentBusiness,
  postRequestEdit,
  updatePassword,
  updateProfile,
  verifUser,
};
export default companyObject;
