import { Button } from 'antd';

import './style.scss';

/**
 *
 * @param {Object} props
 * @param {('blue' | 'gray' | 'black')} props.color default "blue"
 * @param {('primary' | 'default' | 'link')} props.type default "primary"
 * @param {String} props.className
 */
export function PdButton({
  children,
  color = 'blue',
  type = 'primary',
  className = '',
  ...props
}) {
  return (
    <Button
      className={`pd-button pd-button-color-${color} ${className}`}
      type={type}
      {...props}>
      {children}
    </Button>
  );
}
const PdButtons = { PdButton }
export default PdButtons;
