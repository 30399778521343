import { useEffect, useState } from 'react';
import { Badge, Layout } from 'antd';

import { capitalizeFirstLetter, currencyFormatter } from 'utils/string-utility';
import { manipulateStatus, statusColor } from 'utils/status-label-color';

import { DashboardContainer } from 'components/dashboard-container';
import PdAntdHeader from 'components/pd-antd-header';
import PdTable from 'components/pd-table';
import PdTitle from 'components/pd-title';
import InvestorAPI from 'api/InvestorAPI';

const InvestorHistory = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  const onChangePageSize = (size) => {
    setPageSize(size);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'activity_date',
      key: 'activity_date',
    },
    {
      title: 'Business Name',
      dataIndex: 'Bussine',
      key: 'Bussine',
      render: (Bussine) => Bussine.brand_name,
    },
    {
      title: 'Activity Type (Revenue Disbursement)',
      dataIndex: 'type',
      key: 'type',
      render: (activity_type) => (
        <Badge
          style={{ backgroundColor: statusColor[activity_type] }}
          count={capitalizeFirstLetter(manipulateStatus(activity_type))}
        />
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => currencyFormatter(amount, 'Rp'),
    },
  ];

  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        setLoading(true);
        const { data } = await InvestorAPI.getInvestorHistory();
        setData(data.rows);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchHistoryData();
  }, []);
  return (
    <Layout className="company-document-page">
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          History
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer>
        <PdTable
          loading={loading}
          pageSize={pageSize}
          openFilter={false}
          title="All Investor History"
          columns={columns}
          data={data}
          onChangePageSize={onChangePageSize}
        />
      </DashboardContainer>
    </Layout>
  );
};

export default InvestorHistory;
