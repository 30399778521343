import api from '../index';
import axios from 'axios';

const uploadObject = {
  getUploadURL(file_extension, type) {
    return api.post('/get-file-url', { type, file_extension });
  },
  uploadImage(file, url) {
    return axios({
      data: file,
      method: 'PUT',
      url,
      // headers: {
      //   'x-amz-acl': 'public-read',
      // },
    });
  },
};
export default uploadObject;
