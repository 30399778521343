import { Col, Divider, Form, message, Row } from 'antd';

import { PdButton } from 'components/pd-button';
import { PdAntdInput } from 'components/pd-antd-input';
import CompanyAPI from 'api/CompanyAPI';
import { isAlpha, isSpecial } from 'utils/validate-password';

const PasswordInformation = ({ setLoading, loading }) => {
  const [form] = Form.useForm();

  async function handleValidatePassword(_, value) {
    if (value) {
      if (!isAlpha(value)) {
        return Promise.reject('Please input a minimum of 1 uppercase letter');
      } else if (!isSpecial(value)) {
        return Promise.reject('Please input a minimum of 1 special character');
      }
    }
  }
  const onClickSaveChanges = async (values) => {
    const {
      old_password = '',
      new_password = '',
      confirm_password = '',
    } = values;

    if (new_password !== confirm_password) {
      return message.error('Password does not match');
    }
    setLoading({ submit: true });

    try {
      // handleImages

      const payload = {
        old_password,
        new_password,
        confirm_password,
      };

      const { data } = await CompanyAPI.updatePassword(payload);
      message.success(data.message);
    } catch (_) {
      setLoading({ submit: false });
      message.error('Network error');
    } finally {
      setLoading({ submit: false });
    }
  };

  return (
    <Form
      name="passwordForm"
      form={form}
      onFinish={onClickSaveChanges}
      autoComplete="off"
      labelAlign="left"
      colon={false}
      labelCol={{
        span: 8,
      }}
      layout="horizontal"
      size="large">
      <Form.Item
        name="old_password"
        className="pd-margin-top-sm"
        label="Current Password"
        wrapperCol={{ span: 8 }}>
        <PdAntdInput variant="password" autoComplete="off" />
      </Form.Item>
      <Divider />
      <Form.Item
        name="new_password"
        label="New Password"
        wrapperCol={{ span: 8 }}
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
          {
            min: 8,
            message: 'Please input a minimum of 8 character',
          },
          {
            validator: handleValidatePassword,
          },
        ]}>
        <PdAntdInput variant="password" autoComplete="off" />
      </Form.Item>
      <Divider />
      <Form.Item
        name="confirm_password"
        label="Confirm New Password"
        wrapperCol={{ span: 8 }}
        className="pd-margin-bottom-md"
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              }
            },
          }),
        ]}>
        <PdAntdInput variant="password" autoComplete="off" />
      </Form.Item>
      <Row>
        <Col span={8}></Col>
        <Col className="password-req">
          <p className="title">Password Requirement</p>
          <ul>
            <li>Minimum 8 characters long - the more, the better</li>
            <li>At least one lowercase character</li>
            <li>At least one uppercase character</li>
            <li>At least one number, symbol, or whitespace character</li>
          </ul>
        </Col>
      </Row>
      <Divider />
      <Form.Item>
        <Row justify="end" gutter={[20, 0]}>
          <Col>
            <PdButton
              type="ghost"
              className="cancel-button"
              style={{ border: 'none', height: '45px', width: '160px' }}>
              Cancel
            </PdButton>
          </Col>
          <Col>
            <PdButton
              disabled={loading.submit}
              loading={loading.submit}
              htmlType="submit"
              type="submit"
              variant="primary"
              className="save-button"
              style={{ height: '45px', width: '160px' }}>
              Save Changes
            </PdButton>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export { PasswordInformation };
