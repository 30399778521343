import api from '../index';
import token from '../getAccessToken';

function getAllBusiness(params) {
  return api.get(
    `/investor/bussines?${params}`,
    token.getAccessToken('investor')
  );
}
function getMarketPlaceList(params) {
  return api.get(
    `/investor/bussines/explore?${params}`,
    token.getAccessToken('investor')
  );
}
function getBusinessDetail(id) {
  return api.get(`/investor/bussines/${id}`, token.getAccessToken('investor'));
}
function getAllFavorite(params) {
  return api.get(
    `/investor/favorite?${params}`,
    token.getAccessToken('investor')
  );
}
function postFavoriteBusiness(businessId) {
  return api.post(
    `/investor/favorite/${businessId}`,
    {},
    token.getAccessToken('investor')
  );
}
function getBalance() {
  return api.get('/investor/balance', token.getAccessToken('investor'));
}

function withdrawBalance(payload) {
  return api.patch(
    `/investor/withdraw`,
    payload,
    token.getAccessToken('investor')
  );
}

function addBusiness(payload) {
  return api.post(
    `/investor/bussines`,
    payload,
    token.getAccessToken('investor')
  );
}

function getInvestorHistory() {
  return api.get('/investor/history', token.getAccessToken('investor'));
}

function getNFTOwn(business_id) {
  return api.get(
    `/investor/smart-contract/${business_id}`,
    token.getAccessToken('investor')
  );
}

function makeOffer(bussines_id, payload) {
  return api.post(
    `/investor/bussines/${bussines_id}/offers`,
    payload,
    token.getAccessToken('investor')
  );
}
function makeListing(bussines_id, payload) {
  return api.post(
    `/investor/bussines/${bussines_id}/listing`,
    payload,
    token.getAccessToken('investor')
  );
}

function getOffersTable(business_id) {
  return api.get(
    `/investor/bussines/${business_id}/offers`,
    token.getAccessToken('investor')
  );
}
function getListingsTable(business_id) {
  return api.get(
    `/investor/bussines/${business_id}/listing`,
    token.getAccessToken('investor')
  );
}

function deleteOffers(bussines_id, offers_id) {
  return api.put(
    `/investor/bussines/${bussines_id}/offers/${offers_id}`,
    {},
    token.getAccessToken('investor')
  );
}
function deleteListing(bussines_id, listing_id) {
  return api.put(
    `/investor/bussines/${bussines_id}/listing/${listing_id}`,
    {},
    token.getAccessToken('investor')
  );
}

const InvestorAPI = {
  addBusiness,
  deleteListing,
  deleteOffers,
  getAllBusiness,
  getAllFavorite,
  getBalance,
  getBusinessDetail,
  getInvestorHistory,
  getListingsTable,
  getMarketPlaceList,
  getNFTOwn,
  getOffersTable,
  makeListing,
  makeOffer,
  postFavoriteBusiness,
  withdrawBalance,
};
export default InvestorAPI;
