import { Layout } from 'antd';

import './style.scss';

const { Header } = Layout;

export default function PdAntdHeader({ children, line = '', ...props }) {
  return (
    <Header className={`pd-antd-header ${line}`} {...props}>
      {children}
    </Header>
  );
}
