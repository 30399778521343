import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import validator from 'validator';
import { Form } from '@ant-design/compatible';
import { Layout, message } from 'antd';

import { AuthenticationContext } from 'contexts/authentication';

import AuthenticationApi from 'api/authentication';

import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import Forgot from 'components/pd-modal/forgot';
import Password from 'components/pd-modal/set-password';
import VerificationOTP from 'components/pd-modal/verified';

import './style.scss';

const { Content } = Layout;

const halfLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  labelAlign: 'left',
};

export function LoginPage({ form, history }) {
  const { auth } = useContext(AuthenticationContext);
  const loc = useLocation();
  const loginState = loc.state ? loc.state.status : undefined;

  const [loadingButton, seLoadingButton] = useState(false);
  const [modal, setModal] = useState({ type: '', visible: false });

  const { getFieldDecorator } = form;

  const location =
    history.location.pathname === '/register' ? 'register' : 'login';

  async function handleLogin(payload) {
    try {
      seLoadingButton(true);
      if (location === 'register') {
        await auth(payload, 'register', 'company');
      } else {
        await auth(payload, 'login', 'company');
        localStorage.setItem('role', 'company');
        history.push({
          pathname: '/company/dashboard',
        });
      }
    } catch (err) {
      if (err.response.data.message) {
        const errMessage = err.response.data.message;
        message.error(errMessage);
      } else {
        message.error('Tidak dapat menghubungi server, cek koneksi');
      }
    } finally {
      seLoadingButton(false);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        handleLogin(values);
      }
    });
  }

  const handleForgotPassword = () => {
    setModal({ type: 'forgot', visible: true });
  };

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleSubmitForgotPassword = async (type = '', value) => {
    if (type === 'forgot') {
      try {
        const {
          data: { otp },
        } = await AuthenticationApi.forgotPassword(value);

        const modalData = { email: value.email, otp };
        setModal({ type: 'otp', visible: true, data: modalData });
      } catch (err) {
        message.error(err.message);
        setModal({ type: '', visible: false });
      }
    } else if (type === 'otp') {
      try {
        const {
          data: { reset_token },
        } = await AuthenticationApi.otpVerif(value);

        const modalData = { email: value.email, reset_token };
        setModal({ type: 'setPassword', visible: true, data: modalData });
      } catch (err) {
        message.error(err.message);
        setModal({ type: '', visible: false });
      }
    } else if (type === 'newPassword') {
      try {
        const { data } = await AuthenticationApi.resetPassword(value);
        message.success(data.message);
      } catch (err) {
        message.error(err.message);
      } finally {
        setModal({ type: '', visible: false });
      }
    }
  };

  const modalContent = {
    forgot: (
      <Forgot
        onFinish={(value) => handleSubmitForgotPassword('forgot', value)}
      />
    ),
    otp: (
      <VerificationOTP
        onFinish={(value) => handleSubmitForgotPassword('otp', value)}
        data={modal.data}
      />
    ),
    setPassword: (
      <Password
        onFinish={(value) => handleSubmitForgotPassword('newPassword', value)}
        data={modal.data}
      />
    ),
  };

  const passwordValidator = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Password input your password!'));
    }
    const isStrongPassword = validator.isStrongPassword(value);
    if (!isStrongPassword) {
      return Promise.reject(
        new Error(
          'Password must have 8 characters and contain lower case, upper case, symbol, and number'
        )
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (loginState) message.success(loginState);
  }, [loginState]);

  return (
    <Content className="pd-login">
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <div className="login-wrapper">
        <div className="login-content">
          <div className="logo-container pd-margin-top-lg">
            {location === 'register' ? 'Register' : 'Login'}
          </div>
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Item label="Email" {...halfLayout}>
              {getFieldDecorator('email', {
                rules: [
                  { required: true, message: 'Please input your email!' },
                ],
              })(
                <PdAntdInput
                  variant="email"
                  placeholder="Email"
                  className="h-button"
                />
              )}
            </Form.Item>
            <Form.Item label="Password" {...halfLayout}>
              {getFieldDecorator('password', {
                rules: [
                  // { required: true, message: 'Please input your Password!' },
                  { validator: passwordValidator },
                ],
              })(
                <PdAntdInput
                  autoComplete="on"
                  variant="password"
                  placeholder="Password"
                  className="h-button"
                />
              )}
              {location === 'login' && (
                <p
                  className="pd-align-right forgot"
                  onClick={handleForgotPassword}>
                  Forgot your password?
                </p>
              )}
            </Form.Item>
            {/* register account field */}
            {location === 'register' && (
              <>
                <Form.Item label="Full Name" {...halfLayout}>
                  {getFieldDecorator('full_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Fullname!',
                      },
                    ],
                  })(
                    <PdAntdInput
                      variant="text"
                      placeholder="Fullname"
                      className="h-button"
                    />
                  )}
                </Form.Item>
                <Form.Item label="Company Name" {...halfLayout}>
                  {getFieldDecorator('company_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Company Name!',
                      },
                    ],
                  })(
                    <PdAntdInput
                      variant="text"
                      placeholder="Company Name"
                      className="h-button"
                    />
                  )}
                </Form.Item>
                <Form.Item label="Brand Name" {...halfLayout}>
                  {getFieldDecorator('brand_name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your Brand Name!',
                      },
                    ],
                  })(
                    <PdAntdInput
                      variant="text"
                      placeholder="Brand Name"
                      className="h-button"
                    />
                  )}
                </Form.Item>
              </>
            )}
            <Form.Item>
              <PdButton
                color="blue"
                htmlType="submit"
                style={{ width: '100%' }}
                loading={loadingButton}>
                {location === 'register' ? 'Register' : 'Login'}
              </PdButton>
              {location === 'register' ? (
                <p className="forgot">
                  Have account?
                  <Link to="/login"> Login Here</Link>
                </p>
              ) : (
                <p className="forgot">
                  Not registered yet?{' '}
                  <Link to="register">Create an Account</Link>
                </p>
              )}
            </Form.Item>
          </Form>
        </div>

        <div className="login-bg"></div>
      </div>
    </Content>
  );
}

export const LoginPageForm = Form.create({ name: 'login' })(LoginPage);

const LoginPages = { LoginPage };
export default LoginPages;
