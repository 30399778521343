import api from './index';

function loginCompany(payload) {
  return api.post(`/company/login`, payload);
}
function loginInvestor(payload) {
  return api.post(`/investor/login`, payload);
}
function registerCompany(payload) {
  return api.post(`/company/register`, payload);
}
function logout(access_token) {
  return api.delete(`/admins/logout`, { data: { access_token } });
}
function forgotPassword(payload) {
  return api.post('/company/forgot-password', payload);
}
function otpVerif(payload) {
  return api.post('/company/validate-otp', payload);
}
function resetPassword(payload) {
  return api.patch('/company/reset-password', payload);
}

const authObject = {
  forgotPassword,
  loginCompany,
  loginInvestor,
  logout,
  otpVerif,
  registerCompany,
  resetPassword,
};

export default authObject;
