import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Space } from 'antd';
import { PdButton } from 'components/pd-button';

import './style.scss';
export const PdDropdown = ({
  avatar = {},
  text = 'Action',
  color = 'black',
  type = '',
  menus = [],
  onClick = () => {},
  textClassName = '',
  isPadding = true,
}) => {
  const menu = <Menu onClick={(val) => onClick(val)} items={menus} />;
  return (
    <Dropdown overlay={menu} overlayClassName="pd-dropdown">
      <PdButton color={color} type={type} style={{ padding: !isPadding && 0 }}>
        <Space>
          {avatar.render && (
            <Avatar
              shape="circle"
              icon={
                avatar.img ? (
                  <img src={avatar.img} alt="company" />
                ) : (
                  <UserOutlined />
                )
              }
            />
          )}
          <p className={textClassName}>{text}</p>
          <DownOutlined />
        </Space>
      </PdButton>
    </Dropdown>
  );
};
