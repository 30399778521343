import { useEffect, useState } from 'react';
import { createRef, useCallback, useMemo } from 'react';
import { Col, Row } from 'antd';

import { identity } from 'lodash';
import GuestAPI from 'api/GuestAPI';

import { PdAntdSelect } from 'components/pd-antd-select';
import { PdButton } from 'components/pd-button';

const Hero = ({
  handleChangeSelect = identity,
  select = {},
  handleSubmitFilter = identity,
}) => {
  const ref = createRef();
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    const { data } = await GuestAPI.getCategories();
    setCategories([{ id: 0, name: 'All Categories' }, ...data]);
  };
  const expectedApyValue = useCallback(() => {
    return [
      { id: 0, name: 'Default' },
      { id: 1, name: '1-5%' },
      { id: 2, name: '5-10%' },
      { id: 3, name: '>10%' },
    ];
  }, []);

  const sortValue = useCallback(() => {
    return [
      { id: 3, name: 'Default' },
      { id: 1, name: 'Sort A-Z' },
      { id: 2, name: 'Sort Z-A' },
      { id: 0, name: 'Recently Added' },
      { id: 4, name: 'Recently Updated' },
    ];
  }, []);

  const typeValue = useCallback(() => {
    return [
      { id: 0, name: 'Default' },
      { id: 1, name: 'Fundraising' },
      { id: 2, name: 'Existing' },
    ];
  }, []);

  const typeList = useMemo(() => {
    const output = typeValue().map(({ name, id }) => ({
      text: name,
      value: id,
    }));

    return [...output];
  }, [typeValue]);

  const sortList = useMemo(() => {
    const output = sortValue().map(({ name, id }) => ({
      text: name,
      value: id,
    }));

    return [...output];
  }, [sortValue]);

  const apyList = useMemo(() => {
    const output = expectedApyValue().map(({ name, id }) => ({
      text: name,
      value: id,
    }));

    return [...output];
  }, [expectedApyValue]);

  const catList = useMemo(() => {
    const output = categories.map(({ name, id }) => ({
      text: name,
      value: id,
    }));

    return [...output];
  }, [categories]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Row align="middle" justify="center">
      <Col span={22}>
        <h3 className="pd-margin-top-xl pd-margin-bottom-xl pd-text-explore">
          Explore Business
        </h3>
        <Row align="middle" justify="center" className="pd-margin-bottom-md">
          <Col span={20}>
            <Row align="bottom" justify="space-between">
              <Col span={5}>
                <span>Expected APY</span>
                <PdAntdSelect
                  ref={ref}
                  dataSet={apyList}
                  optKey="value"
                  optValue="value"
                  optContent="text"
                  defaultValue={'Default'}
                  className="w-full h-button-primary pd-margin-top-sm"
                  onSelect={(val) => handleChangeSelect(val, 'apy')}
                  value={select.apy}
                />
              </Col>
              <Col span={5}>
                <span>Category</span>
                <PdAntdSelect
                  ref={ref}
                  dataSet={catList}
                  optKey="value"
                  optValue="value"
                  optContent="text"
                  defaultValue={'All Categories'}
                  className="w-full h-button-primary pd-margin-top-sm"
                  onSelect={(val) => handleChangeSelect(val, 'category')}
                  value={select.category}
                />
              </Col>
              <Col span={5}>
                <span>Sort By</span>
                <PdAntdSelect
                  ref={ref}
                  dataSet={sortList}
                  optKey="value"
                  optValue="value"
                  optContent="text"
                  defaultValue={'Default'}
                  className="w-full h-button-primary pd-margin-top-sm"
                  onSelect={(val) => handleChangeSelect(val, 'sort')}
                  value={select.sort}
                />
              </Col>
              <Col span={5}>
                <span>Type of Business</span>
                <PdAntdSelect
                  ref={ref}
                  dataSet={typeList}
                  optKey="value"
                  optValue="value"
                  optContent="text"
                  defaultValue={'Default'}
                  className="w-full h-button-primary pd-margin-top-sm"
                  onSelect={(val) => handleChangeSelect(val, 'businessType')}
                  value={select.businessType}
                />
              </Col>
              <Col span={3}>
                <PdButton
                  type={'primary'}
                  onClick={handleSubmitFilter}
                  className="pd-button-primary h-button-primary">
                  Apply Filter
                </PdButton>
              </Col>
            </Row>
          </Col>
        </Row>

        <hr />
      </Col>
    </Row>
  );
};

export default Hero;
