import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Layout, message } from 'antd';

import { DashboardContainer } from 'components/dashboard-container';
import { PdModals } from 'components/pd-modal';
import BusinessType from 'components/pd-modal/business-type';
import CompanyAPI from 'api/CompanyAPI';
import CardNFT from 'components/landing-components/CardNFT';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';

import RevenuePayment from 'components/pd-modal/revenue-payment';
import { PdTooltip } from 'components/pd-tooltip';

import infoIcon from 'assets/icon/info-blue.svg';
import BgCard from 'assets/images/bg-card.png';

export function RevenueDisbursement() {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [modal, setModal] = useState({ type: '', visible: false });

  const getRevenueDisbursement = async () => {
    const { data } = await CompanyAPI.getRevenueDisbursement();

    const newData = data?.map((d) => {
      const {
        Bussine: { brand_name },
        bussines_id,
        due_date,
        financial_report,
        id,
        status,
        total_income,
        total_payment,
        revenue_per_nft,
      } = d;

      return {
        brand_name,
        bussines_id,
        due_date,
        financial_report,
        id,
        status,
        total_income,
        total_payment,
        revenue_per_nft,
      };
    });
    setData(newData);
  };

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleOpenModalRevenue = async (data) => {
    const { id } = data;
    try {
      const { data: detailData } =
        await CompanyAPI.getDetailRevenueDisbursement(id);

      const {
        VirtualAccounts = [],
        Bussine: { revenue_sharing, number_of_nft, nft_sold, dividend_period },
      } = detailData;
      if (VirtualAccounts.length === 0) {
        setModal({
          type: 'revenue',
          visible: true,
          data: {
            ...data,
            revenue_sharing,
            number_of_nft,
            nft_sold,
            dividend_period,
          },
        });
      } else {
        history.push(`/company/revenue-payment/${id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitRevenue = (value) => {
    const { financial_report = '', total_income, id } = value;
    const payload = {
      financial_report,
      total_income,
    };

    try {
      CompanyAPI.addRevenueDisbursement(id, payload);

      message.success('Disbursement Updated!');
      history.push(`/company/revenue-payment/${id}`);
    } catch (error) {
      console.log(error);
    } finally {
      handleCloseModal();
    }
  };

  const modalContent = {
    businessType: <BusinessType />,
    revenue: (
      <RevenuePayment
        onFinish={(value) => handleSubmitRevenue(value)}
        onClose={handleCloseModal}
        data={modal.data}
      />
    ),
    // success: <Success />,
  };

  useEffect(() => {
    getRevenueDisbursement();
  }, []);
  return (
    <Layout className="pd-cms-home">
      <PdModals
        visible={modal.visible}
        headStyle={{ alignText: 'center' }}
        footer={null}
        wrapClassName="modal-business"
        handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Revenue Disbursement
          <PdTooltip
            placement="top"
            color="white"
            className="pd-margin-left-sm"
            title="In revenue disbursement menu, You can pay disbursement based on your profit this month">
            <img src={infoIcon} alt="info" className="info-icon" />
          </PdTooltip>
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer>
        <Row
          align="middle"
          justify="center"
          className="pd-margin-top-md pd-margin-bottom-xl">
          <Col span={24}>
            <Row justify="space-between" gutter={[16, 16]}>
              {data.map((data) => (
                <Col span={8} className="gutter-row" key={data.id}>
                  <CardNFT
                    className={'pd-margin-top-lg'}
                    from="revenue"
                    onClick={() =>
                      handleOpenModalRevenue({
                        id: data.id,
                        bussines_id: data.bussines_id,
                        name: data.brand_name,
                      })
                    }
                    isShowDetail={false}
                    cover={BgCard}
                    data={data}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </DashboardContainer>
    </Layout>
  );
}
const RevenueDisbursements = { RevenueDisbursement };
export default RevenueDisbursements;
