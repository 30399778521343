import { Tooltip } from 'antd';

import './style.scss';

const PdTooltip = (props) => {
  const { children } = props;
  return <Tooltip {...props}>{children}</Tooltip>;
};

export { PdTooltip };
