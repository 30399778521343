import { useState, useEffect } from 'react';
import { Col, Divider, Form, message, Row, Upload } from 'antd';

import { PdButton } from 'components/pd-button';
import { PdAntdInput } from 'components/pd-antd-input';
import CompanyAPI from 'api/CompanyAPI';

import discordIcon from 'assets/icon/discord.svg';
import twitterIcon from 'assets/icon/twitter.svg';
import instagramIcon from 'assets/icon/instagram.svg';
import UploadAPI from 'api/UploadAPI';
const CompanyInformation = ({ setLoading, loading, userProfile }) => {
  const [form] = Form.useForm();
  const [companyLogo, setCompanyLogo] = useState('');
  const [companyLogoFile, setCompanyLogoFile] = useState('');

  useEffect(() => {
    const setInitialValue = () => {
      form.setFieldsValue({
        profile_picture: userProfile.profile_picture,
        full_name: userProfile.full_name,
        birth_day: userProfile.birth_day,
        no_ktp: userProfile.no_ktp,
        company_logo: userProfile.company_logo,
        twitter_url: userProfile.twitter_url,
        discord_url: userProfile.discord_url,
        instagram_url: userProfile.instagram_url,
      });
      setCompanyLogo(userProfile.company_logo);
    };
    setInitialValue();
  }, [form, userProfile]);

  const companyLogoUploadProps = {
    name: 'file',
    multiple: false,
    beforeUpload: () => {
      // prevent upload automatically by ant design
      return false;
    },
    async onChange(info) {
      const extension = info.file.type.split('/')[1];

      const objectUrl = URL.createObjectURL(info.file);
      setCompanyLogo(objectUrl);
      setCompanyLogoFile((prevState) => ({
        ...prevState,
        file: info.file,
        extension,
        purpose: 'company_logo',
      }));
    },
    showUploadList: false,
  };

  const onClickSaveChanges = async (values) => {
    setLoading({ submit: true });
    const {
      company_name = '',
      company_website = '',
      npwp = '',
      company_social_media = '',
      twitter_url,
      discord_url,
      instagram_url,
    } = values;

    let company_logo = '';
    try {
      if (companyLogoFile.purpose) {
        const {
          data: { objectUrl = '', uploadUrl = '' },
        } = await UploadAPI.getUploadURL(
          companyLogoFile.extension,
          companyLogoFile.purpose
        );
        await UploadAPI.uploadImage(companyLogoFile.file, uploadUrl);
        company_logo = objectUrl;
      }
      const payload = {
        profile: {
          company_name,
        },
        forms: {
          company_website,
          npwp,
          company_social_media,
          company_logo: company_logo,
          twitter_url,
          discord_url,
          instagram_url,
        },
      };

      //   handle images
      const { data } = await CompanyAPI.updateProfile(payload);
      message.success(data.message);
    } catch (err) {
      console.log('err: ', err);
      setLoading({ submit: false });
      message.error('Network error');
    } finally {
      setLoading({ submit: false });
    }
  };
  return (
    <Form
      name="companyForm"
      form={form}
      onFinish={onClickSaveChanges}
      autoComplete="off"
      initialValues={userProfile}
      labelAlign="left"
      colon={false}
      labelCol={{
        span: 8,
      }}
      layout="horizontal"
      size="large">
      <Form.Item
        name="company_logo"
        valuePropName="file"
        className="pd-margin-top-sm"
        label="Company Name">
        <Row gutter={[20, 0]} className="profile-picture-wrapper">
          <Col>
            {companyLogo ? (
              <img src={companyLogo} alt="company" />
            ) : (
              <div className="img-placeholder" />
            )}
          </Col>
          <Col>
            <p className="your-profile-text">Your Company Logo</p>
            <p className="file-text">
              PNG or JPG no bigger than 1000px wide and tall.
            </p>
          </Col>
          <Col>
            <Upload
              className="upload-button"
              accept="image/*"
              {...companyLogoUploadProps}>
              <p>Upload</p>
            </Upload>
          </Col>
        </Row>
      </Form.Item>
      <Divider />
      <Form.Item
        name="company_name"
        label="Company Name"
        wrapperCol={{ span: 10 }}>
        <PdAntdInput />
      </Form.Item>
      <Divider />
      <Form.Item name="npwp" label="Company NPWP" wrapperCol={{ span: 10 }}>
        <PdAntdInput />
      </Form.Item>
      <Divider />
      <Form.Item
        name="company_website"
        label="Company Website"
        wrapperCol={{ span: 10 }}>
        <PdAntdInput />
      </Form.Item>
      <Divider />
      <Form.Item
        // name="company_social_media"
        label="Company Social Media"
        wrapperCol={{ span: 10 }}>
        <Row className="social-media-container">
          <Col className="social-media-icon-wrapper">
            <img
              className="social-media-icon"
              src={discordIcon}
              alt="discord"
            />
          </Col>
          <Col className="pd-margin-left-sm">
            <Form.Item noStyle name="discord_url">
              <PdAntdInput className="social-media-input" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="social-media-container pd-margin-top-sm">
          <Col className="social-media-icon-wrapper">
            <img
              className="social-media-icon"
              src={twitterIcon}
              alt="discord"
            />
          </Col>
          <Col className="pd-margin-left-sm">
            <Form.Item noStyle name="twitter_url">
              <PdAntdInput className="social-media-input" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="social-media-container pd-margin-top-sm">
          <Col className="social-media-icon-wrapper">
            <img
              className="social-media-icon"
              src={instagramIcon}
              alt="discord"
            />
          </Col>
          <Col className="pd-margin-left-sm">
            <Form.Item noStyle name="instagram_url">
              <PdAntdInput className="social-media-input" />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Divider />
      <Form.Item>
        <Row justify="end" gutter={[20, 0]}>
          <Col>
            <PdButton
              type="ghost"
              className="cancel-button"
              style={{ border: 'none', height: '45px', width: '160px' }}>
              Cancel
            </PdButton>
          </Col>
          <Col>
            <PdButton
              disabled={loading.submit}
              loading={loading.submit}
              htmlType="submit"
              type="submit"
              variant="primary"
              className="save-button"
              style={{ height: '45px', width: '160px' }}>
              Save Changes
            </PdButton>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export { CompanyInformation };
