import { Divider, Form, Upload, message, Row } from 'antd';

import { PdButton } from 'components/pd-button';
import { PdAntdInput } from 'components/pd-antd-input';
import { PdAntdSelect } from 'components/pd-antd-select';

import uploadIcon from 'assets/icon/upload-icon.svg';
import deleteIcon from 'assets/icon/cross.svg';
import downloadIcon from 'assets/icon/download-icon.svg';

import './style.scss';
import CompanyAPI from 'api/CompanyAPI';

const { Dragger } = Upload;

const UploadDocument = ({
  onFinish = () => {},
  onClose = () => {},
  onChangeForm = () => {},
  onChangeBusiness = () => {},
  setUploadForm,
  businessList = [],
  loading = false,
  uploadForm = {},
  status = '',
  setDocumentList = () => {},
  documentList = [],
}) => {
  const uploadProps = {
    name: 'file',
    multiple: true,
    beforeUpload: () => {
      // prevent upload automatically by ant design
      return false;
    },
    async onChange(info) {
      const { fileList = [] } = info;
      // console.log('fileList', fileList);
      // const copyOfDocument = [...documentList];
      // setDocumentList(copyOfDocument.concat(fileList));
      setUploadForm((prevState) => ({
        ...prevState,
        // urlList,
        file: fileList,
      }));
    },
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: 'Download',
      showRemoveIcon: true,
      removeIcon: <p className="pd-margin-bottom-none">X</p>,
    },
  };

  const onClickDeleteDocument = async (index) => {
    const copyOfDocument = [...documentList];

    try {
      const docId = copyOfDocument[index].document_id;
      const id = copyOfDocument[index].id;
      await CompanyAPI.deleteFileDocumentBusiness(docId, id);
      copyOfDocument.splice(index, 1);
      message.success(`File removed successfully.`);
    } catch (err) {
      message.error(`Error removing file.`);
    }

    setDocumentList(copyOfDocument);
  };

  // const modifiedDocumentList = useMemo(
  //   (item) => {
  //     return documentList.map((doc, index) => {
  //       const { bussines_document = '' } = doc;
  //       return { name: index + 1, url: bussines_document };
  //     });
  //   },
  //   [documentList]
  // );

  const RenderDocumentList = () => {
    return documentList.map((doc, index) => {
      const { bussines_document = '', file_name = '' } = doc;
      return (
        <Row
          key={index}
          className="document-list-container pd-margin-bottom-sm pd-margin-top-sm">
          <div className="document-list-wrapper" style={{ width: '240px' }}>
            <img
              src={uploadIcon}
              alt="docs"
              className="pd-margin-right-sm upload-icon"
            />
            <p
              className="pd-margin-bottom-none"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}>
              {file_name}
            </p>
          </div>
          {status === 'read' && (
            <a href={bussines_document} download>
              <img src={downloadIcon} alt="download document" />
            </a>
          )}

          {status === 'edit' && (
            <img
              onClick={() => onClickDeleteDocument(index)}
              src={deleteIcon}
              className="delete-icon"
              alt="delete document"
            />
          )}
        </Row>
      );
    });
  };

  const STATUS_DICT = {
    read: { label: 'Detail' },
    create: { label: 'Upload' },
    edit: { label: 'Edit' },
  };

  return (
    <div className="upload-document-modal">
      <div className="title-wrapper">
        <p>{STATUS_DICT[status].label} Document</p>
      </div>
      <Divider />
      <div className="wrapper">
        <div className="content">
          <Form
            initialValues={uploadForm}
            id="request-change-data-form"
            layout="vertical"
            className="form-wrapper"
            autoComplete="off"
            onFinish={() => onFinish(status)}
            requiredMark={false}>
            <Form.Item
              required
              label="Document Name"
              name="document_name"
              rules={[
                {
                  required: true,
                  message: 'Please input document name',
                },
              ]}>
              <PdAntdInput
                disabled={status === 'read'}
                size="large"
                variant="text"
                name="document_name"
                onChange={onChangeForm}
              />
            </Form.Item>
            <Form.Item
              required
              label="Select Business"
              name="business"
              rules={[
                {
                  required: true,
                  message: 'Please input select business',
                },
              ]}>
              <PdAntdSelect
                disabled={status === 'read'}
                size="large"
                optKey="value"
                optValue="value"
                optContent="text"
                dataSet={businessList}
                onChange={onChangeBusiness}
                style={{ textAlign: 'left' }}
              />
            </Form.Item>

            {/* Update: No Longer using date, generated by BE */}
            <Form.Item required label="Upload Document" valuePropName="file">
              {status === 'create' && (
                <Dragger {...uploadProps}>
                  <img src={uploadIcon} alt="upload" />
                  <p>Drop files here to upload</p>
                </Dragger>
              )}

              {status === 'edit' && (
                <>
                  <Dragger
                    {...uploadProps}
                    // fileList={modifiedDocumentList}
                  >
                    <img src={uploadIcon} alt="upload" />
                    <p>Drop files here to upload</p>
                  </Dragger>
                  <RenderDocumentList />
                </>
              )}

              {status === 'read' && <RenderDocumentList />}
            </Form.Item>
            {status !== 'read' && (
              <>
                <Divider />
                <Form.Item>
                  <PdButton
                    key="cancel"
                    type="ghost"
                    className="cancel-button"
                    onClick={onClose}>
                    Cancel
                  </PdButton>
                  <PdButton
                    disabled={loading}
                    loading={loading}
                    key="submit"
                    htmlType="submit"
                    className="upload-button">
                    {STATUS_DICT[status].label}
                  </PdButton>
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export { UploadDocument };
