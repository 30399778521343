import { useState, useEffect } from 'react';
import { Avatar, Badge, Popover, Row } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { isImage } from 'utils/params-nil';
import CompanyAPI from 'api/CompanyAPI';

import defaultIcon from 'assets/icon/notif-icon.svg';
import notification from '../../assets/icon/notification.svg';

import './style.scss';

export const PdNotificationPopover = () => {
  const [data, setData] = useState([]);

  const getAllNotification = async () => {
    const query = { status: 'unread' };
    const params = new URLSearchParams(query);
    try {
      const { data = [] } = await CompanyAPI.getAllNotification(params);
      const notifNew = data.slice(0, 5);
      setData(notifNew);
    } catch (error) {
      console.log(error);
    }
  };

  const notifContent = data.map((data, idx) => {
    const { notification, Bussine, updatedAt } = data;
    const { logo } = Bussine === null ? { logo: '' } : Bussine;
    return (
      <Row key={idx} align="middle" className="pd-margin-top-sm">
        <Avatar
          className="pd-margin-right-md"
          icon={<img src={isImage(logo) ? logo : defaultIcon} alt="logo" />}
        />
        <div>
          <div style={{ maxWidth: '250px' }} className="text-notif">{notification}</div>
          <div style={{ maxWidth: '250px' }} className="text-time">{moment(updatedAt).fromNow()}</div>
        </div>
      </Row>
    );
  });

  useEffect(() => {
    getAllNotification();
  }, []);
  return (
    <Popover
      placement="bottomRight"
      content={
        <div className="notif-content">
          <div className='content-list'>{notifContent}</div>
          {data.length > 0 && (
            <div className="pd-align-center pd-margin-top-md view-all">
              <Link to={'/company/notification'}>View All</Link>
            </div>
          )}
        </div>
      }
      title={
        <Row justify="space-between">
          <div>Notification</div>
          <div className="clear" onClick={() => setData([])}>
            Clear All
          </div>
        </Row>
      }
      trigger="click">
      <Badge dot={data.length > 0}>
        <img src={notification} alt="notif icon" className="notif-img" />
      </Badge>
    </Popover>
  );
};
