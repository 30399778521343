import './style.scss';
import { Col, Layout, message, Pagination, Row, Spin } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import useMetamask from 'hooks/useMetamask';

import InvestorAPI from 'api/InvestorAPI';

import { currencyFormatter } from 'utils/string-utility';

import { DashboardContainer } from 'components/dashboard-container';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import { Success } from 'components/pd-modal/success';
import CardNFT from 'components/landing-components/CardNFT';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import Withdraw from 'components/pd-modal/withdrawal';

import BgCard from 'assets/images/bg-card.png';
import balanceIcon from 'assets/icon/balance-icon.svg';

// import './style.scss';

export function InvestorHome() {
  const { sendTransaction } = useMetamask();
  const [modal, setModal] = useState({ type: '', visible: false });
  const [totalBalance, setTotalBalance] = useState(0);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentWalletAddress, setWalletAddress] = useState(null);
  const [withdrawLoading, setWithdrawLoading] = useState(false);

  const getAllBusiness = useCallback(async () => {
    setLoading(true);
    try {
      const query = { page, limit: 6 };
      const params = new URLSearchParams(query);

      const { data: allBusiness = [] } = await InvestorAPI.getAllBusiness(
        params
      );
      const { data: walletProperties } = await InvestorAPI.getBalance();
      const { balance, wallet_address } = walletProperties;
      setTotalBalance(parseInt(balance));
      setWalletAddress(wallet_address);

      // allBusiness.pop();
      // if (allBusiness.data.length >= 1) {
      //   newData = allBusiness.data.map((data) => {
      //     const {
      //       id,
      //       nft_own,
      //       status,
      //       revenue,
      //       Bussine: {
      //         brand_name = '',
      //         logo = '',
      //         dividend_period = '',
      //         ThisPeriod = '',
      //         LastPeriod = '',
      //         id: bussines_id = '',
      //         Company: { company_logo },
      //       },
      //     } = data;

      //     return {
      //       // favorited: favoritedIdList.includes(bussines_id),
      //       id,
      //       nft_own,
      //       status,
      //       revenue,
      //       brand_name,
      //       logo,
      //       dividend_period,
      //       last_period: LastPeriod,
      //       this_period: ThisPeriod,
      //       bussines_id,
      //       Company: { company_logo },
      //     };
      //   });
      // }
      setCount(allBusiness.data.length);

      setData(allBusiness.data);
    } catch (error) {
      console.log(error);
      message.error('Cannot connect to server! Check your connection');
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    getAllBusiness();
  }, [getAllBusiness]);

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleWithdraw = async (value) => {
    const { total_withdraw } = value;
    try {
      setWithdrawLoading(true);
      const { hash, gasFee } = await sendTransaction(
        total_withdraw.toString(),
        currentWalletAddress
      );
      console.log(hash, gasFee);
      await InvestorAPI.withdrawBalance({
        amount: total_withdraw,
      });
      setModal({ type: 'success', visible: true });
    } catch (error) {
      if (error.reason === 'transaction failed') {
        message.error('Blockchain network busy! try again later');
      } else {
        console.dir(error);
        message.error(error.message);
        get(error);
      }
      handleCloseModal();
    } finally {
      setWithdrawLoading(false);
    }
  };

  const onClickFavorite = async (event, id, isFavorited) => {
    event.stopPropagation();
    event.preventDefault();

    try {
      await InvestorAPI.postFavoriteBusiness(id);
      getAllBusiness();

      if (isFavorited) {
        message.success('Successfully unloved');
      } else {
        message.success('Successfully loved');
      }
    } catch (_) {
      message.error('Network Error');
    }
  };

  const modalContent = {
    withdraw: (
      <Withdraw
        onFinish={handleWithdraw}
        onClose={handleCloseModal}
        data={totalBalance}
        loading={withdrawLoading}
        gasFee={+(0.000045 * 19915429.35).toFixed(3)}
      />
    ),
    success: (
      <Success
        title="Successfully Withdraw"
        desc="Check your metamask wallet"
      />
    ),
  };

  return (
    <Layout className="pd-cms-home">
      <PdModals
        wrapClassName="investor-withdraw"
        headStyle={{ alignText: 'center' }}
        visible={modal.visible}
        handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          My Business ({data.length})
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer>
        <div>
          <Row className="pd-margin-bottom-md">
            <Col
              span={8}
              className="pd-padding-md balance-wrapper pd-margin-right-md">
              <Row align="middle">
                <div className="pd-margin-right-md total-balance-wrapper">
                  <div className="total-balance">Total Balance</div>
                  <PdTitle size="extra-small">
                    {currencyFormatter(totalBalance, 'IDRT')}
                  </PdTitle>
                </div>
                <img src={balanceIcon} alt="balance icon" />
              </Row>
            </Col>
            <Col>
              <PdButton
                color="blue"
                className="h-button withdraw"
                onClick={() => setModal({ type: 'withdraw', visible: true })}>
                Withdraw
              </PdButton>
            </Col>
          </Row>

          {loading && (
            <Row align="middle" justify="center" className="pd-margin-top-xl">
              <Spin />
            </Row>
          )}
          <Row
            align="middle"
            justify="center"
            className="pd-margin-top-md pd-margin-bottom-xl">
            <Col span={24}>
              <Row justify="start" gutter={[16, 16]}>
                {data.map((cardData) => (
                  <Col span={8} className="gutter-row" key={cardData.id}>
                    <Link to={`/investor/product-detail/${cardData.id}`}>
                      <CardNFT
                        className={'pd-margin-top-lg'}
                        from="investor"
                        role="investor"
                        cover={BgCard}
                        data={cardData}
                        type={false}
                        onClickFavorite={onClickFavorite}
                      />
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row justify="end" className="pd-margin-top-lg">
            {count && (
              <Pagination
                defaultCurrent={1}
                total={count}
                showTotal={(total) =>
                  `Showing ${total} Items out of ${total} results found`
                }
                onChange={(page) => setPage(page - 1)}
              />
            )}
          </Row>
        </div>
      </DashboardContainer>
    </Layout>
  );
}

const InvestorHomes = { InvestorHome };
export default InvestorHomes;
