import { Col, Row } from 'antd';
import { PdButton } from 'components/pd-button';
import { Link } from 'react-router-dom';

const JoinOurBusinessOwner = ({ className }) => {
  return (
    <Row
      className={className}
      align="middle"
      justify="center"
      style={{
        backgroundColor: '#F8F8F8',
      }}>
      <Col span={22}>
        <Row
          align="middle"
          justify="space-between"
          className="pd-padding-top-lg pd-padding-bottom-lg">
          <Col xs={22} md={10}>
            <h3 style={{ maxWidth: 240 }} className="font-bold pd-text-primary">
              Lorem Ipsum Dolor sit Amet
            </h3>
            <p className="font-300" style={{ maxWidth: 500, color: '#8e8e8e' }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
              venenatis odio nec risus pellentesque vehicula.
            </p>
          </Col>
          <Col xs={22} md={6}>
            <Row align="middle" justify="start">
              <Col>
                <Link to="/register">
                  <PdButton
                    type={'primary'}
                    className="pd-button-primary h-button-primary">
                    Join as Business Owner
                  </PdButton>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default JoinOurBusinessOwner;
