import { Table } from 'antd';

import { PdAntdSelect } from 'components/pd-antd-select';

import prevArrow from 'assets/icon/prev-arrow.svg';
import nextArrow from 'assets/icon/next-arrow.svg';
import selectIcon from 'assets/icon/select-icon.svg';
import './style.scss';

const PdTable = ({
  openFilter = true,
  columns,
  data,
  title = '',
  onChangePageSize = () => {},
  pageSize = 5,
  loading = false,
  isHeader = true,
}) => {
  const paginationItemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div className="arrow-wrapper">
          <img src={prevArrow} alt="previous" />
          <p>Prev</p>
        </div>
      );
    }
    if (type === 'next') {
      return (
        <div className="arrow-wrapper">
          <p>Next</p>
          <img src={nextArrow} alt="next" />
        </div>
      );
    }

    return originalElement;
  };

  const paginationSet = [
    {
      optKey: 1,
      value: '5',
      text: '5 Per Page',
    },
    {
      optKey: 2,
      value: '10',
      text: '10 Per Page',
    },
    {
      optKey: 3,
      value: '15',
      text: '15 Per Page',
    },
    {
      optKey: 4,
      value: '20',
      text: '20 Per Page',
    },
  ];

  return (
    <div className="pd-table-container">
      {isHeader && (
        <div className="pd-table-header">
          <p>{title}</p>
          <div className="filter">
            <PdAntdSelect
              suffixIcon={<img src={selectIcon} alt="select" />}
              onChange={onChangePageSize}
              dataSet={paginationSet}
              optKey="value"
              optValue="value"
              optContent="text"
              defaultValue="5 Per Page"
              bordered={false}
              style={{ width: '120px', textAlign: 'right', border: 'none' }}
            />
            {openFilter && (
              <div className="filter-wrapper" onClick={openFilter}>
                <p className="filter-text">Filter</p>
                <div className="divider" />
                <p>{data.length}</p>
              </div>
            )}
          </div>
        </div>
      )}
      <Table
        loading={loading}
        pagination={{
          position: ['bottomCenter'],
          pageSize: pageSize,
          className: 'pd-table-pagination',
          itemRender: paginationItemRender,
          responsive: true,
        }}
        className="pd-table-content"
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default PdTable;
