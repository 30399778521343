import { useHistory } from 'react-router-dom';

import existing from 'assets/icon/existing icon.svg';
import fundraising from 'assets/icon/fundraising.svg';

import './style.scss';

export default function BusinessType() {
  const history = useHistory();

  return (
    <div className="pd-business-type-modal">
      <p className="title">Your Type of Business?</p>
      <div className="wrapper">
        <div
          className="card"
          onClick={() => history.push('/company/existing-business')}>
          <img src={existing} alt="Login as Company" />
          <p className="title">Running Business</p>
          <p className="desc">Lorem Ipsum dolor sit amet dolor sit amet</p>
        </div>
        <div
          className="card"
          onClick={() => history.push('/company/fundraising-business')}>
          <img src={fundraising} alt="Register as Company" />
          <p className="title">Fundraising Business</p>
          <p className="desc">Lorem Ipsum dolor sit amet dolor sit amet</p>
        </div>
      </div>
    </div>
    // <div className="pd-guest-modal-business">
    //   <div className="header">
    //     <PdTitle align="center" color="black" size="small">
    //       Your Type of Business?
    //     </PdTitle>
    //   </div>
    //   <div className="content">
    //     <Form
    //       id="forgot-form"
    //       layout="vertical"
    //       className="form-wrapper"
    //       onFinish={onFinish}
    //       requiredMark={false}>
    //       <Form.Item>
    //         <Row justify="center" gutter={20}>
    //           <Col span={12}>
    //             <Link to="/company/existing-business" className='type-wrapper'>
    //               <img src={existing} alt="existing" width={100} />
    //               <h4>Existing Business</h4>
    //               <h4>If your business already established</h4>
    //             </Link>
    //           </Col>
    //           <Col span={12}>
    //             <Link to="/company/fundraising-business"  className='type-wrapper'>
    //               <img src={fundraising} alt="existing" width={80} />
    //               <h4>Fundraising Business</h4>
    //               <h4>If your business isn't established</h4>
    //             </Link>
    //           </Col>
    //         </Row>
    //       </Form.Item>
    //     </Form>
    //   </div>
    // </div>
  );
}
