import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form, Layout, Row, message, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';

import moment from 'moment';
import { useAuthContext } from 'contexts/authentication';

import { DashboardContainer } from 'components/dashboard-container';
import { PdAntdDatePicker } from 'components/pd-antd-date-picker';
import { PdAntdSelect } from 'components/pd-antd-select';
import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import AskVerif from 'components/pd-modal/verif-ask';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import PdUploader from 'components/pd-uploader';
import { PdTooltip } from 'components/pd-tooltip';

import infoIcon from 'assets/icon/info-blue.svg';

import CompanyAPI from 'api/CompanyAPI';
import GuestAPI from 'api/GuestAPI';

import debounce from 'lodash.debounce';

const halfLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
  labelAlign: 'left',
};

export function CreateBusiness() {
  const ref = createRef();
  const [form] = useForm();
  const { authenticatedUserProfile } = useAuthContext();
  const { companyName } = authenticatedUserProfile;

  const [brandLogo, setBrandLogo] = useState(null);
  // const [contCompany, setContCompany] = useState(0);
  // const [contTycoon, setContTycoon] = useState(0);
  const [additionalLogo, setAdditionLogos] = useState([]);

  const history = useHistory();
  const businessType =
    history.location.pathname.indexOf('existing') > 0
      ? 'Operating'
      : 'Fundraising';

  // const [apy, setApy] = useState(0);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');
  const [firstOp, setFirstOp] = useState(null);
  const [dividend, setDividend] = useState('');
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState({ type: '', visible: false });
  const [totalFund, setTotalFund] = useState([]);

  async function handleSubmit(values) {
    setLoading(true);
    try {
      const {
        brand_desc,
        brand_location,
        brand_name,
        category_id,
        company_name,
        cont_company,
        cont_tykoon,
        dividend_period,
        expected_apy,
        expected_roi,
        due_date,
        monthly_revenue,
        number_of_nft,
        price_of_nft,
        total_fund,
        launch_date,
        public_sales_percentage,
      } = values;

      // if (cont_company + cont_tykoon !== 100) {
      //   message.error('Sum of company and tykoon contribution must be 100!');
      //   return;
      // }

      if (brandLogo === null || !brandLogo) {
        message.error('Please insert main photo');
      }
      const moreLogos = additionalLogo.map((data) => data.objectUrl);

      const payload = {
        additional_logos: moreLogos,
        brand_description: brand_desc,
        branch_location: brand_location,
        brand_name,
        category_id,
        company_name,
        dividend_period,
        expected_apy,
        expected_roi,
        ...(businessType === 'Fundraising' && {
          due_date: moment(due_date).format('YYYY-MM-DD'),
          contribution_company: cont_company,
          contribution_tycoon: cont_tykoon,
        }),
        logo: brandLogo,
        monthly_revenue,
        number_of_nft,
        price_of_nft,
        fund_goal: total_fund,
        launch_date: moment(launch_date).format('YYYY-MM-DD'),
        first_period: moment(date).startOf('month').format('YYYY-MM-DD'),
        public_sales_percentage,
        revenue_sharing: 0,
      };

      businessType === 'Operating'
        ? await CompanyAPI.createExistingBusiness(payload)
        : await CompanyAPI.createFundrisingBusiness(payload);
      message.success('Business successfully created, waiting for approval!');
      history.goBack();
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message === 'Invalid Date') {
        message.error('Make sure your due date is before the first period!');
      }
      message.error(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const handleChangeOperation = useCallback(
    (val) => {
      setDate(val);
      const firstOperation = moment(val).add(dividend, 'month').format('MMMM');
      setFirstOp(firstOperation);
    },
    [dividend]
  );

  function nearest_multiple_of(N, x) {
    return x + ((N - (x % N)) % N);
  }

  const debouncedResults = useMemo(() => {
    const handleChangeFundNeed = (val) => {
      const total_fund = nearest_multiple_of(1000000, val);
      const number_of_nft = total_fund / 1000000;

      form.setFieldsValue({
        number_of_nft,
        total_fund,
        price_of_nft: 1000000,
      });
    };
    return debounce(handleChangeFundNeed, 1000);
  }, [form]);

  const handleChangeAPY = (val) => {
    // setApy(val);
    const expected_roi = Math.ceil(1 / (val / 100));
    form.setFieldsValue({
      expected_roi,
    });
  };

  const dividendPeriodValue = useCallback(() => {
    return [
      { id: 1, name: '1 Month' },
      { id: 2, name: '2 Month' },
      { id: 3, name: '3 Month' },
      { id: 6, name: '6 Month' },
      { id: 12, name: '12 Month' },
    ];
  }, []);

  const dividendList = useMemo(() => {
    const output = dividendPeriodValue().map(({ name, id }) => ({
      text: name,
      value: id,
    }));

    return [...output];
  }, [dividendPeriodValue]);

  const catList = useMemo(() => {
    const output = categories.map(({ name, id }) => ({
      text: name,
      value: id,
    }));

    return [...output];
  }, [categories]);

  const handleChangeCat = (val) => {
    setCategory(val);
  };

  const handleChangeDividend = (val) => {
    setDividend(val);
  };

  const getCategories = async () => {
    const { data } = await GuestAPI.getCategories();
    setCategories(data);
  };

  const handleChangeCapital = (val, purpose) => {
    setTotalFund((totalFund) => ({ ...totalFund, [purpose]: val }));
  };

  const modalContent = {
    verification: <AskVerif />,
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    handleChangeOperation(date);
  }, [dividend, date, handleChangeOperation]);

  useEffect(() => {
    form.setFieldsValue({
      company_name: companyName,
      // cont_company: contCompany,
      // cont_tykoon: 100 - contCompany,
      total_fund: totalFund.fund + totalFund.capital,
    });
    debouncedResults(totalFund.fund + totalFund.capital);
  }, [companyName, form, totalFund, debouncedResults]);

  return (
    <Layout>
      <PdModals visible={modal.visible} handleClose={handleCloseModal}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader line="line">
        <PdTitle size="small" className="pd-margin-top-sm">
          {businessType} Business
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer className="pd-cms-content">
        <Form form={form} onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">
                  Business Detail Information
                </PdTitle>
                <p>This section will be provide</p>
              </div>
              <Form.Item
                {...halfLayout}
                className=""
                label="Company Name"
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company name!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Company Name"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Business Name"
                name="brand_name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your business name!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Business Name"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Business Desc"
                name="brand_desc"
                rules={[
                  {
                    required: true,
                    message: 'Please input your business desc!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Business Description"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Business Categories"
                name="category_id"
                rules={[
                  {
                    required: true,
                    message: 'Please input your business categories!',
                  },
                ]}>
                <PdAntdSelect
                  ref={ref}
                  dataSet={catList}
                  optKey="value"
                  optValue="value"
                  optContent="text"
                  defaultValue={undefined}
                  placeholder="Select Business Categories"
                  className="w-full h-button"
                  onSelect={handleChangeCat}
                  value={category}
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="New Business Location"
                name="brand_location"
                rules={[
                  {
                    required: true,
                    message: 'Please input your brand location!',
                  },
                ]}>
                <PdAntdInput
                  disabled={loading}
                  placeholder="Write Brand Location"
                  ref={ref}
                  className="h-button"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Your Business Photo (Main Photo)"
                name="logo"
                rules={[
                  {
                    required: false,
                    message: 'Please input your brand logo!',
                  },
                ]}>
                <PdUploader
                  accept="image/*"
                  type="image"
                  afterUpload={setBrandLogo}
                  url={brandLogo}
                  purpose="logo"
                />
              </Form.Item>
              <Form.Item
                {...halfLayout}
                className=""
                label="Your Business Photo
                (Additional Photo)"
                name="additional_logos"
                rules={[
                  {
                    required: false,
                    message: 'Please input your brand logo!',
                  },
                ]}>
                <PdUploader
                  accept="image/*"
                  type="image"
                  afterUpload={setAdditionLogos}
                  url={additionalLogo[additionalLogo.length - 1]}
                  purpose="logo"
                  multiple={true}
                />
              </Form.Item>

              {businessType === 'Operating' ? (
                <Form.Item {...halfLayout} label="Avg Monthly Revenue (IDR)">
                  <Space>
                    <Form.Item
                      noStyle
                      name="monthly_revenue"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your avg monthly revenue!',
                        },
                      ]}>
                      <PdAntdInput
                        disabled={loading}
                        variant="number"
                        ref={ref}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        prefix="IDR"
                        placeholder="Write Avg Revenue"
                        className="h-button"
                        style={{ width: '200px' }}
                      />
                    </Form.Item>
                    <PdTooltip
                      placement="right"
                      color="white"
                      className="pd-margin-left-sm"
                      title="Your avg monthly revenue">
                      <img src={infoIcon} alt="info" className="info-icon" />
                    </PdTooltip>
                  </Space>
                </Form.Item>
              ) : (
                <Form.Item {...halfLayout} label="Expect Monthly Revenue (IDR)">
                  <Space>
                    <Form.Item
                      noStyle
                      name="monthly_revenue"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your expect monthly revenue!',
                        },
                      ]}>
                      <PdAntdInput
                        disabled={loading}
                        variant="number"
                        ref={ref}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        prefix="IDR"
                        placeholder="Write Expect Revenue"
                        className="h-button"
                        style={{ width: '200px' }}
                      />
                    </Form.Item>
                    <PdTooltip
                      placement="right"
                      color="white"
                      className="pd-margin-left-sm"
                      title="Your avg monthly revenue">
                      <img src={infoIcon} alt="info" className="info-icon" />
                    </PdTooltip>
                  </Space>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">Your Timeline</PdTitle>
                <p>This section will be provide</p>
              </div>
              <Form.Item {...halfLayout} label="Expected Launch Date">
                <Space>
                  <Form.Item
                    noStyle
                    name="launch_date"
                    initialValue={moment()}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your launch date!',
                      },
                    ]}>
                    <PdAntdDatePicker
                      ref={ref}
                      placeholder="Company launch date"
                      className="w-quarter h-button"
                      style={{ width: '200px' }}
                    />
                  </Form.Item>
                  <PdTooltip
                    placement="right"
                    color="white"
                    className="pd-margin-left-sm"
                    title="Your nft release date to the public">
                    <img src={infoIcon} alt="info" className="info-icon" />
                  </PdTooltip>
                </Space>
              </Form.Item>
              {businessType !== 'Operating' && (
                <Form.Item {...halfLayout} label="Fundraising End Date">
                  <Space>
                    <Form.Item
                      noStyle
                      name="due_date"
                      initialValue={moment()}
                      rules={[
                        {
                          required: true,
                          message: 'Please input your end date!',
                        },
                      ]}>
                      <PdAntdDatePicker
                        ref={ref}
                        placeholder="Company end date"
                        className="w-quarter h-button"
                        style={{ width: '200px' }}
                      />
                    </Form.Item>
                    <PdTooltip
                      placement="right"
                      color="white"
                      className="pd-margin-left-sm"
                      title="Your fundraising end date">
                      <img src={infoIcon} alt="info" className="info-icon" />
                    </PdTooltip>
                  </Space>
                </Form.Item>
              )}

              {businessType !== 'Operating' && (
                <>
                  <Form.Item
                    {...halfLayout}
                    label="Your Capital Contribution (IDR)">
                    <Space>
                      <Form.Item
                        noStyle
                        name="cont_company"
                        rules={[
                          {
                            required: true,
                            message:
                              'Please input your Your Capital Contribution!',
                          },
                        ]}>
                        <PdAntdInput
                          disabled={loading}
                          variant="number"
                          prefix={'IDR'}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          ref={ref}
                          placeholder="Write Your Capital Contribution"
                          className="h-button"
                          style={{ width: '200px' }}
                          onChange={(val) =>
                            handleChangeCapital(val, 'capital')
                          }
                        />
                      </Form.Item>
                      <PdTooltip
                        placement="right"
                        color="white"
                        className="pd-margin-left-sm"
                        title="The amount of your company contribution to this funding">
                        <img src={infoIcon} alt="info" className="info-icon" />
                      </PdTooltip>
                    </Space>
                  </Form.Item>
                  <Form.Item {...halfLayout} label="Fundraising Amount (IDR)">
                    <Space>
                      <Form.Item
                        noStyle
                        name="cont_tykoon"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Fundraising Amount!',
                          },
                        ]}>
                        <PdAntdInput
                          // disabled={true}
                          variant="number"
                          prefix={'IDR'}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          ref={ref}
                          placeholder="Write Fundraising Amount"
                          className="h-button"
                          style={{ width: '200px' }}
                          onChange={(val) => handleChangeCapital(val, 'fund')}
                        />
                      </Form.Item>
                      <PdTooltip
                        placement="right"
                        color="white"
                        className="pd-margin-left-sm"
                        title="Tycoon's contribution amount for this funding">
                        <img src={infoIcon} alt="info" className="info-icon" />
                      </PdTooltip>
                    </Space>
                  </Form.Item>
                </>
              )}

              {businessType === 'Operating' && (
                <>
                  <Form.Item
                    {...halfLayout}
                    label="Percentage of Public Sales (%)">
                    <Space>
                      <Form.Item
                        noStyle
                        name="public_sales_percentage"
                        rules={[
                          {
                            required: true,
                            message:
                              'Please input your public sales percentage',
                          },
                        ]}>
                        <PdAntdInput
                          disabled={loading}
                          variant="number"
                          ref={ref}
                          // onChange={handleChangeAPY}
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace('%', '')}
                          placeholder="Write Public Sales Percentage"
                          className="h-button"
                          style={{ width: '200px' }}
                        />
                      </Form.Item>
                      <PdTooltip
                        placement="right"
                        color="white"
                        className="pd-margin-left-sm"
                        title="Expected APY of your business in %">
                        <img src={infoIcon} alt="info" className="info-icon" />
                      </PdTooltip>
                    </Space>
                  </Form.Item>
                  <Form.Item
                    {...halfLayout}
                    label="Valuation of Your Business (IDR)">
                    <Space>
                      <Form.Item
                        noStyle
                        name="total_fund"
                        rules={[
                          {
                            required: true,
                            message:
                              'Please input your Valuation of Your Business!',
                          },
                        ]}>
                        <PdAntdInput
                          disabled={loading}
                          variant="number"
                          ref={ref}
                          onChange={debouncedResults}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          prefix={'IDR'}
                          placeholder="Write Valuation of Your Business"
                          className="h-button"
                          style={{ width: '200px' }}
                        />
                      </Form.Item>
                      <PdTooltip
                        placement="right"
                        color="white"
                        className="pd-margin-left-sm"
                        title="Amount of funds you want to collect from tykoon">
                        <img src={infoIcon} alt="info" className="info-icon" />
                      </PdTooltip>
                    </Space>
                  </Form.Item>
                </>
              )}
              {businessType !== 'Operating' && (
                <>
                  <Form.Item {...halfLayout} label="Total Fund Needed (IDR)">
                    <Space>
                      <Form.Item
                        noStyle
                        name="total_fund"
                        rules={[
                          {
                            required: false,
                            message: 'Please input your Total Fund Needed!',
                          },
                        ]}>
                        <PdAntdInput
                          // disabled={true}
                          variant="number"
                          ref={ref}
                          onChange={debouncedResults}
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          prefix={'IDR'}
                          placeholder="Write Total Fund Needed"
                          className="h-button"
                          style={{ width: '200px' }}
                          value={totalFund.fund + totalFund.capital}
                        />
                      </Form.Item>
                      <PdTooltip
                        placement="right"
                        color="white"
                        className="pd-margin-left-sm"
                        title="Amount of funds you want to collect from tykoon">
                        <img src={infoIcon} alt="info" className="info-icon" />
                      </PdTooltip>
                    </Space>
                  </Form.Item>
                  <Row className="pd-margin-bottom-md">
                    <Col span={8}></Col>
                    <Col span={10}>
                      <div className="pd-margin-top-md w-full">
                        Total Fund Needed = Your Capital Cont + Fundraising
                        Amount
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              <Form.Item {...halfLayout} label="Dividend Period">
                <Space>
                  <Form.Item
                    noStyle
                    name="dividend_period"
                    rules={[
                      {
                        required: true,
                        message: 'Please select your Dividend Period!',
                      },
                    ]}>
                    <PdAntdSelect
                      ref={ref}
                      dataSet={dividendList}
                      optKey="value"
                      optValue="value"
                      optContent="text"
                      defaultValue={undefined}
                      placeholder="Select Dividend Period"
                      className="w-full h-button"
                      onSelect={handleChangeDividend}
                      value={dividend}
                      style={{ width: '200px' }}
                    />
                  </Form.Item>
                  <PdTooltip
                    placement="right"
                    color="white"
                    className="pd-margin-left-sm"
                    title="The dividend period you give to investors">
                    <img src={infoIcon} alt="info" className="info-icon" />
                  </PdTooltip>
                </Space>
              </Form.Item>
              <Form.Item {...halfLayout} label="First Month of Operation">
                <Space>
                  <Form.Item
                    noStyle
                    name="first_period"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your First Month of Operation!',
                      },
                    ]}>
                    <PdAntdDatePicker
                      ref={ref}
                      placeholder="Company First Month of Operation"
                      className="w-quarter h-button"
                      format={'YYYY-MM'}
                      onChange={handleChangeOperation}
                      picker="month"
                      style={{ width: '200px' }}
                    />
                  </Form.Item>
                  <PdTooltip
                    placement="right"
                    color="white"
                    className="pd-margin-left-sm"
                    title="You will receive invoice first week of the next period.">
                    <img src={infoIcon} alt="info" className="info-icon" />
                  </PdTooltip>
                </Space>
              </Form.Item>
              <Row>
                <Col span={8}></Col>
                <Col span={10}>
                  <div className="pd-margin-top-md w-full">
                    Your first Invoice will be on 1st{' '}
                    {firstOp === 'Invalid date' || firstOp === null
                      ? 'Month'
                      : firstOp}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={24}>
              <div className="pd-margin-bottom-lg">
                <PdTitle size="extra-small">NFT Disbursement</PdTitle>
                <p>This section will be provide</p>
              </div>

              <Form.Item {...halfLayout} label="Number of NFT">
                <Space>
                  <Form.Item
                    noStyle
                    name="number_of_nft"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your number of NFT!',
                      },
                    ]}>
                    <PdAntdInput
                      disabled={true}
                      variant="number"
                      ref={ref}
                      placeholder="Write number of NFT"
                      className="h-button"
                      style={{ width: '200px' }}
                    />
                  </Form.Item>
                  <PdTooltip
                    placement="right"
                    color="white"
                    className="pd-margin-left-sm"
                    title="Total NFT generated from the total funds you expect from tykoon">
                    <img src={infoIcon} alt="info" className="info-icon" />
                  </PdTooltip>
                </Space>
              </Form.Item>
              <Form.Item {...halfLayout} label="Price of NFT (IDR)">
                <Space>
                  <Form.Item
                    noStyle
                    name="price_of_nft"
                    rules={[
                      {
                        required: false,
                        message: 'Please input your price of NFT!',
                      },
                    ]}>
                    <PdAntdInput
                      disabled={true}
                      defaultValue={1000000}
                      variant="number"
                      prefix={'IDR'}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                      ref={ref}
                      placeholder="Write price of NFT"
                      className="h-button"
                      style={{ width: '200px' }}
                    />
                  </Form.Item>
                  <PdTooltip
                    placement="right"
                    color="white"
                    className="pd-margin-left-sm"
                    title="Unit price for 1 NFT published on Tykoon">
                    <img src={infoIcon} alt="info" className="info-icon" />
                  </PdTooltip>
                </Space>
              </Form.Item>
              <Form.Item {...halfLayout} label="Expected APY (%)">
                <Space>
                  <Form.Item
                    noStyle
                    name="expected_apy"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Expected APY!',
                      },
                    ]}>
                    <PdAntdInput
                      disabled={loading}
                      variant="number"
                      ref={ref}
                      onChange={handleChangeAPY}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace('%', '')}
                      placeholder="Write Expected APY"
                      className="h-button"
                      style={{ width: '200px' }}
                    />
                  </Form.Item>
                  <PdTooltip
                    placement="right"
                    color="white"
                    className="pd-margin-left-sm"
                    title="Expected APY of your business in %">
                    <img src={infoIcon} alt="info" className="info-icon" />
                  </PdTooltip>
                </Space>
              </Form.Item>
              <Form.Item {...halfLayout} label="Expected ROI (Years)">
                <Space>
                  <Form.Item
                    noStyle
                    name="expected_roi"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Expected ROI!',
                      },
                    ]}>
                    <PdAntdInput
                      disabled={true}
                      variant="number"
                      ref={ref}
                      formatter={(value) => `${value} Years`}
                      placeholder="Write Expected ROI"
                      className="h-button"
                      style={{ width: '200px' }}
                    />
                  </Form.Item>
                  <PdTooltip
                    placement="right"
                    color="white"
                    className="pd-margin-left-sm"
                    title="Your Expected ROI">
                    <img src={infoIcon} alt="info" className="info-icon" />
                  </PdTooltip>
                </Space>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end" gutter={20} className="pd-margin-bottom-xl">
            <Col span={3} style={{ justifyContent: 'end', display: 'flex' }}>
              <PdButton
                type="default"
                className="w-full h-button"
                color="blue"
                onClick={() => history.goBack()}>
                Cancel
              </PdButton>
            </Col>
            <Col span={3} style={{ justifyContent: 'end', display: 'flex' }}>
              <PdButton
                htmlType="submit"
                color="purple"
                loading={loading}
                className="w-full h-button">
                Submit
              </PdButton>
            </Col>
          </Row>
        </Form>
      </DashboardContainer>
    </Layout>
  );
}

const CreateBusinesses = { CreateBusiness };
export default CreateBusinesses;
