import { useMemo } from 'react';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Badge, Card, Col, message, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import TProgress from '../Progress';
import { PdButton } from 'components/pd-button';

import {
  capitalize,
  capitalizeFirstLetter,
  currencyFormatter,
} from 'utils/string-utility';
import { manipulateStatus, statusColor } from 'utils/status-label-color';
import { isImage } from 'utils/params-nil';

import InvestorAPI from 'api/InvestorAPI';

import companyLogo from 'assets/images/companylogo.png';
import seaIcon from 'assets/icon/nft-sell.png';
import opensea from 'assets/icon/opensea-icon.svg';

import './style.scss';

function daysRemaining(event) {
  var todaysdate = moment();
  return todaysdate.to(moment(event));
}

const CardNFT = ({
  data,
  cover,
  from = 'investor',
  button = true, // button sell / buy
  type = true, // ribbon type show or not
  onClick = () => ({}), // when its clicked event
  refetch = () => {}, // re fetching
  isShowDetail = true, // if the card is clicked it will redirect to detail page
}) => {
  const history = useHistory();

  const {
    // Company: { company_logo },
    // last_period,
    // nft_own,
    // price_of_nft,
    // this_period,
    brand_name,
    bussines_id = '',
    bussines_type = 'fund raising',
    category_id,
    dividend_period,
    due_date,
    expected_apy,
    favorite = false,
    fund_goal,
    fundraising_status,
    id,
    LastPeriod,
    logo,
    nft_sold,
    number_of_nft,
    published_percentage,
    status,
    ThisPeriod,
    total_fund,
    total_investor,
    total_payment,
  } = data;

  const handleToCardDetail = (id, brandName, businessType, categoryId) => {
    history.push(`/explore/${id}`, {
      role: 'guest',
      brandName,
      businessType,
      categoryId,
    });
  };

  const onClickFavorite = async (event, id, isFavorited) => {
    event.stopPropagation();
    event.preventDefault();
    if (role === 'investor') {
      try {
        await InvestorAPI.postFavoriteBusiness(id);
        refetch();
        if (isFavorited) {
          message.success('Successfully unloved');
        } else {
          message.success('Successfully loved');
        }
      } catch (_) {
        message.error('Network Error');
      }
    }
  };

  const role = useMemo(() => {
    const investorToken = localStorage.getItem('investor_token');
    const companyToken = localStorage.getItem('company_token');

    if (investorToken) return 'investor';
    if (companyToken) return 'company';

    return 'guest';
  }, []);

  const RenderLoveFavorite = () => {
    return (
      <div
        style={{ position: 'absolute', top: 10, right: 20, cursor: 'pointer' }}>
        {role === 'company' ? null : favorite ? (
          <HeartFilled
            style={{
              color: '#FF4444',
              fontSize: '20px',
            }}
            onClick={
              (event) => onClickFavorite(event, bussines_id || id, favorite)
              // onClickFavorite(event, bussines_id || id, favorited)
            }
          />
        ) : (
          <HeartOutlined
            style={{
              color: '#fff',
              fontSize: '20px',
            }}
            onClick={(event) =>
              onClickFavorite(event, bussines_id || id, favorite)
            }
          />
        )}
      </div>
    );
  };

  return (
    <Card
      onClick={() =>
        isShowDetail &&
        handleToCardDetail(id, brand_name, bussines_type, category_id)
      }
      style={{ borderRadius: '12px', position: 'relative' }}
      cover={
        <div
          style={{
            borderRadius: '12px 12px 0 0',
            position: 'relative',
          }}>
          <div className="wrapper-company-logo">
            {['company', 'investor'].includes(from) && (
              <img
                src={
                  isImage(data.Company.company_logo)
                    ? data.Company.company_logo
                    : companyLogo
                }
                alt="logo"
              />
            )}
          </div>
          {isImage(logo) ? (
            <img
              src={logo}
              alt="bg-card"
              style={{
                height: '160px',
                width: '100%',
                borderRadius: '12px 12px 0 0',
                objectFit: 'cover',
              }}
            />
          ) : (
            <img
              src={cover}
              alt="bg-card"
              style={{
                height: '160px',
                width: '100%',
                borderRadius: '12px 12px 0 0',
                objectFit: 'cover',
              }}
            />
          )}
          {/* ribbon for business type */}
          {type && (
            <div className="ribbon ribbon-top-left">
              <span>
                {capitalizeFirstLetter(
                  bussines_type === 'existing' ? 'operating' : bussines_type
                )}
              </span>
            </div>
          )}
        </div>
      }>
      <RenderLoveFavorite />
      <Row align="top" justify="center">
        <Col span={24}>
          <Row align="middle" justify="space-between" className="brand-wrapper">
            <p className="pd-text-secondary">{brand_name}</p>
            {from === 'company' && role !== 'investor' && (
              <Badge
                style={{ backgroundColor: statusColor[fundraising_status] }}
                count={capitalize(manipulateStatus(fundraising_status))}
              />
            )}
          </Row>
          {bussines_type !== 'existing' &&
            ['revenue', 'investor'].indexOf(from) < 0 && (
              <>
                <Row align="middle" justify="space-between">
                  <Col span={12} className="percentage-text">
                    {`${published_percentage}% - `}
                    {currencyFormatter(fund_goal, 'IDRT')}
                  </Col>
                  <Col
                    span={12}
                    style={{ textAlign: 'end' }}
                    className="percentage-text">
                    {due_date === null ? '' : daysRemaining(due_date)}
                  </Col>
                </Row>
                <TProgress percent={published_percentage} />
              </>
            )}
          {bussines_type === 'existing' &&
            ['revenue', 'investor'].indexOf(from) < 0 && (
              <>
                <Row align="middle" justify="space-between">
                  <Col span={12} className="percentage-text">
                    NFT Sold: {nft_sold === null ? '-' : nft_sold}
                  </Col>
                  <Col
                    span={12}
                    style={{ textAlign: 'end' }}
                    className="percentage-text">
                    Available NFT:{' '}
                    {number_of_nft === null
                      ? nft_sold
                      : parseInt(number_of_nft) + parseInt(nft_sold)}
                  </Col>
                </Row>
                <TProgress
                  percent={
                    (parseInt(nft_sold) * 100) / parseInt(number_of_nft) +
                    parseInt(nft_sold)
                  }
                />
              </>
            )}
          {['revenue', 'investor'].indexOf(from) < 0 && (
            <Row align="middle" justify="space-between" gutter={24}>
              <Col span={8} className="gutter-row">
                <p className="font-400 pd-text-body-card">Funding</p>
                <p className="font-500 pd-text-body-card-2">
                  IDRT <br />
                  {currencyFormatter(total_fund, '')}
                </p>
              </Col>
              <Col span={6} className="gutter-row">
                <p className="font-400 pd-text-body-card">
                  {from === 'company' ? 'NFT Sold' : 'Investor'}
                </p>
                <p className="font-500 pd-text-body-card-2">
                  {from === 'company'
                    ? nft_sold
                    : total_investor === null
                    ? '-'
                    : total_investor}
                </p>
              </Col>
              <Col span={8} className="gutter-row">
                <p className="font-400 pd-text-body-card">Expected APY</p>
                <p className="font-500 pd-text-body-card-2">{expected_apy}%</p>
              </Col>
            </Row>
          )}
          {from === 'revenue' && (
            <Row align="middle" justify="space-between" gutter={24}>
              <Col span={12} className="gutter-row">
                <p className="font-400 pd-text-body-card">Due Date</p>
                <p className="font-500 pd-text-body-card-2">
                  {due_date === null
                    ? '-'
                    : moment(due_date).format('YYYY-MM-DD')}
                </p>
              </Col>
              <Col span={12} className="gutter-row">
                <p className="font-400 pd-text-body-card">This Disbursement</p>
                <Row className="pd-margin-bottom-md ">
                  <p className="font-500 pd-text-body-card-2 pd-margin-right-sm">
                    {total_payment === null
                      ? '-'
                      : currencyFormatter(total_payment, 'IDRT')}
                  </p>
                  <Badge
                    style={{
                      backgroundColor: statusColor[status],
                      fontSize: '10px',
                    }}
                    count={capitalize(manipulateStatus(status))}
                  />
                </Row>
              </Col>
            </Row>
          )}
          {from === 'investor' && (
            <Row align="bottom" justify="space-between" gutter={24}>
              <Col span={12} className="gutter-row">
                <p className="font-400 pd-text-body-card">
                  Current Period: <br />{' '}
                  {ThisPeriod === null
                    ? '-'
                    : `${moment(ThisPeriod.createdAt)
                        .subtract(1, 'months')
                        .startOf('month')
                        .format('YYYY/MM/DD')} - ${moment(ThisPeriod.createdAt)
                        .endOf('month')
                        .format('YYYY/MM/DD')}`}
                </p>
                <p className="font-500 pd-text-body-card-2">
                  IDRT{' '}
                  {ThisPeriod === null
                    ? '-'
                    : currencyFormatter(
                        parseInt(ThisPeriod.revenue_per_nft) * dividend_period,
                        ''
                      )}
                </p>
                <p className="font-400 pd-text-body-card">
                  Last Period: <br />{' '}
                  {LastPeriod === null
                    ? '-'
                    : `${moment(LastPeriod.createdAt)
                        .subtract(1, 'months')
                        .startOf('month')
                        .format('YYYY/MM/DD')} - ${moment(LastPeriod.createdAt)
                        .endOf('month')
                        .format('YYYY/MM/DD')}`}
                </p>
                <p className="font-500 pd-text-body-card-2">
                  {' '}
                  IDRT{' '}
                  {LastPeriod === null
                    ? '-'
                    : currencyFormatter(
                        parseInt(LastPeriod.revenue_per_nft) * dividend_period,
                        ''
                      )}{' '}
                </p>
              </Col>
              <Col span={12} className="gutter-row">
                <p className="font-400 pd-text-body-card">Due In</p>
                <p className="font-500 pd-text-body-card-2">
                  {!due_date ? '-' : daysRemaining(due_date)}
                </p>
                <p className="font-400 pd-text-body-card">Status</p>
                <p className="font-500 pd-text-body-card-2">
                  {from === 'investor'
                    ? ThisPeriod === null
                      ? 'Waiting for payment'
                      : capitalize(ThisPeriod.status)
                    : !due_date
                    ? '-'
                    : daysRemaining(due_date)}
                </p>
              </Col>
              {/* <Col span={6} className="gutter-row">
                <p className="font-400 pd-text-body-card">NFT Owned</p>
                <p className="font-500 pd-text-body-card-2">{nft_own}</p>
                <p
                  className="font-400 pd-text-body-card"
                  style={{ visibility: 'hidden' }}>
                  Cal. APM
                </p>
                <p
                  className="font-500 pd-text-body-card-2"
                  style={{ visibility: 'hidden' }}>
                  {!due_date ? '-' : daysRemaining(due_date)}
                </p>
              </Col> */}
            </Row>
          )}
          {button ? (
            from === 'investor' ? (
              <PdButton
                type={'default'}
                color="blue"
                onClick={onClick}
                className="pd-button-primary h-button-primary w-full"
                icon={
                  <img
                    src={seaIcon}
                    alt="sea-icon"
                    style={{ marginRight: 10 }}
                  />
                }>
                Sell NFT
              </PdButton>
            ) : from === 'company' && role !== 'investor' ? (
              <PdButton
                type={'primary'}
                onClick={onClick}
                className="pd-button-primary h-button-primary w-full"
                icon={
                  <img
                    src={seaIcon}
                    alt="sea-icon"
                    style={{ marginRight: 10 }}
                  />
                }>
                Sell NFT
              </PdButton>
            ) : from === 'revenue' ? (
              <PdButton
                type={'default'}
                onClick={onClick}
                className="pd-button-primary h-button-primary w-full">
                Pay Disbursement
              </PdButton>
            ) : (
              <PdButton
                type={'primary'}
                onClick={onClick}
                icon={
                  bussines_type === 'existing' && (
                    <img
                      src={opensea}
                      alt="sea-icon"
                      style={{ marginRight: 10 }}
                    />
                  )
                }
                className="pd-button-primary h-button-primary w-full">
                Buy Now
              </PdButton>
            )
          ) : (
            ''
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default CardNFT;
