import 'react-quill/dist/quill.snow.css';
import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Layout, message, Row } from 'antd';

import { DashboardContainer } from 'components/dashboard-container';
import { PdAntdInput } from 'components/pd-antd-input';
import { PdModals } from 'components/pd-modal';

import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';
import ReactQuill from 'react-quill';
import { PdButton } from 'components/pd-button';
import CompanyAPI from 'api/CompanyAPI';
import { PdAntdSelect } from 'components/pd-antd-select';
import { createRef } from 'react';
import { Success } from 'components/pd-modal/success';
import { useParams } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { PdAntdDatePicker } from 'components/pd-antd-date-picker';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';

const halfLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 20 },
  labelAlign: 'left',
};

const AddBlog = () => {
  const ref = createRef();
  const [form] = useForm();
  const { id } = useParams();
  const loc = window.location.pathname.split('/')[3]; // edit-post dan add-post

  const [modal, setModal] = useState({
    type: '',
    visible: false,
    data: {},
    status: '',
  });
  const [value, setValue] = useState('');
  const [allBusiness, setAllBusiness] = useState([]);
  const [business, setBusiness] = useState(null); // for id business

  const handleSubmitBlog = async (val) => {
    try {
      if (loc === 'add-post') {
        await CompanyAPI.postBlog(val);
        setModal({ type: 'success', visible: true });
      } else {
        await CompanyAPI.editBlogs(id, val);
        setModal({ type: 'editSuccess', visible: true });
      }
      setTimeout(() => window.location.reload(), 1500);
    } catch (error) {
      message.error('Error while submitting blog...');
    }
  };

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const catList = useMemo(() => {
    const output = allBusiness.map(({ brand_name, id }) => ({
      text: brand_name,
      value: id,
    }));

    return [...output];
  }, [allBusiness]);

  const handleChangeBusiness = (val) => {
    setBusiness(val);
  };

  const getAllBusiness = async () => {
    try {
      const { data } = await CompanyAPI.getAllBusiness();
      setAllBusiness(data);
    } catch (error) {
      message.error("Can't get business data of this company");
    }
  };

  const modalContent = {
    success: (
      <Success
        title="Successfully Posted"
        desc="Your article has been posted in your business"
      />
    ),
    editSuccess: (
      <Success title="Successfully Edited" desc="Your article has updated" />
    ),
  };

  useEffect(() => {
    const getDetailBlogs = async () => {
      try {
        const { data } = await CompanyAPI.getDetailBlogs(id);
        form.setFieldsValue({
          date: moment(data.updatedAt),
          title: data.title,
          detail_article: data.detail_article,
          bussines_id: data.bussines_id,
        });
      } catch (error) {
        message.error("Can't get business data of this company");
      }
    };
    getAllBusiness();
    if (loc !== 'add-post') {
      getDetailBlogs();
    } else {
      form.setFieldsValue({
        date: moment(),
      });
    }
  }, [form, id, loc]);

  return (
    <Layout className="company-document-page">
      <PdModals
        visible={modal.visible}
        wrapClassName="upload-document-modal"
        headStyle={{ alignText: 'center' }}
        handleClose={handleCloseModal}
        footer={null}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          {loc === 'edit-post' ? 'Edit Post' : 'Add Post'}
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer>
        <Form onFinish={handleSubmitBlog} form={form}>
          <Form.Item {...halfLayout} name="date" label="Date Created">
            <PdAntdDatePicker
              disabled
              className="w-quarter h-button"
              style={{ width: '200px' }}
            />
          </Form.Item>
          <Form.Item
            {...halfLayout}
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Please input your title!',
              },
            ]}>
            <PdAntdInput
              variant="text"
              placeholder="Add your title"
              className="h-button"
            />
          </Form.Item>
          <Form.Item
            {...halfLayout}
            name="detail_article"
            label="Description"
            rules={[
              {
                required: true,
                message: 'Please input your article description!',
              },
            ]}>
            <ReactQuill theme="snow" value={value} onChange={setValue} />
          </Form.Item>
          <Form.Item {...halfLayout} name="bussines_id" label="Business">
            <PdAntdSelect
              ref={ref}
              dataSet={catList}
              optKey="value"
              optValue="value"
              optContent="text"
              defaultValue={undefined}
              placeholder="Select Business"
              className="h-button"
              onSelect={handleChangeBusiness}
              value={business}
            />
          </Form.Item>

          <Form.Item>
            <Row gutter={24} justify="end">
              <Col>
                <PdButton type="default" color="blue" className="h-button">
                  <Link to="/company/blog">Cancel</Link>
                </PdButton>
              </Col>
              <Col>
                <PdButton
                  htmlType="submit"
                  type="primary"
                  color="blue"
                  className="h-button">
                  Submit
                </PdButton>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </DashboardContainer>
    </Layout>
  );
};

export default AddBlog;
