import { Row, Col, Radio } from 'antd';
import { useCallback, useEffect, useState } from 'react';

import { PdButton } from 'components/pd-button';
import CardNFT from 'components/landing-components/CardNFT';

import GuestAPI from '../../../api/GuestAPI';
import InvestorAPI from '../../../api/InvestorAPI';

import thumb from '../../../assets/images/thumb.svg';
import hero from '../../../assets/images/landing-hero.svg';
import howItWorks from '../../../assets/images/how-it-works.png';
import grabLogo from '../../../assets/images/grab.svg';
import BgCard from 'assets/images/bg-card.png';

import './index.scss';
import Footer from 'components/landing-components/Footer';
import Navbar from 'components/landing-components/Navbar';

const LandingPageNewVersion = () => {
  const [tabPosition, setTabPosition] = useState('left');
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(3);

  const handlePagination = () => {
    setPage(page + 3);
  };
  const fetchMarketplaceList = useCallback(async () => {
    const query = { limit: page, order_from: 'updatedAt', order_by: 'DESC' };
    const params = new URLSearchParams(query);
    const { data = {} } = await GuestAPI.getMarketPlaceList(params);
    const { rows = [], count = 0 } = data;
    setTotalPage(count);
    setMarketplaceList(rows);

    const isInvestor = localStorage.getItem('investor_token');

    if (isInvestor) {
      const { data = {} } = await InvestorAPI.getMarketPlaceList(params);
      setTotalPage(data.pageInfo.totalData);
      setMarketplaceList(data.data);
    } else {
      const { data = {} } = await GuestAPI.getMarketPlaceList(params);
      setTotalPage(data.pageInfo.totalData);
      setMarketplaceList(data.data);
    }
  }, [page]);

  useEffect(() => {
    fetchMarketplaceList();
  }, [fetchMarketplaceList]);

  const changeTabPosition = (e) => {
    setTabPosition(e.target.value);
  };
  return (
    <div className="landing-wrapper">
      <Navbar />
      {/* section 1 jumbotron */}
      <div className="section section-1">
        <div className="left">
          <div className="tagline-wrapper">
            <div className="text-light-blue">
              Let’s invest with simply one click{' '}
            </div>
            <img src={thumb} alt="thumb" />
          </div>
          <div className="title">
            Explore, <span>Invest</span> & Earn
          </div>
          <div className="title-secondary">
            The leading marketplace that allows anyone to invest in real world
            businesses and earn part of their earnings
          </div>
        </div>
        <div className="right">
          <img src={hero} alt="hero" />
        </div>
      </div>

      {/* section 2 */}
      <div className="section section-2">
        <div className="text">
          <div className="text-title">
            How it <span>works?</span>
          </div>
          <div className="text-title-secondary">
            Our marketplace allows investors to purchase a stake in a variety of
            companies and earn-a-share of their revenue, without having to buy
            the entire business.
          </div>
        </div>
        <div className="button-content">
          <div className="content active">
            <div className="number">1</div>
            <div className="text">Browse Business</div>
          </div>
          <div className="content">
            <div className="number">2</div>
            <div className="text">Invest on Business</div>
          </div>
          <div className="content">
            <div className="number">3</div>
            <div className="text">Earn Royalty</div>
          </div>
        </div>
        <div className="content-img">
          <img src={howItWorks} alt="" />
        </div>
      </div>

      {/* section 3 */}
      <div className="section section-3">
        <div className="text">
          <div className="text-title">Company that trust us</div>
          <div className="text-title-secondary">
            We understand the importance of trust when it comes to investing.
            That's why we use cutting-edge technology to provide unparalleled
            security, transparency, and accessibility to our investors.
          </div>
        </div>
        <div className="trusted-company">
          <img src={grabLogo} alt="" />
          <img src={grabLogo} alt="" />
          <img src={grabLogo} alt="" />
          <img src={grabLogo} alt="" />
          <img src={grabLogo} alt="" />
          <img src={grabLogo} alt="" />
        </div>
      </div>

      {/* section 4 */}
      <section className="section section-4">
        <Row align="middle" justify="center">
          <h1 className="text-title">Explore Business</h1>
        </Row>

        <Row align="middle" justify="center" className="pd-margin-bottom-lg">
          <Radio.Group value={tabPosition} onChange={changeTabPosition}>
            <Radio.Button className="left" value="latest-update">
              Latest Update
            </Radio.Button>
            <Radio.Button value="most-popular">Most Popular</Radio.Button>
            <Radio.Button className="right" value="trending">
              Trending
            </Radio.Button>
          </Radio.Group>
        </Row>

        <Row align="middle" justify="center">
          <Col span={24}>
            <Row align="top" justify="center" gutter={[40, 40]}>
              {marketplaceList.map((data) => {
                const { id = 0 } = data;

                return (
                  <Col xs={24} md={12} lg={8} className="gutter-row" key={id}>
                    <CardNFT
                      refetch={fetchMarketplaceList}
                      className={'pd-margin-top-lg'}
                      cover={BgCard}
                      data={data}
                      from="landing"
                    />
                  </Col>
                );
              })}
            </Row>
            <Row align="middle" justify="center" className="pd-margin-top-md">
              <PdButton
                disabled={page >= totalPage}
                color="blue"
                type="default"
                className="load"
                onClick={handlePagination}>
                Load More
              </PdButton>
            </Row>
          </Col>
        </Row>
      </section>

      {/* section 5 */}
      <div className="section-5">
        <div className="wrapper-5">
          <div className="title-text">Looking to publicize your business?</div>
          <PdButton type="default" className="join" color="white">
            Join as Business Owner
          </PdButton>
        </div>
      </div>

      {/* sectino 6 */}

      <div className="section section-6">
        <div className="text">
          <div className="text-title">Frequently Asked Question</div>
          <PdButton type="link" className="join" color="blue">
            Explore More FAQ
          </PdButton>
        </div>
        <div className="faq-wrapper">
          {[1, 2, 3, 4, 5, 6].map((data) => (
            <div className="faq-content" key={data}>
              <div className="title-faq">
                How can I register and purchase an investment?
              </div>
              <div className="title-desc">
                To invest in the businesses you will need to own a crypto
                wallet. Some of the popular choices are Metamask…
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* section 7 */}
      <div className="section section-7">
        <div className="text-title">Benefit for the users</div>
        <div className="content">
          <div className="card left">
            <div className="header">
              <div className="title-header">Benefits for Investors</div>
              <div className="desc-header">
                Here are some of the advantages that investors get when joining
                a tykoon
              </div>
            </div>
            <div className="body">
              <div className="point-wrapper">
                <div className="point">.</div>
                <div className="point-text">
                  <div className="point-title">
                    Access to a diverse investment opportunity
                  </div>
                  <div className="point-desc">
                    Investor can control the vision & mission of the NFT assets
                  </div>
                </div>
              </div>
              <div className="point-wrapper">
                <div className="point"></div>
                <div className="point-text">
                  <div className="point-title">
                    Access to liquidity for their investments
                  </div>
                  <div className="point-desc">
                    Investor can control the performance of the NFT assets they
                    have purchased.
                  </div>
                </div>
              </div>
              <div className="point-wrapper">
                <div className="point"></div>
                <div className="point-text">
                  <div className="point-title">
                    Fractionalized investment opportunity to enable risk
                    diversification
                  </div>
                  <div className="point-desc">
                    Majority of business share their businees to potential
                    investors
                  </div>
                </div>
              </div>
            </div>
            <div className="button">
              <PdButton color="blue" type="primary" className="investor">
                Join Investor
              </PdButton>
            </div>
          </div>

          {/* right */}
          <div className="card right">
            <div className="header">
              <div className="title-header">Benefits for Investors</div>
              <div className="desc-header">
                Here are some of the advantages that investors get when joining
                a tykoon
              </div>
            </div>
            <div className="body">
              <div className="point-wrapper">
                <div className="point">.</div>
                <div className="point-text">
                  <div className="point-title">
                    Access to a diverse investment opportunity
                  </div>
                  <div className="point-desc">
                    Investor can control the vision & mission of the NFT assets
                  </div>
                </div>
              </div>
              <div className="point-wrapper">
                <div className="point"></div>
                <div className="point-text">
                  <div className="point-title">
                    Access to liquidity for their investments
                  </div>
                  <div className="point-desc">
                    Investor can control the performance of the NFT assets they
                    have purchased.
                  </div>
                </div>
              </div>
              <div className="point-wrapper">
                <div className="point"></div>
                <div className="point-text">
                  <div className="point-title">
                    Fractionalized investment opportunity to enable risk
                    diversification
                  </div>
                  <div className="point-desc">
                    Majority of business share their businees to potential
                    investors
                  </div>
                </div>
              </div>
            </div>
            <div className="button">
              <PdButton color="blue" type="primary" className="company">
                Join Company
              </PdButton>
            </div>
          </div>
        </div>
      </div>

      {/* section 8 */}
      <div className="section section-8">
        <div className="text">
          <div className="text-title">
            Start earning a return on your investment today!
          </div>
          <div className="text-desc">
            Start investment today to get the opportunity to get dividends from
            the company
          </div>
        </div>
        <PdButton color="blue" type="primary" className="company">
          Join as Business Owner
        </PdButton>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPageNewVersion;
