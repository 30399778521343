import { useEffect } from 'react';
import { Col, Divider, Form, message, Row } from 'antd';

import { PdButton } from 'components/pd-button';
import { PdAntdInput } from 'components/pd-antd-input';
import CompanyAPI from 'api/CompanyAPI';

const BankInformation = ({ setLoading, loading, userProfile }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const setInitialValue = () => {
      form.setFieldsValue({
        bank_account: userProfile.bank_account,
        bank: userProfile.bank,
        account_name: userProfile.account_name,
      });
    };
    setInitialValue();
  }, [form, userProfile]);

  const onClickSaveChanges = async (values) => {
    setLoading({ submit: true });
    const { bank_account = '', bank = '', account_name = '' } = values;
    const payload = {
      profile: {
        bank_account,
        bank,
        account_name,
      },
    };
    try {
      const { data } = await CompanyAPI.updateProfile(payload);
      message.success(data.message);
    } catch (_) {
      setLoading({ submit: false });
      message.error('Network error');
    } finally {
      setLoading({ submit: false });
    }
  };
  return (
    <Form
      name="bankForm"
      form={form}
      onFinish={onClickSaveChanges}
      autoComplete="off"
      initialValues={userProfile}
      labelAlign="left"
      colon={false}
      labelCol={{
        span: 8,
      }}
      layout="horizontal"
      size="large">
      <Form.Item
        name="account_name"
        className="pd-margin-top-sm"
        label="Bank Account Name"
        wrapperCol={{ span: 10 }}>
        <PdAntdInput />
      </Form.Item>
      <Divider />
      <Form.Item name="bank" label="Bank Vendor" wrapperCol={{ span: 10 }}>
        <PdAntdInput />
      </Form.Item>
      <Divider />
      <Form.Item
        name="bank_account"
        label="Account Number"
        wrapperCol={{ span: 10 }}>
        <PdAntdInput />
      </Form.Item>
      <Divider />
      <Form.Item>
        <Row justify="end" gutter={[20, 0]}>
          <Col>
            <PdButton
              type="ghost"
              className="cancel-button"
              style={{ border: 'none', height: '45px', width: '160px' }}>
              Cancel
            </PdButton>
          </Col>
          <Col>
            <PdButton
              disabled={loading.submit}
              loading={loading.submit}
              htmlType="submit"
              type="submit"
              variant="primary"
              className="save-button"
              style={{ height: '45px', width: '160px' }}>
              Save Changes
            </PdButton>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export { BankInformation };
