import './style.scss';
import { useEffect, useState } from 'react';
import { Col, Layout, message, Row } from 'antd';
import { Link } from 'react-router-dom';

import { DashboardContainer } from 'components/dashboard-container';
import { Failed, Success } from 'components/pd-modal/success';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import { PdTooltip } from 'components/pd-tooltip';

import infoIcon from 'assets/icon/info-blue.svg';
import PdAntdHeader from 'components/pd-antd-header';
import PdTable from 'components/pd-table';
import PdTitle from 'components/pd-title';
import CompanyAPI from 'api/CompanyAPI';
import moment from 'moment/moment';

const Blog = () => {
  const [modal, setModal] = useState({
    type: '',
    visible: false,
    data: {},
    status: '',
  });

  const [tableData, setTableData] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);

  const getAllBlogs = async () => {
    try {
      setLoading(true);
      const { data } = await CompanyAPI.getAllBlogs();
      setTableData(data.rows);
    } catch (error) {
      message.error('Error while fetching blogs data...');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBlogs = async (id) => {
    try {
      setLoading(true);
      await CompanyAPI.deleteBlogs(id);
      setModal({ type: 'successDelete', visible: true });
      setTimeout(() => getAllBlogs(), 1500);
    } catch (error) {
      message.error('Error while deleting blogs data...');
    } finally {
      setLoading(false);
    }
  };

  const onChangePageSize = (size) => {
    setPageSize(size);
  };

  function strip(html) {
    var tmp = document.implementation.createHTMLDocument('New').body;
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const ActionButton = (data) => {
    const { id } = data;
    return (
      <Row gutter={24} justify="center">
        <Col>
          <PdButton color="black" className="h-button">
            <Link to={`/company/blog/edit-post/${id}`}>Edit Post</Link>
          </PdButton>
        </Col>
        <Col>
          <PdButton
            color="black"
            type="default"
            className="h-button"
            onClick={() => handleDeleteBlogs(id)}>
            Delete Post
          </PdButton>
        </Col>
      </Row>
    );
  };

  const columns = [
    {
      title: 'Post Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'detail_article',
      key: 'detail_article',
      render: (detail_article) =>
        strip(detail_article).slice(0, 26) +
        (detail_article.length > 26 ? '...' : ''),
    },
    {
      title: 'Business',
      dataIndex: 'Bussine',
      key: 'Bussine',
      render: (bussines) => bussines.brand_name,
    },
    {
      title: 'Date',
      dataIndex: 'updateAt',
      key: 'updateAt',
      render: (date) => moment(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Action',
      key: 'act',
      render: ActionButton,
      align: 'center',
    },
  ];

  const modalContent = {
    success: (
      <Success
        title="Congratulations"
        desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
      />
    ),
    failed: (
      <Failed
        title="Oops!"
        desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. "
      />
    ),
    successDelete: (
      <Failed title="Deleted" desc="Your article has been deleted " />
    ),
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <Layout className="company-document-page">
      <PdModals
        visible={modal.visible}
        wrapClassName="upload-document-modal"
        headStyle={{ alignText: 'center' }}
        handleClose={handleCloseModal}
        footer={null}>
        {modalContent[modal.type]}
      </PdModals>
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Blog
          <PdTooltip
            placement="top"
            color="white"
            title="In company document menu, You can add company documents/financial reports">
            <img src={infoIcon} alt="info" className="info-icon" />
          </PdTooltip>
        </PdTitle>
        <PdButton
          className="h-button"
          // onClick={handleOpenUploadDocument}
        >
          <Link to={'/company/blog/add-post'}>Add Post</Link>
        </PdButton>
      </PdAntdHeader>
      <DashboardContainer>
        <PdTable
          loading={loading.page}
          pageSize={pageSize}
          // openFilter={handleOpenFilterModal}
          title="Article List"
          columns={columns}
          data={tableData}
          onChangePageSize={onChangePageSize}
        />
      </DashboardContainer>
    </Layout>
  );
};

export default Blog;
