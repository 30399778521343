import { Divider } from 'antd';

import { PdAntdSelect } from 'components/pd-antd-select';
import { PdButton } from 'components/pd-button';

import './style.scss';

/**
 *
 * @param {Function} props.onFinish function that recieve value
 */

export default function RequestChangeDataFilter({
  onFinish = () => {},
  onChange = () => {},
}) {
  const dataSet = [
    {
      optKey: 1,
      value: 'all',
      text: 'All',
    },
    {
      optKey: 2,
      value: 'in-review',
      text: 'In Review',
    },
    {
      optKey: 3,
      value: 'updated',
      text: 'Updated',
    },
    {
      optKey: 4,
      value: 'rejected',
      text: 'Rejected',
    },
  ];

  return (
    <div className="request-change-data-filter-modal">
      <div className="title-wrapper">
        <p className="filter-text">Filter</p>
        <p className="clear-filter-text">Clear Filter</p>
      </div>
      <Divider />

      <div className="filter-wrapper">
        <p className="filter-wrapper-title">By Status</p>
        <PdAntdSelect
          onChange={onChange}
          dataSet={dataSet}
          optKey="value"
          optValue="value"
          optContent="text"
          defaultValue="all"
          style={{ width: '120px', textAlign: 'left' }}
        />
      </div>
      <Divider />
      <PdButton onClick={onFinish} style={{ height: 40 }} className="w-full">
        Apply Filter
      </PdButton>
    </div>
  );
}
