import './style.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Layout, Spin, Tabs } from 'antd';

import CompanyAPI from 'api/CompanyAPI';

import { DashboardContainer } from 'components/dashboard-container';
import PdAntdCollapse from 'components/pd-antd-collapse';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';

import { currencyFormatter } from 'utils/string-utility';

const dummyData = [
  {
    label: `ATM`,
    key: '1',
    children: `Content of Tab Pane 1 Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse vero fuga iusto sint cumque optio. Autem molestiae hic dicta?`,
  },
  {
    label: `IBANKING`,
    key: '2',
    children: `Content of Tab Pane 2 Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse vero fuga iusto sint cumque optio. Autem molestiae hic dicta?`,
  },
  {
    label: `MBANKING`,
    key: '3',
    children: `Content of Tab Pane 3 Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aperiam esse vero fuga iusto sint cumque optio. Autem molestiae hic dicta?`,
  },
];

export function RevenuePaymentVCC() {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { due_date, total_payment, VirtualAccounts = [] } = data;

  const { account_number, bank_code, va_name } =
    VirtualAccounts.length === 0 ? VirtualAccounts : VirtualAccounts[0];

  const handleSubmitRevenue = async (bank) => {
    try {
      setLoading(true);
      const payload = {
        disbursement_id: id,
        bank_code: bank,
      };

      await CompanyAPI.chooseBankPayment(payload);
      const { data } = await CompanyAPI.getDetailRevenueDisbursement(id);
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getRevenueDisbursement = async () => {
      try {
        setLoading(true);
        const { data } = await CompanyAPI.getDetailRevenueDisbursement(id);
        setData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getRevenueDisbursement();
  }, [id]);

  return (
    <Layout className="pd-cms-home">
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Payment
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer>
        <Row justify="center">
          {loading && <Spin />}
          {!loading && VirtualAccounts.length === 0 ? (
            <Col span={18}>
              <p className="pd-align-center">Due date until {due_date}</p>
              <PdTitle size="small" align="center">
                {currencyFormatter(total_payment, 'IDR')}
              </PdTitle>
              <PdAntdCollapse text="Transfer Bank" data={'test'}>
                <Row
                  justify="space-between"
                  gutter={[24, 24]}
                  className="pd-margin-bottom-md">
                  {['BCA', 'BNI', 'MANDIRI', 'BRI', 'PERMATA', 'CIMB'].map(
                    (bank, idx) => (
                      <Col key={idx} span={8}>
                        <div
                          className="card-bank"
                          onClick={(e) =>
                            handleSubmitRevenue(e.target.innerText)
                          }>
                          <PdTitle size="extra-small" align="center">
                            {bank}
                          </PdTitle>
                        </div>
                      </Col>
                    )
                  )}
                </Row>
              </PdAntdCollapse>
            </Col>
          ) : (
            !loading && (
              <Col span={12}>
                <Row className="va-wrapper pd-margin-bottom-lg">
                  <div>
                    <div className="pd-margin-bottom-lg">
                      Virtual Account {bank_code}
                      <PdTitle size="extra-small">{account_number}</PdTitle>
                    </div>
                    <div className="pd-margin-bottom-lg">
                      Virtual Account Name
                      <PdTitle size="extra-small">{va_name}</PdTitle>
                    </div>
                    <div>
                      Total Amount
                      <PdTitle size="extra-small">
                        {currencyFormatter(total_payment, 'IDR')}
                      </PdTitle>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Tabs defaultActiveKey="1" items={dummyData} />
                </Row>
              </Col>
            )
          )}
        </Row>
      </DashboardContainer>
    </Layout>
  );
}
const RevenuePaymentVCCs = { RevenuePaymentVCC };
export default RevenuePaymentVCCs;
