import { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import { ethers } from 'ethers';
import debounce from 'lodash.debounce';
import { MenuOutlined } from '@ant-design/icons';

import { useAuthContext } from 'contexts/authentication';

import { PdProfileNav } from 'components/pd-profile-nav';
import { PdAntdInput } from 'components/pd-antd-input';
import { PdButton } from 'components/pd-button';
import { PdModals } from 'components/pd-modal';
import { LoginModal } from 'components/pd-modal/login';
import tykoonLogo from 'assets/images/logo-black.png';
import walleticon from '../../../assets/images/wallet-money.png';

import './style.scss';

const Navbar = () => {
  const history = useHistory();
  const { auth } = useAuthContext();

  const provider =
    window.ethereum && window.ethereum.isMetaMask
      ? new ethers.providers.Web3Provider(window.ethereum)
      : null;

  const [searchValue, setSearchValue] = useState(
    localStorage.getItem('searchQuery')
  );
  const authenticatedInvestor = localStorage.getItem('investor_token');
  const [role, setRole] = useState('');
  const [modal, setModal] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [y, setY] = useState(window.scrollY);
  const [isDown, setIsDown] = useState(false);
  const [isFetchPaused, setIsFetchPaused] = useState(false);

  useEffect(() => {
    const getAuthenticated = async () => {
      if (authenticatedInvestor) {
        setIsAuthenticated(true);
        setRole('investor');
      } else {
        try {
          const onStorage = localStorage.getItem('current_user');
          if (onStorage) {
            setRole('company');
            setIsAuthenticated(true);
          }
        } catch (err) {
          setIsAuthenticated(false);
          console.log('err', err);
        }
      }
    };

    getAuthenticated();
  }, [authenticatedInvestor]);

  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum
        .request({ method: 'eth_requestAccounts' })
        .then((result) => {
          if (result.length > 0) {
            history.push('/investor/my-business');
            accountChangedHandler(result[0]);
            getAccountBalance(result[0]);
          } else {
            setIsWalletConnected(false);
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
    } else {
      message.info('Need to install MetaMask');
      window.open(
        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
        '_blank'
      );
    }
  };

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount) => {
    if (typeof newAccount === 'string') {
      const wallet = { wallet_address: newAccount };
      auth(wallet, 'login', 'investor');
      localStorage.setItem('wallet_address', JSON.stringify(newAccount));
      getAccountBalance(newAccount.toString());
    }
  };

  const getAccountBalance = (account) => {
    window.ethereum
      .request({ method: 'eth_getBalance', params: [account, 'latest'] })
      .then((balance) => {
        // setUserBalance(ethers.utils.formatEther(balance));
      })
      .catch((error) => {
        //check if there is no connected account
        if (error.code === -32602) {
          window.location.reload();
          return;
        }
        message.error(error.message);
      });
  };

  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    window.location.reload();
  };

  // listen for account changes
  if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on('accountsChanged', (res) =>
      accountChangedHandler(res[0], 'change')
    );
    window.ethereum.on('chainChanged', chainChangedHandler);
  }

  const isMetaMaskConnected = useCallback(async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      const accounts = await provider.listAccounts();

      if (accounts.length) {
        // metamask is connected
        setIsWalletConnected(true);
      } else {
        // metamask is not connected
        setIsWalletConnected(false);
        localStorage.removeItem('wallet_address');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setIsDown(false);
      } else if (y < window.scrollY) {
        setIsDown(true);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    isMetaMaskConnected();
  }, [isMetaMaskConnected]);

  const handleResult = debounce((value) => {
    history.push(`/explore?brand_name=${value}`);
    localStorage.setItem('searchQuery', value);
    setIsFetchPaused(false);
  }, 1500);

  const debouceRequest = useCallback(
    (value) => handleResult(value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onChangeSearchInput = (event) => {
    if (localStorage.getItem('searchQuery')) {
      localStorage.removeItem('searchQuery');
    }

    setIsFetchPaused(true);

    const {
      target: { value = '' },
    } = event;
    setSearchValue(value);
    debouceRequest(value);
  };

  const onClickModalLogin = () => {
    setModal({ type: 'login', visible: true });
  };
  const handleCloseModal = () => {
    setModal({ type: '', visible: false });
  };

  const modalContent = {
    login: <LoginModal />,
  };

  return (
    <Row
      className={`w-100 h-nav pd-shadow-navbar nav ${isDown ? 'hide' : ''}`}
      align="middle"
      justify="center">
      <PdModals
        wrapClassName="pd-login-modal"
        width={600}
        handleClose={handleCloseModal}
        footer={null}
        visible={modal.visible}>
        {modalContent[modal.type]}
      </PdModals>

      <Col span={22}>
        <Row className="w-100" align="middle" justify="space-between">
          <Col lg={8} xl={10}>
            <Row align="middle" gutter={12}>
              <Col>
                <Link to={`/`}>
                  <img
                    src={tykoonLogo}
                    alt="logo"
                    style={{ width: '100%', maxWidth: '112px' }}
                    className="pd-margin-left-md"
                  />
                </Link>
              </Col>
              <Col xs={0} lg={10} xl={16}>
                <PdAntdInput
                  loading={isFetchPaused}
                  value={searchValue}
                  variant="search"
                  size="large"
                  placeholder="Search for businesses and companies"
                  className="h-input"
                  handleSearch={onChangeSearchInput}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={2} lg={0}>
            <MenuOutlined onClick={() => setMenuOpen(!menuOpen)} />
          </Col>

          <div className={`mobile-menu ${menuOpen ? 'show' : ''}`}>
            <Row align="middle" justify="center">
              <Col span={22}>
                <Row className="pd-margin-top-xs" justify="center">
                  <Link
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    to="/">
                    <h3 className="pd-margin-right-md">Home</h3>
                  </Link>
                </Row>
                <Row justify="center">
                  <Link
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    to="/explore">
                    <h3>Marketplace</h3>
                  </Link>
                </Row>
                <Row>
                  <PdAntdInput
                    variant="search"
                    placeholder="Search"
                    className="h-input pd-margin-top-sm pd-margin-bottom-sm w-full"
                  />
                </Row>
              </Col>
              <Col span={22}>
                {isAuthenticated ? (
                  <Row justify="center">
                    <PdProfileNav />
                  </Row>
                ) : (
                  <Row justify="center">
                    <Col className="pd-margin-right-md">
                      <PdButton
                        onClick={onClickModalLogin}
                        color="blue"
                        type="default"
                        className=" h-button">
                        Join Now
                      </PdButton>
                    </Col>
                    <Col>
                      <PdButton
                        className="h-button"
                        onClick={connectWalletHandler}>
                        <img
                          src={walleticon}
                          alt="wallet"
                          style={{ marginRight: '10px' }}
                        />
                        {isWalletConnected
                          ? 'Wallet Connected'
                          : 'Connect Wallet'}
                      </PdButton>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>

          <Col xs={0} lg={16} xl={14}>
            <Row align="middle">
              <Col span={24}>
                <Row justify="end" align="middle" gutter={16}>
                  <Col>
                    <Row className="pd-margin-top-xs" justify="end">
                      <Link to="/">
                        <h3 className="pd-margin-right-md pd-margin-bottom-none pd-margin-top-none">
                          Home
                        </h3>
                      </Link>
                      <Link to="/explore">
                        <h3 className="pd-margin-bottom-none pd-margin-top-none">
                          Marketplace
                        </h3>
                      </Link>
                    </Row>
                  </Col>
                  {isAuthenticated ? (
                    <PdProfileNav role={role} />
                  ) : (
                    <>
                      <Col>
                        <PdButton
                          onClick={onClickModalLogin}
                          color="blue"
                          type="default"
                          className=" h-button">
                          Join Now
                        </PdButton>
                      </Col>
                      <Col>
                        <PdButton
                          className="h-button"
                          onClick={connectWalletHandler}>
                          <img
                            src={walleticon}
                            alt="wallet"
                            style={{ marginRight: '10px' }}
                          />
                          {isWalletConnected && authenticatedInvestor
                            ? 'Wallet Connected'
                            : 'Connect Wallet'}
                        </PdButton>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Navbar;
