import { useEffect, useState } from 'react';

import { RightOutlined } from '@ant-design/icons';
import { message, Row, Spin } from 'antd';

import { Link, useHistory } from 'react-router-dom';
import GuestAPI from 'api/GuestAPI';
import { addressSplitter } from 'utils/string-utility';
import moment from 'moment';

import Footer from 'components/landing-components/Footer';
import JoinOurBusinessOwner from 'components/landing-components/JoinBusinessOwner';
import Navbar from 'components/landing-components/Navbar';

import notFoundImg from '../../../assets/images/not-found.svg';

import './style.scss';

const SearchFAQ = () => {
  const history = useHistory();
  const loc = window.location.pathname.split('/')[2];
  const keyword = new URLSearchParams(window.location.search).get('title');

  const [data, setData] = useState([]);
  const [count, setCount] = useState(12);
  const [loading, setLoading] = useState(false);

  const handleDetail = async (id, cat) => {
    history.push(`/faq/${cat}/detail/${id}`);
  };

  useEffect(() => {
    const getAllFaq = async () => {
      try {
        setLoading(true);
        const query = { search: keyword };
        const params = new URLSearchParams(query);

        const { data } = await GuestAPI.getFaq(params);
        setCount(data.count);
        setData(data.rows);
      } catch (error) {
        message.error('Error while trying to search faqs..');
      } finally {
        setLoading(false);
      }
    };
    getAllFaq();
  }, [loc, keyword]);

  return (
    <>
      <Navbar />
      <div className="faq-wrapper">
        <Row align="middle" className="breadcumb">
          <Link to="/faq">Tykoon Help Center</Link>
          <RightOutlined />
          <div>Search Results</div>
        </Row>
        <div className="detail-question">Search Results</div>
        <div className="count-question">
          {count} results for {keyword}
        </div>
        <Row gutter={24} className="content-wrapper" align="top">
          {loading && <Spin />}
          {data.length > 0 &&
            data.map(
              ({ title, description, category_faq, post_date, id }, idx) => (
                <div className="result-card-wrapper" key={idx}>
                  <div
                    className="title-result"
                    onClick={() =>
                      handleDetail(id, category_faq.toLowerCase())
                    }>
                    {title}
                  </div>
                  <div className="breadcumb-wrapper">
                    <Row align="middle" className="breadcumb-result">
                      <Link to="/faq">Tykoon Help Center</Link>
                      <RightOutlined />
                      <Link to="/faq">{category_faq} Questions</Link>
                      <RightOutlined />
                      <div>{addressSplitter(title, 20)}</div>
                    </Row>
                    <div className="time-result">
                      Updated {moment().diff(moment(post_date), 'days')} days
                      ago
                    </div>
                  </div>
                  <div
                    className="answer-result"
                    dangerouslySetInnerHTML={{
                      __html: addressSplitter(description, 300),
                    }}
                  />
                </div>
              )
            )}

          {data.length === 0 && (
            <div className="notfound-wrapper">
              <img src={notFoundImg} alt="not found" />
              <div className="notfound-title">Ups.. result is not found</div>
            </div>
          )}
        </Row>
      </div>
      <JoinOurBusinessOwner className={'pd-margin-bottom-lg'} />
      <Footer />
    </>
  );
};

export default SearchFAQ;
