import { useEffect, useState, useCallback } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Col, Row, message } from 'antd';

import GuestAPI from 'api/GuestAPI';

import { Link, useParams } from 'react-router-dom';

import { capitalize } from 'utils/string-utility';

import Footer from 'components/landing-components/Footer';
import JoinOurBusinessOwner from 'components/landing-components/JoinBusinessOwner';
import Navbar from 'components/landing-components/Navbar';

import './style.scss';

const DetailFAQ = () => {
  const { id } = useParams();

  const loc = window.location.pathname.split('/')[2];

  const [data, setData] = useState([]);
  const [detail, setDetail] = useState({ description: '', title: '' });

  const filteredData = useCallback(() => {
    const dataDetail = data.filter((data) => data.id === parseInt(id));
    setDetail(...dataDetail);
  }, [data, id]);

  useEffect(() => {
    filteredData();
  }, [id, filteredData]);

  useEffect(() => {
    const getAllFaq = async () => {
      try {
        if (loc === 'investor') {
          const { data: investor } = await GuestAPI.getFaq(`filter=Investor`);
          setData(investor.rows);
        } else {
          const { data: company } = await GuestAPI.getFaq(`filter=Company`);
          setData(company.rows);
        }
      } catch (error) {
        message.error('Error while trying to get faqs..');
      }
    };
    getAllFaq();
  }, [loc]);
  return (
    <>
      <Navbar />
      <div className="faq-wrapper">
        <Row align="middle" className="breadcumb">
          <Link to="/faq">Tykoon Help Center</Link>
          <RightOutlined />
          <Link to={`/faq/${loc}/all`}>{capitalize(loc)} Questions</Link>
          <RightOutlined />
          <div>{detail?.title}</div>
        </Row>

        <Row gutter={24} className="content-wrapper">
          <Col
            span={6}
            style={{
              maxHeight: '2000px',
              height: '100vh',
              overflowY: 'scroll',
            }}>
            {data.map((data) => (
              <Link
                to={`/faq/${loc}/detail/${data.id}`}
                key={data.id}
                style={{ color: 'black' }}>
                <div
                  className={`title ${
                    parseInt(id) === data.id ? 'active' : ''
                  }`}>
                  <Row justify="space-between" align="middle">
                    <div className="title-text">{data.title}</div>
                  </Row>
                </div>
              </Link>
            ))}
          </Col>
          <Col span={18}>
            <div className="detail-title">{detail?.title}</div>
            {/* <div className="detail-desc">{strip(detail?.description)}</div> */}
            <div
              className="detail-desc"
              dangerouslySetInnerHTML={{ __html: detail?.description }}
            />
          </Col>
        </Row>
      </div>
      <JoinOurBusinessOwner className={'pd-margin-bottom-lg'} />
      <Footer />
    </>
  );
};

export default DetailFAQ;
