import { useState, useEffect } from 'react';
import { Layout, message, Tabs } from 'antd';
import moment from 'moment';

import { DashboardContainer } from 'components/dashboard-container';
import CompanyAPI from 'api/CompanyAPI';
import PdAntdHeader from 'components/pd-antd-header';
import PdTitle from 'components/pd-title';

import { PersonalInfomation } from './PersonalInformation';
import { CompanyInformation } from './CompanyInformation';
import { BankInformation } from './BankInformation';
import { PasswordInformation } from './PasswordInformation';

import './style.scss';

const Profile = () => {
  const [loading, setLoading] = useState({});
  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const { data = {} } = await CompanyAPI.getUserProfile();
        const { CompanyForms = [] } = data;
        if (CompanyForms.length) {
          const date = moment(CompanyForms[0].birth_day);
          CompanyForms[0].birth_day = date;
          setUserProfile(CompanyForms[0]);
        }
      } catch (_) {
        message.error('Network Error');
      }
    };
    fetchUserProfile();
  }, []);

  const items = [
    {
      label: 'Personal Information',
      key: 'personal',
      children: (
        <PersonalInfomation
          setLoading={setLoading}
          loading={loading}
          userProfile={userProfile}
        />
      ),
    },
    {
      label: 'Company information',
      key: 'company',
      children: (
        <CompanyInformation
          setLoading={setLoading}
          loading={loading}
          userProfile={userProfile}
        />
      ),
    },
    {
      label: 'Bank Account Information',
      key: 'bank',
      children: (
        <BankInformation
          setLoading={setLoading}
          loading={loading}
          userProfile={userProfile}
        />
      ),
    },
    {
      label: 'Reset Password',
      key: 'password',
      children: (
        <PasswordInformation setLoading={setLoading} loading={loading} />
      ),
    },
  ];

  return (
    <Layout className="company-profile-page">
      <PdAntdHeader level={2}>
        <PdTitle size="small" className="pd-margin-top-sm">
          Profile Account
        </PdTitle>
      </PdAntdHeader>
      <DashboardContainer>
        <Tabs items={items} />
      </DashboardContainer>
    </Layout>
  );
};

export { Profile };
