import { Link } from 'react-router-dom';
import { Alert, Col, Row } from 'antd';

import { PdButton } from 'components/pd-button';
import PdTitle from 'components/pd-title';

import './style.scss';

/**
 *
 * @param {Function} props.onClose function that recieve value
 */

export default function RejectReason({ onClose = () => {}, reason = '' }) {
  return (
    <div className="pd-guest-modal-business">
      <div className="header">
        <PdTitle align="center" color="black" size="small">
          Reason
        </PdTitle>
      </div>
      <div className="content">
        <Alert
          message={`Your verification failed because "${reason}" , please check and submit again`}
          type={'error'}
          showIcon
        />
        <Row justify="center" gutter={20} className="pd-margin-top-lg">
          <Col>
            <PdButton
              type="default"
              className="w-full"
              color="blue"
              onClick={onClose}>
              Cancel
            </PdButton>
          </Col>
          <Col>
            <Link to="/company/verify-account">
              <PdButton color="blue" onClick={onClose}>Re-Submit</PdButton>
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
}
