export function manipulateStatus(status) {
  switch (status) {
    case 'not published':
      return 'in review';
    case 'new':
      return 'incoming';
    case 'launch':
      return 'launched';
    case 'publish':
      return 'published';
    case 'fail':
      return 'failed';
    default:
      return status;
  }
}

export const statusColor = {
  published: '#44ABFF',
  publish: '#44ABFF',
  launched: '#215C8C',
  inreview: '#1A252D',
  'in review': '#1A252D',
  incoming: '#6C6D6D',
  pending: 'black',
  verified: '#00D97E',
  rejected: 'red',
  'not published': '#1A252D', // in business this is stand for in-review status
  new: '#44ABFF', // new is incoming status
  launch: '#215C8C', // launch is launched
  paid: '#215C8C',
  success: '#00D97E',
  fail: '#FF4444',
  'fund disbursed': '#007644',
  'fund returned': '#782A2A',
};
