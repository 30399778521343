import { Switch } from 'react-router-dom';
import { Layout } from 'antd';
import { useScrollToTop } from 'hooks/use-scroll-top';

import AuthenticatedGuardRoute from 'components/guard/authenticated';
import { PdNavbar } from 'components/pd-navbar';
import { Sidebar } from 'components/sidebar';
import PdStickyFailedVerification from 'components/pd-sticky-failed';

import { CompanyDocument } from 'pages/dashboard/Company/document';
import { CreateBusiness } from 'pages/dashboard/Company/business-create';
import { DetailBusiness } from 'pages/shared/product-detail';
import { Home } from 'pages/dashboard/Company/company-home';
import { Notification } from 'pages/dashboard/Company/notification';
import { Profile } from 'pages/dashboard/Company/profile';
import { RequestEditChange } from 'pages/dashboard/Company/request-change-data';
import { RevenueDisbursement } from 'pages/dashboard/Company/revenue-disbursement';
import { RevenuePaymentVCC } from 'pages/dashboard/Company/revenue-disbursement/revenuePayment';
import { VerifyForm } from 'pages/dashboard/Company/verify-account';
import History from 'pages/dashboard/Company/history';
import Blog from 'pages/dashboard/Company/blog';
import AddBlog from 'pages/dashboard/Company/blog/add-post';

const CompanyRouteWrapper = () => {
  useScrollToTop();
  return (
    <Switch>
      <>
        <Layout className="pd-cms">
          <Sidebar from="company" />
          <Layout>
            <PdStickyFailedVerification />
            <PdNavbar />
            <AuthenticatedGuardRoute
              exact
              path="/company/dashboard"
              component={Home}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/profile"
              component={Profile}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/revenue-disbursement"
              component={RevenueDisbursement}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/verify-account"
              component={VerifyForm}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/existing-business"
              component={CreateBusiness}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/fundraising-business"
              component={CreateBusiness}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/product-detail/:id"
              component={DetailBusiness}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/revenue-payment/:id"
              component={RevenuePaymentVCC}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/notification"
              component={Notification}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/request-edit"
              component={RequestEditChange}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/company-document"
              component={CompanyDocument}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/history"
              component={History}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/blog"
              component={Blog}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/blog/add-post"
              component={AddBlog}
            />
            <AuthenticatedGuardRoute
              exact
              path="/company/blog/edit-post/:id"
              component={AddBlog}
            />
          </Layout>
        </Layout>
      </>
    </Switch>
  );
};

export default CompanyRouteWrapper;
